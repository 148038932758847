.i3-filter {
  &__panel {
    @include border-bottom();
    padding: space(3);
    transition: max-height $transition-base;

    &--minimized {
      // stylelint-disable-next-line declaration-no-important
      max-height: rem(76px) !important; // use important to override the inline max-height set in vue

      .i3-filter__panel-controls {
        opacity: 0;
        transform: translateY(-1rem);
      }
    }
  }

  // &__view {
  // }

  &__panel-controls {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: rem(12px);
    margin-bottom: - space(2);
    transition: all $transition-base;

    .filter-bar__tag {
      display: inline-flex;
      &--group {
        display: inline-flex;
      }
    }

    .filter-bar__tag:not(.filter-bar__tag--child) {
      margin-bottom: space(2);
    }
  }

  &__panel-meta {
    margin-top: rem(10px);
    margin-bottom: rem(14px);
    font-size: $font-size-xs;
    color: color("muted-light");
    text-transform: uppercase;
    letter-spacing: 0.5px;
  }

  &__panel-clear {
    display: inline-block;
    padding: rem(8px) rem(10px);
    color: color("danger");
    cursor: pointer;
    border: $border-width solid $border-color;
    border-radius: $border-radius;

    svg {
      font-size: rem(12px);
    }

    &--disabled {
      color: color("muted-light");
      pointer-events: none;
    }
  }

  &__search-view,
  &__facet-view {
    padding: space(3);
    background: $white;
    border-bottom: $border-width solid $border-color;
  }

  &__search-view {
    background: color("gray-bg");
  }

  &__facets-view {
    border-bottom: $border-width solid $border-color;
  }

  &__facet {
    position: relative;
    background: $white;

    &:not(:last-child) {
      border-bottom: $border-width solid $border-color;
    }

    &--selected {
      padding-bottom: space(3);

      .i3-filter__facet-name {
        padding-bottom: rem(12px);
        font-weight: $font-weight-medium;
      }

      .i3-filter__facet-icon {
        transform: rotate(180deg);
      }
    }
  }

  &__facet-name {
    padding: font-padding(space(4), space(3));
    line-height: 1;
    cursor: pointer;
  }

  &__facet-icon {
    position: absolute;
    top: rem(20px);
    right: space(3);
    font-size: rem(24px);
    pointer-events: none;
  }

  &__filter {
    position: relative;
    margin-bottom: rem(12px);
    cursor: pointer;

    @include bp-down(sm) {
      margin-bottom: space(3);
    }
  }

  &__range-filter {
    .vue-slider {
      margin-bottom: space(4);
    }
  }

  .product-color {
    width: 25%;
    margin-right: 0;

    &__icon {
      font-size: rem(48px);
    }
  }

  &__range-filter-inputs {
    display: flex;
    align-items: center;
    margin-bottom: space(3);

    div {
      margin: 0 space(1);
    }
  }

  &__facet-children {
    padding-right: space(3);
    padding-left: space(3);
  }

  &__filter-children {
    position: relative;
    padding-top: rem(12px);

    @include bp-down(sm) {
      padding-top: space(3);
    }

    &::before {
      position: absolute;
      top: - rem(4px);
      bottom: rem(13px);
      left: - rem(19px);
      width: 1px;
      content: "";
      border-left: 1px dashed $border-color;

      @include bp-down(sm) {
        left: - rem(22px);
      }
    }

    .i3-filter__filter {
      &::before {
        position: absolute;
        top: rem(10px);
        left: - rem(19px);
        width: rem(19px);
        height: 1px;
        content: "";
        border-top: 1px dashed $border-color;

        @include bp-down(sm) {
          top: rem(15px);
        }
      }
    }
  }

  &__filter-count {
    font-size: $font-size-xs;
    color: color("muted");
  }
}
