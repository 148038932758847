.overlay-scrollable {
  position: relative;
  height: 100%;

  &__content {
    height: 100%;
    overflow-x: hidden;
    overflow-y: auto;
    background: color("gray-bg");

    @include bp-down(sm) {
      overflow-y: scroll;
    }
  }

  .overlay-header {
    position: absolute;
    top: 0;
    right: 0;
    left: 0;
  }
}
