// stylelint-disable selector-no-qualifying-type

.accessories-list-page {
  .promo {
    z-index: $zindex-backdrop;
    &__content {
      margin-top: 0;
    }
  }
  .choose-spec {
    &__content {
      min-height: 105px;

      .btn {
        padding-top: 1.25rem;
      }

      .btn--primary:disabled {
        color: color("border");
        background-color: color("muted-light");
        border-color: color("muted-light");
        opacity: 1;
      }
    }

    &__options {
      display: flex;
      margin-top: space(2);
      font-size: $font-size-base;
    }

    &__options-header {
      margin-right: space(2);
      color: color("white");
    }

    .inline-bullets {
      color: color("white");
    }
  }
}

.accessories-list {
  padding: space(5) 0;
  background: color("gray-bg");

  @include bp-down(sm) {
    padding: space(3) 0 space(5);
  }

  &__header {
    display: flex;
    align-items: center;
    margin-bottom: rem(12px);

    &__title {
      margin-right: space(2);
      font-size: $font-size-xs;
      font-weight: $font-weight-medium;
    }

    &__store-selector {
      display: flex;
      align-items: center;
      margin-left: auto;
      cursor: pointer;

      @include bp-down(sm) {
        display: block;
      }
      &__input {
        display: flex;
        align-items: center;

        .vehicle-list-option {
          margin-right: space(2);
          cursor: pointer;
        }

        &:not(:last-child) {
          @include bp-down(sm) {
            margin-bottom: space(1);
          }
          margin-right: space(3);
        }
        &__text {
          font-size: $font-size-xs;
          font-weight: $font-weight-medium;
          &--muted {
            margin-left: space(1);
            font-size: $font-size-xs;
            font-weight: $font-weight-medium;
            color: color("muted");
          }
        }
      }
    }
  }

  &__items {
    display: flex;
    flex-wrap: wrap;
  }

  &__item {
    @include make-col-ready();
    @include make-col(3);
    display: flex;
    margin-bottom: space(3);

    @include bp-down(lg) {
      @include make-col(3);
    }

    @include bp-down(md) {
      @include make-col(4);
    }

    @include bp-down(sm) {
      @include make-col(12);
    }
  }

  &__full-item {
    @include make-col-ready();
    padding-top: space(5);
    padding-bottom: space(5);

    @include bp-only(lg) {
      @include make-col(12);
    }

    @include bp-only(md) {
      @include make-col(10);
      @include make-col-offset(1);
    }

    @include bp-down(sm) {
      @include make-col(12);
      width: calc(100% + #{$grid-gutter-width-sm});
      max-width: none;
      padding-right: 0;
      padding-left: 0;
      margin-right: -($grid-gutter-width-sm / 2);
      margin-left: -($grid-gutter-width-sm / 2);
    }
  }

  &__full-item-wrapper {
    width: 100%;
  }

  &__floating-filter {
    position: fixed;
    top: auto;
    bottom: 60px;
    left: 50%;
    z-index: 101;
    width: 60px;
    height: 60px;
    margin-left: -30px;
    font-size: 24px;
    line-height: 62px;
    color: color("white");
    text-align: center;
    cursor: pointer;
    background-color: color("primary");
    border-radius: 30px;

    body.overlay-open & {
      display: none;
    }

    @include bp-down(sm) {
      display: none;
    }

    .scrolled--up & {
      top: 260px;
      bottom: auto;
    }
  }
}
