.department-actions {
  .btn {
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: $font-size-sm;
  }

  .call-action__number {
    font-size: $font-size-sm;

    &--toggler {
      span {
        font-size: $font-size-sm;
        color: $white;
        text-decoration: none;
      }
    }
  }

  svg {
    margin-right: space(1);
    margin-bottom: 2px;
  }
}
