.eon-product-buy-actions {
  &__row {
    display: flex;
    justify-content: flex-end;

    + .feedback-message {
      margin-top: space(3);
    }
  }

  &__selected {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 56px;
    color: color("green");
    background-color: color("green-bg");
    border: $border-width solid color("green");
    border-radius: $border-radius;

    svg {
      margin-right: space(1);
    }
  }

  .btn {
    margin-left: rem(12px);

    @include bp-down(sm) {
      padding: space(3);
      line-height: 1.3rem;
    }

    &--build-tyre {
      margin-left: 0;
    }
  }

  .form-stack {
    margin-bottom: 0;
  }
}
