.pickup-locations-table {
  &__item {
    display: flex;
    padding: space(3) 0;
    font-size: $font-size-sm;
    border-bottom: 1px solid color("border-light");

    &:last-child {
      padding-bottom: 0;
      border-bottom: 0;
    }

    &:first-child {
      padding-top: 0;
    }

    div:first-child {
      width: 70%;
    }
    div:last-child {
      width: 30%;
      font-size: $font-size-xs;
      text-align: right;
    }

    .fa-map-marker-alt {
      margin-right: space(2);
      color: color("blue");
    }
  }

  &--header {
    padding: 0 space(4) space(2);
    font-size: $font-size-xxs;
    color: color("muted");

    text-transform: uppercase;
    .pickup-locations-table__item {
      padding: 0;
      div:first-child,
      div:last-child {
        font-size: $font-size-xs;
      }
    }
  }
}
