.backdrop {
  position: fixed;
  top: 0;
  bottom: 0;
  left: -100%;
  z-index: $zindex-backdrop;
  width: 100%;
  height: 100%;
  background: rgba(gray("900"), 0.4);
  opacity: 0;
  transition: opacity $transition-base, left 0s linear 0.22s;

  @include bp-down(sm) {
    display: none;
  }

  &--active {
    left: 0;
    opacity: 1;
    transition: opacity $transition-base;
  }

  &--aside {
    z-index: $zindex-backdrop-aside;
  }
}

