.cart-upsell-item {
  display: flex;
  padding: space(3);
  margin-bottom: space(2);
  background: $white;
  border: $border-width dashed $border-color;
  border-radius: $border-radius;
  @include bp-down(sm) {
    flex-direction: column;
  }

  &__main {
    display: flex;
    align-items: center;
    width: 60%;
    @include bp-down(sm) {
      width: 100%;
    }
    img {
      width: auto;
      max-width: rem(45px);
      height: auto;
      max-height: rem(50px);
      margin-right: space(2);
      border-radius: $border-radius;
    }
  }

  &__product-name {
    font-size: $font-size-xs;
    font-weight: $font-weight-medium;
    line-height: rem(16px);
  }

  &__actions {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 40%;

    @include bp-down(sm) {
      width: 100%;
      margin-top: space(2);
    }
  }

  &__price {
    .price-tag {
      display: block;
      font-size: $font-size-xs;
      text-align: right;

      &:first-child {
        font-weight: $font-weight-medium;
      }
    }
  }
}
