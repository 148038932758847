// stylelint-disable selector-no-qualifying-type
.site-alerts {
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  height: $site-alerts-height;
  transition: transform $transition-base;
  transform: translateY(- $site-alerts-height);
}

body.has-large-site-alerts {
  .site-alerts {
    height: $site-alerts-large-height;
    transform: translateY(- $site-alerts-large-height);

    .alert-bar {
      height: $site-alerts-large-height;

      &__content p {
        text-overflow: initial;
        white-space: normal;
      }
    }

  }
}

body:not(.scrolled) .site-alerts {
  transform: none;
}
