.vehicle-slider {
  @include bp-down(sm) {
    padding-right: 0;
  }

  .swiper-slide {
    @include bp-down(sm) {
      width: calc(95% - 4px);
    }
    height: auto;
  }

  .product-spot {
    height: 100%;
  }

  .swiper-container {
    overflow: visible;
  }
}
