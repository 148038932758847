// stylelint-disable declaration-no-important
.contact {
  margin-bottom: space(4);

  &--slim {
    margin-top: space(4);

    h4 {
      @include bp-up(md) {
        font-size: $h3-font-size;
      }
    }
  }
  &__tabs {
    display: flex;
    flex-wrap: wrap;
    margin-right: -rem(20px);
    margin-left: -rem(20px);

    .tab-button {
      width: calc(16.666% - #{rem(20px) * 2});
      margin: rem(20px) rem(20px) rem(20px) 0;
    }

    @include bp-down(lg) {
      margin-right: -rem(10px);
      margin-left: -rem(10px);

      .tab-button {
        width: calc(16.666% - #{rem(10px) * 2});
        margin: rem(10px);
      }
    }

    @include bp-down(md) {
      .tab-button {
        width: calc(33.333% - #{rem(10px) * 2});
      }
    }

    @include bp-down(sm) {
      .tab-button {
        width: calc(50% - #{rem(10px) * 2});
        margin: rem(6px) rem(10px);
      }
    }
  }

  &__department {
    display: flex;
    justify-content: space-between;
    padding: space(4);
    background: color("white");
    border: $border-width solid $border-color;
    border-radius: $border-radius;

    @include bp-down(sm) {
      display: block;
    }
  }

  &__department-content {
    width: 100%;
    padding-right: rem(16px);
  }

  &__department-actions {
    margin-bottom: space(4);
    .btn {
      display: block;
      margin-bottom: space(1);
    }

    @include bp-down(sm) {
      margin-top: space(3);
    }
  }

  &__department-placeholder {
    display: flex;
    align-items: center;
    justify-content: center;
    min-height: rem(144px);
    padding: 0 space(4);
    color: color("muted-light");
    border: $border-width dashed $border-color;
  }

  &__faq-question {
    margin-bottom: space(2);

    > div {
      display: inline;
    }

    i,
    svg {
      font-size: rem(24px);
    }
  }

  &__faq-answer {
    margin-bottom: space(1);
    font-size: $font-size-sm;
  }

  .reg-nr {
    border: $border-width solid $border-color;
    border-radius: $border-radius;

    &__field {
      position: relative;
      display: flex;
      width: 100%;
      background-color: color("white");
      border-radius: $border-radius;

      &__symbol {
        border-top-left-radius: $border-radius;

        &--rounded {
          border-bottom-left-radius: $border-radius;
        }
      }

      &__container {
        border-radius: 0;
      }

      input {
        background-color: transparent !important;
        border: 0;
      }
    }

    &__specs {
      padding: space(3) space(2);
      font-size: $font-size-sm;
      font-weight: $font-weight-medium;
      background: $green-light;
      border-top: $border-width solid $border-color;
      border-bottom-right-radius: $border-radius;
      border-bottom-left-radius: $border-radius;
    }
  }
  &-department-header {
    display: flex;
    justify-content: flex-end;

    @include bp-down(sm) {
      display: none;
    }
  }
}
