.mini-product-spot {
  position: relative;
  display: flex;
  flex-direction: column;

  &__thumbnail {
    height: 118px;
    margin-bottom: 12px;
    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
    &--rounded {
      img {
        border-radius: $border-radius;
      }
    }
  }

  &__header {
    margin-bottom: space(1);
    overflow: hidden;
    font-size: $font-size-xs;
    font-weight: $font-weight-medium;
    text-overflow: ellipsis;
    white-space: nowrap;
  }

  &__sub-title {
    overflow: hidden;
    font-size: $font-size-xs;
    color: color("muted");
    text-overflow: ellipsis;
    white-space: nowrap;
  }

  &__meta {
    margin-bottom: space(2);
    font-size: $font-size-xs;
    color: color("muted-light");

    &__condition {
      padding: space(1) space(1) 1px space(1);
      font-size: $font-size-xxs;
      color: color("white");
      text-transform: uppercase;
      background: color("muted-light");
      border-radius: rem(2px);
    }
  }

  .price-tag {
    font-size: $font-size-xs;
    font-weight: $font-weight-medium;

    &--obsolete {
      font-size: $font-size-xs;
    }
  }
}
