.campaign-grid {
  display: grid;
  grid-gap: 1px;
  grid-template-columns: 1fr 1fr;
  margin-bottom: space(5);
  .promo {
    height: rem(340px);

    @include bp-up(md) {
      height: rem(620px);
    }

    .ribbon {
      display: none;
    }

    &.promo--overlay {
      padding-top: 0;
    }
    .sticker {
      margin-top: 0;
    }
    .promo__text {
      display: none;
    }

    @include promo-bg-overlay();

    &--box {
      @include bp-down(sm) {
        margin-bottom: 0;
        .promo__container {
          justify-content: flex-start;
        }
        @include promo-bg-overlay();
      }
    }
  }

  &:not(&--gap) {
    .promo {
      &,
      &::before {
        border-radius: 0;
      }
    }
  }

  @include bp-down(sm) {
    grid-template-columns: 1fr;

    .promo .btn {
      display: none;
    }
  }

  &--gap {
    grid-gap: space(6);

    .promo {
      @extend .promo--padding-sm;
    }
    @include bp-down(sm) {
      grid-gap: space(3);
    }
  }
}
