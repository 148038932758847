.gallery-slideshow {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: $zindex-modal;
  display: none;
  background: rgba($black, 0.8);

  &--active {
    display: block;
  }

  .circle-action {
    position: absolute;
    top: rem(40px);
    right: rem(40px);
    z-index: 2;
    @include bp-down(md) {
      top: rem(24px);
      right: rem(24px);
    }
  }

  &__container {
    height: 100%;
  }

  &__main {
    position: relative;
    display: flex;
    width: 100%;
    height: 100%;
    margin-bottom: space(2);

    .swiper-slide {
      display: flex;
      align-items: center;
      justify-content: center;
      background: color("black");

      .phy-ply-holmgrensbil .phy-overlay-ctrls {
        margin-bottom: 65px;
      }
    }
  }

  &__main-image {
    position: relative;
    max-height: 100%;
  }

  &__main-caption {
    position: absolute;
    bottom: rem(7px);
    left: 0;
    font-size: $font-size-xs;
    color: $white;
  }

  &__nav {
    position: absolute;
    top: calc(50% - 20px);
    z-index: 1;
    display: flex;
    align-items: center;
    justify-content: center;
    width: rem(48px);
    height: rem(48px);
    font-size: $font-size-lg;
    color: color("primary");
    cursor: pointer;
    background-color: rgba(255, 255, 255, 0.9);
    border-radius: rem(48px);
    transition: opacity $transition-base;

    @include bp-down(sm) {
      display: none;
    }

    &--prev {
      left: space(3);
    }

    &--next {
      right: space(3);
    }

    &--disabled {
      cursor: default;
      opacity: 0.3;
    }
  }

  .swiper-container {
    width: 100%;
    max-width: 100%;
    margin-right: auto;
    margin-left: auto;
  }

  .swiper-pagination-bullets {
    bottom: space(4);
  }
  .swiper-pagination-bullet {
    background: gray("500");
    opacity: 1;
  }

  .swiper-pagination-bullet-active {
    background: color("primary");
  }
}
