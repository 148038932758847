// stylelint-disable declaration-no-important
.vehicle-configurator {
  &__main {
    padding: space(6);
    background: $white;
    border-top: $border-width solid $gray-300;
    border-right: $border-width solid $gray-300;
    border-left: $border-width solid $gray-300;
    border-top-left-radius: $border-radius;
    border-top-right-radius: $border-radius;
    box-shadow: 0px 1px 1px #0000000a;

    @include bp-down(sm) {
      padding-right: space(3);
      padding-left: space(3);
    }
  }

  &__header {
    margin-bottom: space(4);

    @include bp-down(sm) {
      margin-bottom: 0;
    }
  }

  &.configurator-app {
    padding-bottom: space(3);
  }

  &__btn-icon {
    margin-right: rem(12px);
  }

  &__button-error {
    margin-top: space(3);
    font-size: $font-size-sm;
    font-weight: $font-weight-medium;
  }

  .configurator-section {
    &__loader {
      font-size: rem(32px);
      text-align: center;
    }

    &__title-container {
      display: flex;
      justify-content: space-between;
    }

    &__title {
      margin-bottom: space(3);
    }

    &__icon {
      margin-right: space(2);
    }

    &__summary {
      padding: 1rem;
      margin-top: 1rem;
      background-color: #f7f7f9;
      border-radius: 0.375rem;
    }

    &__completed {
      padding: space(3);
      margin-bottom: space(2);
      background-color: color("green-bg");
      border-radius: rem(6px);

      &__sub-action {
        padding-top: rem(12px);
        font-size: $font-size-sm;
        border-top: $border-width solid $border-color;

        &--email {
          .form-stack {
            flex: 1;

            @include bp-up(md) {
              margin-bottom: 0;
            }
          }
        }
      }
    }
  }

  .details-bar {
    padding-bottom: rem(12px);
    &__title {
      display: flex;
      justify-content: space-between;
      margin-bottom: space(2);
      font-size: $font-size-sm;
      font-weight: $font-weight-medium;
      color: $black;
    }

    &__item-list {
      display: flex;
      justify-content: space-between;
      margin-bottom: space(2);
      font-size: $font-size-xs;
      color: #18191abf;
    }
  }

  .finance-selector {
    &__information-field {
      padding: space(4);
      background-color: color("blue-bg");
      border: $border-width dashed $border-color;

      .link {
        font-weight: $font-weight-medium;
      }

      @include bp-down(sm) {
        margin-top: space(3);
      }
    }

    .price-tag {
      font-size: $font-size-sm;
      font-weight: $font-weight-medium;

      &:not(.price-tag--discount) {
        color: $black;
      }
    }

    .form-row:last-child .form-row__col {
      margin-bottom: 0;
    }

    .configurator-section__input-row {
      padding-top: space(3);
    }
  }

  &__text-input-headline {
    margin-bottom: space(2);
    font-size: $font-size-sm;
    font-weight: $font-weight-medium;
  }

  .trade-in-selector {
    .configurator-text-input {
      margin-bottom: rem(12px);
    }
  }

  .service-agreement-selector {
    .configurator-select {
      margin-bottom: rem(12px);

      @include bp-down(md) {
        margin-left: 0;
      }
    }

    &__input-step {
      .configurator-section__input-row {
        @include bp-down(md) {
          flex-direction: column;
        }
      }
    }

    &__product-row {
      &:not(:last-child) {
        margin-bottom: rem(12px);
      }
    }
  }

  .summation-list {
    display: flex;
    flex: 1;
    flex-direction: column;

    &--sub-list {
      margin-top: space(2);

      .text--value {
        font-weight: $font-weight-base !important;
      }
    }

    &__row {
      display: flex;
      justify-content: space-between;

      &:not(:last-child) {
        margin-bottom: space(2);
      }

      .link {
        font-weight: $font-weight-medium;
      }

      .text {
        font-size: $font-size-sm;
        &--value {
          font-weight: $font-weight-medium;
        }

        &--discount {
          color: color("danger");
        }

        &--obsolete {
          color: color("muted");
          text-decoration: line-through;
        }
      }
    }
  }

  &__summation-list {
    &__row {
      display: flex;
      justify-content: space-between;

      &:not(:last-child) {
        margin-bottom: rem(12px);
      }

      &:first-child {
        .vehicle-configurator__summation-list__label {
          font-weight: $font-weight-medium;
          color: $gray-900;
        }

        .vehicle-configurator__summation-list__value {
          font-weight: $font-weight-medium;
        }
      }

      &--cash-summation {
        .vehicle-configurator__summation-list__label {
          font-weight: $font-weight-regular;
        }

        .vehicle-configurator__summation-list__value {
          font-weight: $font-weight-regular;
        }
      }

      &--cash-total-row {
        .vehicle-configurator__summation-list__label,
        .vehicle-configurator__summation-list__value {
          font-weight: $font-weight-medium;
          color: $gray-900;
        }
      }
    }

    &__totals {
      padding: space(3) 0 space(2);
      border-top: $border-width solid $border-color;
    }

    &__label {
      font-size: $font-size-xs;
      color: #18191abf;
    }

    &__value {
      font-size: $font-size-xs;
      color: $gray-900;
      white-space: nowrap;

      &--discount {
        color: color("danger");
      }

      &--obsolete {
        color: color("muted");
        text-decoration: line-through;
      }
    }

    &__expand {
      display: flex;
      justify-content: space-between;
      padding-bottom: space(2);
      cursor: pointer;
      user-select: none;

      span {
        font-size: $font-size-xs;
        color: #18191abf;
        text-decoration: underline;
      }

      i {
        font-size: $font-size-sm;
        color: $black;
      }
    }
  }

  .tyre-selector {
    &__item {
      width: calc(50% - 8px);
      margin-bottom: space(4);
      &:nth-child(2n) {
        margin-left: space(3);
      }

      &__heading {
        margin-bottom: space(2);
        font-size: $font-size-sm;
        font-weight: $font-weight-medium;
      }

      &__price-container {
        display: flex;
        align-items: center;
      }

      &__price {
        margin-bottom: space(2);
        font-size: $font-size-sm;

        &--discount {
          font-weight: $font-weight-medium;
          color: color("danger");
        }

        &--obsolete {
          margin-left: space(2);
          font-size: $font-size-xs;
          color: color("muted");
          text-decoration: line-through;
        }
      }

      a {
        font-size: $font-size-sm;
        color: #18191abf;
      }
    }
    &__up-sell {
      margin-top: space(3);
    }
  }

  .accessories-selector {
    &__input-row {
      &:not(:first-child) {
        margin-top: rem(12px);
      }
    }

    &__heading {
      display: flex;
      justify-content: space-between;
      margin-bottom: space(2);
      font-size: $font-size-sm;
      font-weight: $font-weight-medium;
    }
    &__link {
      font-size: $font-size-xs;
      color: #18191abf;
    }
    &__link-container {
      padding-top: space(2);
    }
  }

  .additional-service-selector {
    &__submit-btn {
      margin-bottom: 12px;
    }
  }

  .selected-option {
    background: color("blue-bg");
  }

  &__summation {
    padding-right: space(6);
    padding-bottom: space(6);
    padding-left: space(6);
    background: color("blue-bg");
    border-top: $border-width dashed $gray-300;
    border-right: $border-width dashed $gray-300;
    border-left: $border-width dashed $gray-300;
    border-bottom-right-radius: $border-radius;
    border-bottom-left-radius: $border-radius;
    box-shadow: 0px 1px 1px #0000000a;

    @include bp-down(sm) {
      padding-right: space(3);
      padding-left: space(3);
    }

    &__addons {
      .vehicle-configurator__summation__row {
        border-top: 0 !important;
      }
    }

    &__row {
      padding-top: space(4);
      padding-bottom: space(4);
      &:first-child {
        border-top: $border-width solid $border-color;
      }
      &:not(:last-child) {
        border-bottom: $border-width solid $border-color;
      }

      &--black-border:not(:last-child) {
        margin-bottom: space(5);
        border-top: 2px solid $black;
        border-bottom: 2px solid $black;
      }

      .serviceagreementblock &--black-border {
        margin-top: space(3);
        border-top: 2px solid $black;
        border-bottom: 2px solid $black;
      }
    }

    &__sub-notice {
      margin-top: space(3);
      font-size: $font-size-xs;
      color: color("muted");
    }

    &__price {
      .price-tag {
        font-size: $font-size-lg;
        font-weight: $font-weight-medium;
      }

      .price-tag--obsolete {
        font-size: $font-size-sm;
      }

      @include bp-down(sm) {
        display: none;
      }
    }

    &__meta-data {
      font-size: $font-size-xs;
      color: color("muted");
      .meta-tag {
        margin-right: space(1);
        margin-left: 0;
        font-size: $font-size-xxs;
        text-transform: uppercase;
      }
    }
  }

  &__contact-form {
    &__input-section {
      margin-bottom: space(4);

      &:first-child {
        margin-top: space(4);
      }
    }
  }

  &__tab-bar {
    display: flex;
    border-bottom: none;
    &__item {
      width: 50%;
      padding-top: 0;
      margin-right: 0;
      font-size: $font-size-sm;
      text-align: center;
      border-bottom: $border-color solid $border-width;

      &.tab-bar__item--active {
        border-bottom-color: color("primary");
        border-bottom-width: 2px;
      }
    }
  }

  &__department-container {
    display: flex;
    padding: space(4) 0;
  }

  &__department {
    flex: 1;
    padding-right: space(3);
    &__title {
      font-weight: $font-weight-medium;
    }
    &__adress {
      margin-top: rem(12px);
      font-size: $font-size-sm;
    }
    // &__directions-link {

    // }
  }

  &__department-map {
    position: relative;
    width: 33%;
    height: 100px;
    background-position: center;
    background-size: cover;
    border: $border-width solid $border-color;
    border-radius: rem(6px);
    box-shadow: 0px 0.5px 0px #00000029;

    a {
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
    }
  }

  &__link {
    font-size: $font-size-sm;
    color: #18191abf;
  }

  .opening-hours {
    display: flex;

    &__clock {
      margin-top: 2px;
    }

    &__container {
      width: 100%;
    }

    &__indicator {
      color: color("success");
    }
    &__row {
      display: flex;
      justify-content: space-between;
      margin-top: rem(4px);

      &:first-child {
        margin-top: space(2);
        font-weight: $font-weight-medium;
      }
    }

  }

  &__department-links {
    margin-top: space(4);

    &__item {
      padding: space(3) 0;
      font-size: $font-size-sm;
      border-top: $border-width solid $border-color;
    }
    &__icon {
      color: $black;
    }
    &__chevron {
      margin-left: space(2);
    }
  }

  &__feedback {
    display: flex;
    padding: space(4);
    margin-top: space(4);
    border-radius: rem(6px);
    &--success {
      background-color: color("green-bg");

      svg {
        color: color("success");
      }
    }

    &--error {
      background-color: color("red-bg");

      svg {
        color: color("red");
      }
    }

    svg {
      margin-right: space(3);
    }

    &__title {
      margin-bottom: space(2);
      font-size: $font-size-sm;
      font-weight: $font-weight-medium;
    }

    &__text {
      font-size: $font-size-sm;
    }
  }

  &__modal {
    margin: rem(-40px);

    @include bp-down(md) {
      padding-bottom: 88px;
      margin: rem(-24px) rem(-16px);
    }

    &--loading {
      display: flex;
      align-items: center;
      justify-content: center;
      min-height: 300px;
    }

    &__loader {
      font-size: 40px;
    }

    &__container {
      display: flex;

      @include bp-down(md) {
        flex-direction: column;
      }
    }

    &__column {
      width: 50%;
      padding: space(5);

      @include bp-down(md) {
        width: auto;
      }

      &:first-child {
        background-color: $white;
      }

      .product-main-action__configurator,
      .product-main-action__summary {
        padding-right: 0;
        padding-left: 0;
      }

      .product-main-action__summary {
        border-bottom: none;
      }
    }

    &__tab-container {
      .form-text {
        font-size: $font-size-xxs;
        color: #18191abf;
      }
    }


    &__total-cash-price {
      display: flex;
      justify-content: space-between;
      padding-top: space(3);
      font-size: $font-size-sm;
      font-weight: $font-weight-medium;
      border-top: $border-width solid $border-color;

      .price-tag--obsolete {
        margin-left: space(2);
      }
    }

    &__vehicle-container {
      display: flex;
    }
    &__vehicle-image {
      max-width: rem(68px);

      img {
        width: 100%;
        border-radius: rem(4px);
        object-fit: contain;
      }
    }

    &__vehicle-info {
      margin-left: rem(12px);
    }

    &__vehicle-title {
      font-size: $font-size-xs;
      font-weight: $font-weight-medium;
    }

    &__vehicle-sub-title {
      font-size: $font-size-xs;
      color: #18191abf;
    }

    // &__vehicle-data-container {
    //   // display: flex;
    // }

    &__vehicle-data-item {
      font-size: $font-size-xxs;
      color: #18191abf;
    }

  }

  .total-price {
    &__row {
      display: flex;
      align-items: center;
      justify-content: space-between;
      &:not(:last-child) {
        margin-bottom: space(2);
      }

      @include bp-down(md) {
        flex-direction: column;
        align-items: flex-start;

        &:not(:first-child) {
          margin-top: space(3);
        }
      }
    }
    &__title {
      font-size: $font-size-xs;
      font-weight: $font-weight-bold;

      &--meta {
        font-weight: $font-weight-base;
        color: color("muted-light");
      }
    }
    .price-tag {
      font-size: $font-size-lg;
      font-weight: $font-weight-medium;
      &--obsolete {
        font-size: $font-size-sm;
        font-weight: $font-weight-base;
      }

      @include bp-down(md) {
        margin-top: space(2);
      }
    }
  }

  .form-stack {
    @include bp-down(sm) {
      margin-right: 0 !important;
    }
  }

  .delivery-indicator {
    width: rem(6px);
    height: rem(6px);
    margin-right: space(2);
    border-radius: rem(4px);
    &--available {
      background-color: color("success");
    }

    &--unavailable {
      background-color: color("secondary");
    }

  }
}

.main-info {
  padding: space(3) space(4);
  font-size: $font-size-sm;
  border-top: $border-width solid $border-color;
  &__heading {
    margin-bottom: space(2);
    font-size: $font-size-xs;
    font-weight: $font-weight-medium;
  }
  &__data {
    margin-bottom: space(1);
    font-size: $h2-sm-font-size;
    font-weight: $font-weight-medium;
  }
  &__meta {
    font-size: $font-size-xs;
    color: color("muted");
  }
}
