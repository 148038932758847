.column-layout {

  &__main {
    display: flex;
    padding-bottom: space(6);
    border-bottom: $border-width solid $gray-400;

    @include bp-down(md) {
      flex-direction: column;
      border-bottom: 0;
    }
  }

  &__column {
    position: relative;
    width: 60%;
    min-height: 1px;

    .container {
      padding-left: 0;
      @include bp-down(md) {
        padding-right: 0;
      }
    }

    @include bp-down(md) {
      flex: none;
      width: auto;
      width: 100%;
      padding-right: 0;
    }

    &__blocks {
      padding: 0 space(8);
    }

    &--right {
      width: 40%;

      @include bp-down(md) {
        width: 100vw;
        margin-right: rem(-16px);
        margin-left: rem(-16px);
      }
    }
  }

  &__image-container {
    @include bp-down(md) {
      padding: 0;
    }
  }
}
