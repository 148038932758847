// modified default theme from vue-slider-component

.vue-slider {
  margin-right: rem(10px);
  margin-left: rem(10px);
}

// component style
.vue-slider-disabled {
  cursor: not-allowed;
  opacity: 0.5;
}

// rail style
.vue-slider-rail {
  background-color: color("border");
  border-radius: 15px;
}

// process style
.vue-slider-process {
  background-color: color("primary");
  border-radius: 15px;
}

// mark style
.vue-slider-mark {
  z-index: 4;

  &:first-child,
  &:last-child {
    .vue-slider-mark-step {
      display: none;
    }
  }

  &:first-child {
    .vue-slider-mark-label {
      left: -10px;
      transform: none;
    }
  }

  &:last-child {
    .vue-slider-mark-label {
      right: -10px;
      left: auto;
      transform: none;
    }
  }

  @at-root &-step {
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.16);
    border-radius: 50%;

    // &-active {
    // }
  }

  @at-root &-label {
    /* stylelint-disable-next-line declaration-no-important */
    margin-top: rem(20px) !important;
    font-size: $font-size-sm;
    color: color("muted-light");
    white-space: nowrap;
    // &-active {
    // }
  }
}

// dot style
.vue-slider-dot {
  @at-root &-handle {
    width: 100%;
    height: 100%;
    text-align: center;
    cursor: pointer;
    background-color: #fff;
    border-radius: 50%;
    box-shadow: 0.5px 0.5px 2px 1px rgba(0, 0, 0, 0.32);

    svg {
      margin-top: rem(5px);
      font-size: rem(22px);
    }

    @at-root &-focus {
      box-shadow: 0px 0px 1px 2px rgba(color("primary"), 0.36);
    }
    @at-root &-disabled {
      cursor: not-allowed;
      background-color: #ccc;
    }
  }
}
