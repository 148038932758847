// stylelint-disable selector-no-qualifying-type
.config-btn-row {
  margin-top: 16px;
  &__btn {
    margin-top: 0.25rem;
    font-size: 0.75rem;
    font-weight: 500;
  }
}

.hero {
  &__gallery {
    position: relative;

    &__image {
      img {
        width: 100%;
      }
    }

    &__modal-btn {
      position: absolute;
      right: 0;
      bottom: 0;

      &__icon {
        padding-top: 2px;
      }
    }
  }
}

.container {
  .color-selector__item {
    .option-box {
      height: 100%;
      &__control {
        justify-content: center;
      }
      &__control-check {
        display: none;
      }
      &__control-label {
        text-align: center;
      }
    }
  }
}

body:not(.scrolled).has-site-alerts {
  .modal {
    top: $site-header-height + $site-alerts-height;

    @include bp-down(sm) {
      top: $site-header-height-sm + $site-alerts-height;
    }
  }
}

body {
  .modal {
    top: 82px;

    @include bp-down(sm) {
      top: 60px;
    }
  }
}

body:not(.scrolled) {
  .modal {
    top: $site-header-height;

    @include bp-down(sm) {
      top: $site-header-height-sm;
    }
  }
}

.modal {
  .modal-text {
    margin-bottom: space(4);
  }

  .modal-list {

    &__item {
      display: flex;
      padding-top: space(3);
      padding-bottom: space(3);
      font-size: $font-size-lg;

      &:not(:last-child) {
        border-bottom: $border-width solid $border-color;
      }

      &__icon {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 24px;
        padding-bottom: 2px;
        margin-right: 12px;
      }
    }
  }
}

