// stylelint-disable declaration-no-important
.service-booking-selector {
  position: relative;
  z-index: 10;
  display: flex;
  align-items: center;
  justify-content: center;

  &--readonly {
    @include bp-down(md) {
      padding: space(3);
      background: color("white");
      transform: translateY(0);
      @include border-bottom();
    }
  }

  &--container {
    @extend .container;
    max-width: 52rem;
    padding: 0;
    margin-bottom: -84px;
    transform: translateY(-20%);

    @include bp-down(md) {
      margin-bottom: -50px;
      transform: translateY(-20px);
    }

    .form-step {
      margin-bottom: 0;
    }
  }

  &--content {
    display: flex;
    flex-direction: column;

    .feedback {
      display: flex;
      padding: space(3);
      margin-top: 1rem;
      font-size: $font-size-sm;
      border-radius: $border-radius;

      &--standard {
        background-color: color("blue-bg");
      }
      //TODO ADD COLORS TO GENERIC POSITION
      &--success {
        background-color: color("green-bg");
      }
      //TODO ADD COLORS TO GENERIC POSITION
      &--error {
        background-color: color("red-bg");
      }

      &--mobile {
        display: none;
        @include bp-down(sm) {
          display: flex;
        }
      }

      @include bp-down(sm) {
        flex-direction: column;
        align-items: flex-start;
        margin-bottom: space(3);
      }
    }
  }

  &__columns {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    background-color: color("white");
    border-top: 1px solid color("border");
    border-right: 1px solid color("border");
    border-left: 1px solid color("border");
    border-radius: $border-radius;

    &__main-actions {
      display: flex;
      flex: 1;

      @include bp-down(md) {
        flex-direction: column;
        width: 100%;
        height: rem(64px);
      }
    }

    &--readonly {
      align-items: normal;
      justify-content: start;
      @include bp-down(md) {
        padding: 0;
        border: none;
      }
    }

    @include bp-up(lg) {
      @include border-bottom();
    }

    @include bp-down(lg) {
      width: 100%;
    }

    @include bp-down(md) {
      flex-direction: column;

    }

    &--hidden {
      @include bp-down(md) {
        flex-direction: row;
      }
      @include bp-down(sm) {
        flex-direction: column;
      }
    }

    // .feedback {
    //   display: block;
    //   width: 100%;
    //   padding: 1rem;
    //   text-align: center;
    //   background-color: color("green-bg");
    //   border-bottom: $border-width solid $border-color;

    //   @include bp-up(md) {
    //     display: flex;
    //   }
    // }
  }

  &__column {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: space(2);

    &--readonly {
      width: auto !important;
      padding: space(5) space(3);
      font-size: $font-size-sm;
      border-right: none;
      &:first-child {
        padding-left: space(4);
        &::before {
          background-color: color("success");
        }
      }
      i,
      svg {
        color: color("success");
      }

      @include bp-down(md) {
        padding: space(1) space(2);
        border: none !important;
        &:first-child {
          padding-left: space(2);
          &::before {
            display: none;
          }
        }
      }
    }

    &--invalid {
      select {
        font-size: $font-size-sm !important;
      }
    }

    &--notify {
      > .form-stack,
      .regnr-input {
        transform: scale(1.05);
      }
    }

    &--service {
      flex: 1;

      @include bp-up(md) {
        border-right: 1px solid color("border");
      }

      @include bp-down(md) {
        width: 100%;
      }
    }
    &--regnr {
      border-right: 1px solid color("border");
      .regnr-input .input-group {
        max-width: 30rem;

        @include bp-down(md) {
          flex-wrap: nowrap;
        }
      }
    }
    &--department {
      flex: 1;
      width: 100%;
      @include bp-up(md) {
        width: 30%;
        border-right: 1px solid color("border");
      }
    }
    &--submit {
      width: 25%;
      padding: space(2) space(3);
      border-right: 0;

      &.service-booking-selector__column--readonly {
        margin-left: auto;

        @include bp-down(md) {
          position: absolute;
          right: 0;
          width: auto !important;
        }
      }

      @include bp-down(md) {
        width: 100%;
        padding: space(2);
        border-bottom-right-radius: 4px;
      }
    }

    > .form-stack,
    .regnr-input {
      width: 100%;
      transition: transform ease-in 0.2s;
    }

    label {
      top: 0.8rem;
      font-size: $font-size-xxs;
      color: $black;

      @include bp-down(md) {
        top: 4px;
        font-size: 8px;
      }
    }

    @include bp-down(md) {
      display: block;
      border-bottom: 1px solid color("border");

      .btn--primary {
        border-top-left-radius: 0;
        border-top-right-radius: 0;
      }

      .form-stack {
        input,
        select {
          height: rem(48px) !important;
          padding-top: 19px;
          font-size: $font-size-sm;
        }
      }
    }

    .form-control {
      border: none;
    }

    .regnr-input__symbol {
      margin-right: 2px;
      border-radius: $border-radius !important;

      @include bp-down(md) {
        width: 32px;
        height: rem(48px);
        min-height: auto;
        &::before {
          top: 8px;
          left: 8px;
          width: 16px;
          height: 16px;
          background-size: contain;
        }

        &::after {
          bottom: 4px;
          left: 11px;
          font-size: 12px;
        }
      }
    }

    select,
    input {
      font-size: $font-size-lg;
      background-color: color("white") !important;
    }

    &--hidden {
      display: none !important;
      width: 0 !important;

      ~ .service-booking-selector__column {
        @include bp-up(md) {
          &--readonly {
            flex: none;
          }
        }
      }

      ~ .service-booking-selector__column--department {
        @include bp-down(md) {
          height: rem(90px);
          border-right: 1px solid color("border");
          border-bottom: 1px solid color("border");
        }
      }
      .service-booking-selector__column--regnr {
        width: 25%;
      }
    }
  }

  .regnr-input__tooltip {
    &--error {
      left: rem(-24px);
      background-color: color("red-bg");
      border-color: color("danger");

      &::before {
        background-color: color("red-bg");
        border-color: color("danger");
      }
    }
  }
}

.damage-report-page,
.service-booking-page-v2,
.tyre-booking-page,
.glass-repair-page,
.spare-parts-page,
.ac-cleaning-page,
.rim-repair-page,
.service-control-page {
  .service-booking-selector__columns {
    max-width: rem(832px);
  }
}

// When used in block

.section__content:not(.section__content--no-padding) {
  .servicebookingoverviewblock {
    margin-top: -space(8);
  }
}

.servicebookingoverviewblock > .container {
  @include bp-up(md) {
    padding-right: 0;
    padding-left: 0;

    .page-header__title {
      align-items: center;
    }
  }
}

.servicebookingoverviewblock {
  .service-booking-selector--container {
    width: 100%;
    max-width: inherit;
    margin-bottom: -130px;
    transform: translateY(-130px);
    @include bp-up(md) {
      padding: 0 space(6);
    }

    @include bp-down(md) {
      margin-bottom: -50px;
      transform: translateY(-20px);
    }
  }

  .promo--service-booking {
    @include bp-up(md) {
      padding-bottom: rem(125px);
    }
  }
}

.servicebookingoverviewblock.col-lg-12,
.servicebookingoverviewblock.col-md-12 {
  .service-booking-selector {
    &__columns {
      &__main-actions {
        flex: 2;
      }
    }
  }
}

.servicebookingoverviewblock.col-md-6,
.servicebookingoverviewblock.col-lg-4 {
  @include bp-up(md) {
    padding-bottom: space(8);
  }
  .service-booking-selector {
    @include bp-up(md) {
      margin-bottom: -50px;
      transform: translateY(-120px);

      &--readonly {
        padding: space(3);
        background: color("white");
        transform: translateY(0);
        @include border-bottom();
      }

      &__columns {
        flex-direction: column;
        width: 100%;

        &--readonly {
          padding: 0;
          border: none;
        }

        &--hidden {
          flex-direction: row;
          flex-direction: column;
        }

        &__main-actions {
          flex: 2;
        }
      }

      &__column {
        &--readonly {
          padding: space(1) space(2);
          border: none !important;
          &:first-child {
            padding-left: space(2);
            &::before {
              display: none;
            }
          }
        }

        display: block;
        width: 100% !important;
        border-bottom: 1px solid color("border");

        &--hidden {
          ~ .service-booking-selector__column {
            flex: 1;
            width: auto !important;
            &--readonly {
              flex: none;
            }
          }

          ~ .service-booking-selector__column--department {
            height: rem(90px);
            border-right: 1px solid color("border");
            border-bottom: 1px solid color("border");
          }
        }
      }
    }
  }
  .section {
    @include bp-up(md) {
      position: initial;
      right: 0;
      left: 0;
      width: auto;
      margin: 0;
      margin-top: -(space(5));
    }
  }
}

.servicebookingoverviewblock.col-lg-4 {
  .section {
    @include bp-up(md) {
      max-height: 256px;
    }
  }
}
