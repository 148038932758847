.quote-block {
  padding-left: space(4);
  border-left: 2px solid color("primary");

  @include bp-down(md) {
    padding-left: space(3);
  }

  &__quote {
    margin-bottom: space(5);
    font-size: $font-size-lg;
    font-weight: $font-weight-medium;

    @include bp-down(md) {
      margin-bottom: space(3);
      font-size: $font-size-sm;
    }
  }
  &__author {
    display: flex;
    align-items: center;
    &__image {
      width: rem(56px);
      height: rem(56px);
      margin-right: rem(12px);
      overflow: hidden;
      border: $border-width solid $gray-500;
      border-radius: 99999px;

      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }
    &__name {
      font-size: $font-size-sm;
      font-weight: $font-weight-medium;
      color: color("primary");

      @include bp-down(md) {
        font-size: $font-size-xs;
      }
    }
    &__title {
      font-size: $font-size-sm;
      color: $gray-700;

      @include bp-down(md) {
        font-size: $font-size-xs;
      }
    }
  }
}
