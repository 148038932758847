.campaign-tyre {
  .regnr-input input {
    color: color("black");
  }
  .choose-spec {
    margin-top: space(4);

    .choose-spec__options {
      @include bp-up(md) {
        display: flex;
      }

      &-header,
      .inline-bullets {
        font-size: $font-size-xs;
        color: color("white");
      }
      .inline-bullets {
        @include bp-up(md) {
          margin-left: space(2);
        }
      }
    }
  }

  .modal-content {
    color: color("black");
  }
}
