.inline-bullets {
  &__item {
    display: inline-block;

    &:not(:last-child) {
      &::after {
        margin: 0 rem(4px) 0 rem(6px);
        font-size: rem(9px);
        content: "•";
      }
    }
  }
}
