.search-results {
  padding: 0 space(3) space(4);

  &__heading {
    margin-top: space(4);
    margin-bottom: space(3);
    font-size: $font-size-xxs;
    font-weight: $font-weight-bold;
    text-transform: uppercase;
  }

  &__container {
    &--column {
      display: flex;
      flex-wrap: wrap;
      .search-results__item {
        width: calc(50% - 8px);
        margin-bottom: 0;
      }
      .search-results__item:nth-child(2n) {
        margin-left: space(3);
      }
    }
  }

  &__item {
    margin-bottom: 12px;
  }

  &__btn {
    width: 100%;
    margin-top: space(3);
  }
}

.search-response-link {
  display: flex;

  &__container {
    display: flex;
    flex-wrap: wrap;
  }

  &__route {
    flex-shrink: 0;
    margin-right: space(1);
    &--primary {
      margin-right: 0;
      font-weight: $font-weight-medium;
      color: color("primary");
    }
  }
}
