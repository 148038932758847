.reviews-widget {
  margin-top: space(2);
  &__container {
    display: flex;
    align-items: center;

    &__content {
      display: flex;
      align-items: center;
    }
  }

  &__google-logo {
    display: none;
    height: 20px;
    margin-bottom: 8px;

    img {
      height: 100%;
    }
  }

  &__star-list {
    display: flex;
    margin-right: space(2);
  }
  &__star-container {
    position: relative;
  }
  &__star {
    width: 16px;
    height: 16px;
    margin-right: 2px;
    &--active {
      position: absolute;
      top: 2px;
      left: -1px;
      object-fit: cover;
      object-position: left;

      @include bp-down(sm) {
        top: 0;
      }
    }
  }
  &__average-rating {
    margin-right: space(2);
    font-size: $font-size-sm;
    font-weight: $font-weight-medium;
  }
  &__divider {
    width: 2px;
    height: 2px;
    margin-right: space(2);
    background: $black;
    border-radius: 200px;
    opacity: 0.5;
  }

  &__total-reviews {
    font-size: $font-size-sm;
    color: $black;
    opacity: 0.75;

    &--cta {
      text-decoration: underline;
      cursor: pointer;
    }
  }

  &__modal {

    &__title {
      font-size: $h3-font-size;
      font-weight: $font-weight-medium;
    }
    &__address {
      margin-top: space(1);
      font-size: $font-size-sm;
    }
    &__average-rating {
      margin-right: space(3);
      font-size: $h3-font-size;
      font-weight: $font-weight-medium;
      opacity: 0.75;
    }
    &__star-row {
      display: flex;
      align-items: center;
      margin-top: space(4);
      .reviews-widget__star {
        top: 0;
        left: -2px;
        width: 32px;
        height: 32px;
      }
    }
    &__review-button {
      margin-top: 1rem;
    }
    &__loader {
      font-size: $h2-font-size;
      text-align: center;
    }
  }
  &__sort {
    margin-top: space(4);
    &__label {
      font-size: $font-size-sm;
      font-weight: $font-weight-medium;
    }
    &__row {
      display: flex;
      margin-top: space(2);
    }
    &__tag {
      display: flex;
      flex-shrink: 0;
      align-items: center;
      height: rem(30px);
      padding: 0 space(3);
      margin-right: space(2);
      font-size: $font-size-xs;
      font-weight: $font-weight-medium;
      color: color("primary");
      cursor: pointer;
      user-select: none;
      background: color("gray-bg");
      border: $border-width solid $border-color;
      border-radius: 15px;

      &--active {
        border-color: color("primary");
      }

      &:hover {
        border-color: color("primary");
      }
    }
  }

  &__list {
    max-height: 350px;
    padding-top: space(3);
    padding-right: space(3);
    margin-top: space(3);
    overflow: auto;
    border-top: $border-width solid $border-color;
  }

  &__review {
    display: flex;
    margin-bottom: space(4);
    &__image {
      flex-shrink: 0;
      width: 40px;
      height: 40px;
    }
    &__content {
      margin-left: space(2);
    }
    &__name {
      font-size: $font-size-sm;
      font-weight: $font-weight-medium;
    }
    &__rating-container {
      display: flex;
      align-items: center;
      margin-top: space(2);
      .reviews-widget__star {
        top: 3px;
        width: 12px;
        height: 12px;
      }
    }
    &__date {
      font-size: $font-size-xs;
      opacity: 0.75;
    }

    &__comment {
      margin-top: space(2);
      font-size: $font-size-xs;

    }
    &__read-more {
      font-weight: $font-weight-medium;
      color: color("primary");
      text-decoration: underline;
      cursor: pointer;
    }
  }

  &__load-more {
    margin-top: space(2);
    font-weight: $font-weight-medium;
    color: color("primary");
    text-decoration: underline;
    cursor: pointer;
  }
}

.reviews-widget-container {
  display: flex;
  flex: 1;
  align-items: end;
  justify-content: end;

  @include bp-down(sm) {
    align-items: center;
    justify-content: left;
  }

  .reviews-widget {
    @include bp-down(sm) {
      margin-top: 0;
      margin-bottom: 1rem;
    }
  }

  .reviews-widget__container {
    flex-direction: column;
    align-items: start;

    @include bp-down(sm) {
      flex-direction: row;
      align-items: center;
      justify-content: left;
    }

    .reviews-widget__star {
      @include bp-up(md) {
        top: 0;
        left: 0px;
        width: 32px;
        height: 29px;
      }
    }

    .reviews-widget__divider {
      background-color: $white;
    }

    .reviews-widget__total-reviews {
      color: $white;
    }
  }

  .reviews-widget__google-logo {
    display: block;

    @include bp-down(sm) {
      display: none;
    }
  }
}
