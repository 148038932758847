.checkout-header {
  @include border-bottom();
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  z-index: $zindex-site-header;
  height: $site-header-height-checkout;
  background: $white;

  @include bp-down(sm) {
    height: $site-header-height-checkout-sm;
    padding: rem(9px) 0;
  }

  > .container {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 100%;
  }

  &__logo {
    position: absolute;
    left: space(3);
    height: 34px;

    @include bp-down(sm) {
      display: none;
    }

    img {
      width: 179px;
      height: 34px;
    }

    @include bp-down(sm) {
      height: 32px;

      img {
        width: 168px;
        height: 32px;
      }
    }
  }

  &__action {
    position: absolute;
    right: space(3);
  }

  &__title {
    @include make-h2-sm();
    margin: 0 auto;

    @include bp-down(sm) {
      margin-left: 0;
    }
  }
}
