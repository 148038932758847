.logo-card {
  &__image {
    @include bg-image(0);
    height: rem(247px);
    border-top-left-radius: $border-radius;
    border-top-right-radius: $border-radius;

    @include bp-down(sm) {
      height: rem(162px);
    }
  }

  &__logo {
    position: absolute;
    top: - rem(34px);
    display: flex;
    align-items: center;
    justify-content: center;
    width: rem(68px);
    height: rem(68px);
    padding: space(2);
    background: $white;
    border-radius: 12px;
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.08);

    @include bp-down(sm) {
      top: - rem(30px);
      width: rem(60px);
      height: rem(60px);
    }
  }

  &__content {
    position: relative;
    padding: rem(50px) space(4) space(4);
    background: $white;
    border: $border-width solid $border-color;
    border-top: 0;
    border-bottom-right-radius: $border-radius;
    border-bottom-left-radius: $border-radius;

    @include bp-down(sm) {
      padding: rem(39px) space(3) space(3);
    }
  }
}
