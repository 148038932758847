.ecom-checkout,
.accessories-checkout {
  .site-header,
  .site-alerts {
    display: none;
  }
}

.checkout {
  padding: space(6) 0;
  background: color("gray-bg");

  @include bp-down(sm) {
    padding: 0;
  }

  &__container {
    max-width: rem(755px);
    margin: 0 auto;
  }

  &__select-vehicle {

    &__description {
      margin-bottom: space(3);
    }

    .input-group {
      max-width: none;
    }
  }

  &__selected-department {
    margin-top: space(4);
  }

  &__selected-department-delivery {
    display: flex;
    flex-direction: row;

    .delivery-status-circle {
      margin-right: 5px;
    }

    h4 {
      margin-bottom: 0;
    }

    p {
      font-size: $font-size-sm;
      color: color("muted");
    }
  }

  &__selected-department-map {
    overflow: hidden;
    border: $border-width solid $border-color;
    border-radius: $border-radius;

    .feedback-message {
      border: 0;
      border-top: $border-width solid $border-color;
      border-radius: 0;
    }
  }

  &__selected-department-map-image {
    position: relative;
    height: rem(140px);
    background-repeat: no-repeat;
    background-position: center center;

    img {
      display: block;
    }

    .btn {
      position: absolute;
      right: space(2);
      bottom: space(3);
    }
  }

  &__selected-department-tyre-hotel-order {
    margin-top: space(4);
  }

  &__payment-error {
    margin-bottom: space(4);

    small {
      font-size: $font-size-xs;
      color: $black;
    }

    @include bp-down(sm) {
      margin: space(4);
    }
  }

  .input-group {
    margin-bottom: space(3);

    .form-stack {
      @include bp-up(md) {
        max-width: calc(50% - #{space(2)});
      }
    }
  }

  &__payment-additional-details {
    display: flex;
    flex-direction: row;
    align-items: center;

    .fee-text {
      margin-left: 10px;
      font-size: 1rem;
      font-weight: 500;
      text-align: right;
    }

    img {
      width: auto;
      max-width: rem(60px);
      height: auto;
      max-height: rem(35px);
    }
  }

  &__why-ssn {
    display: block;
    margin: 0 0 space(4) 0;
    font-size: $font-size-sm;
  }

  &__accept-terms {
    width: 100%;
    padding: space(2) 0;
    margin-bottom: space(2);
    border-top: $border-width solid $border-color;
  }

  &__send-cart {
    float: right;
    font-size: $font-size-xs;

    i,
    svg {
      font-size: $font-size-sm;
    }
  }
}
