.employee-list {
  &--expanded {
    .employee-list__item:nth-child(n + 1) {
      display: flex;
    }
  }

  &__item {
    &:nth-child(n + 1) {
      display: none;
    }
  }

  &__phone-heading {
    margin-top: space(1);
  }

  &--horizontal {
    display: flex;
    flex-wrap: wrap;

    .employee-list__item {
      display: flex;
      margin-bottom: space(4);
    }

    @include bp-down(sm) {
      flex-direction: column;

      .employee-list__item:not(:last-child) {
        margin-bottom: space(4);
      }
    }
  }

  .link {
    font-weight: $font-weight-medium;
  }
}

.employee-channel-list {
  padding-top: space(6);
  padding-bottom: space(6);

  .vehicle-page & {
    padding-top: 0;
    padding-bottom: 0;
  }

  @include bp-down(md) {
    padding-top: 0;
  }

  &__heading {
    margin-bottom: space(3);
    font-size: $font-size-lg;
    font-weight: $font-weight-medium;

    @include bp-down(md) {
      font-size: $font-size-base;
    }
  }
  &__actions {
    display: flex;
    margin-top: space(4);

    @include bp-down(md) {
      flex-direction: column;
    }
  }

  &__tabs {
    display: flex;

    @include bp-down(md) {
      flex-direction: column;
    }
  }

  &__select {
    display: flex;
    align-items: center;
    justify-content: center;
    height: rem(42px);
    padding: 0 space(4);
    margin-right: rem(12px);
    font-size: $font-size-sm;
    font-weight: $font-weight-medium;
    cursor: pointer;
    user-select: none;
    background: color("white");
    border: $border-width solid $gray-400;
    border-radius: rem(6px);
    transition: all $transition-base;

    &--active {
      color: color("blue");
      background: color("blue-bg");
      border-color: color("blue");
    }

    @include bp-down(md) {
      flex: 1;
      padding: 0.5rem;
      margin-right: 0;
      margin-bottom: rem(12px);
    }
  }
  &__input {
    position: relative;
    display: flex;
    align-items: center;
    width: 100%;
    max-width: rem(365px);
    height: rem(42px);
    padding-left: rem(40px);
    margin-bottom: 0;
    font-size: $font-size-sm;
    background: color("white");
    border: $border-width solid $gray-400;
    border-radius: rem(6px);
    transition: all $transition-base;

    &:hover {
      border-color: $gray-900;
    }

    input {
      width: 100%;
      border: 0;
      outline: none;
    }

    svg {
      position: absolute;
      left: space(3);
    }

    @include bp-down(md) {
      max-width: none;
    }
  }
  &__quantity-text {
    margin-top: space(4);
    font-size: $font-size-sm;
    opacity: 0.75;

    @include bp-down(md) {
      margin-top: space(3);
      font-size: $font-size-xs;
    }
  }
  &__list {
    display: flex;
    flex-wrap: wrap;
    margin-top: space(4);
  }
  &__item {
    display: flex;
    width: calc(25% - 18px);
    padding: space(3);
    margin-right: space(4);
    margin-bottom: space(4);
    cursor: pointer;
    background-color: color("white");
    border: $border-width solid $gray-400;

    @include bp-down(md) {
      width: 100%;
      margin-right: 0;
      margin-bottom: space(2);
    }

    &:nth-child(4n) {
      margin-right: 0;
    }

    &--vehiclepage {
      width: calc(50% - 18px);

      &:nth-child(2n) {
        margin-right: 0;
      }
    }

    &__image {
      flex-shrink: 0;
      width: rem(56px);
      height: rem(56px);
      overflow: hidden;
      border: $border-width solid $gray-400;
      border-radius: 999999px;

      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }
    &__content {
      margin-left: rem(12px);
    }
    &__name {
      font-size: $font-size-sm;
      font-weight: $font-weight-medium;
      color: color("primary");
    }
    &__title {
      font-size: $font-size-sm;
      color: $gray-700;
    }

  }
  &__pagination-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;

    @include bp-down(md) {
      margin-top: space(3);
    }
  }
  &__pagination-text {
    margin-bottom: space(3);
    font-size: $font-size-sm;
    color: $gray-700;

    @include bp-down(md) {
      font-size: $font-size-xs;
    }
  }
  &__pagination-bar {
    position: relative;
    width: 100%;
    max-width: rem(270px);
    height: rem(3px);
    margin-bottom: space(3);
    background: $gray-500;
    border-radius: 200px;
    &__active {
      position: absolute;
      left: 0;
      width: 0%;
      height: 100%;
      background-color: color("primary");
      border-radius: 200px;
      transition: all $transition-base;
    }
  }

  &--block {
    padding-top: 0;
    padding-bottom: 0;

    .employee-channel-list__item {
      width: calc(33.33% - 16px);
    }

    .employee-channel-list__item:nth-child(4n) {
      margin-right: space(4);
    }

    .employee-channel-list__item:nth-child(3n) {
      margin-right: 0;
    }
  }
}
