@mixin promo-sm() {
  padding: space(4) 0;

  .promo__container {
    padding-right: space(4);
    padding-left: space(4);
  }

  .ribbon {
    left: space(3);
  }

  .sticker {
    right: space(3);
    width: rem(82px);
    height: rem(78px);
    &__text {
      font-size: rem(10px);
      p {
        font-size: rem(10px);
      }
    }
  }
}

@mixin promo-lg() {
  padding-top: rem(201px) + $site-header-height;

  @include bp-down(sm) {
    padding-top: rem(128px) + $site-header-height-sm;
  }

  .promo__meta {
    font-size: rem(16px);
  }

  .promo__header {
    @include make-h1();
  }
}

.promo {
  position: relative;
  z-index: 0;
  display: flex;
  align-items: flex-end;
  padding: space(6) 0;
  color: $white;

  .promo__container--no-content {
    height: 29vw;
  }

  @include bp-down(sm) {
    @include promo-sm();
    @include bg-image(0);
  }

  &--sm {
    @include promo-sm();
    @include bg-image(0);
  }

  &--lg {
    @include promo-lg();
  }

  &--service-booking {
    padding-top: $site-header-height;
    @include bp-down(sm) {
      padding-top: $site-header-height-sm;
    }
    @include bp-up(md) {
      padding-bottom: rem(56px);
    }
  }

  &--padding-sm {
    padding: space(4) 0;
  }

  &--bg-overlay {
    @include promo-bg-overlay();

    .list-label {
      color: $white;
    }
  }

  &--no-border-radius {
    border-radius: 0;

    &::before {
      border-radius: 0;
    }

    img {
      border-radius: 0;
    }
  }

  .btn--outline {
    color: $white;
  }

  &__standard-btn {
    margin-right: space(2);
  }

  &__outline-btn {
    &:hover {
      color: color("black");
      background-color: $white;
      border-color: $white;
    }
  }

  @include bg-image();

  &--box {
    @include bp-down(sm) {
      &::before {
        position: absolute;
        top: 100%;
        left: 0;
        z-index: -1;
        width: 100%;
        height: rem(200px);
        content: "";
        background: linear-gradient(180deg, rgba(206, 206, 216, 1) 0%, rgba(255, 255, 255, 1) 100%);
      }
      margin-bottom: rem(200px);
      .promo__container {
        justify-content: center;
        padding-right: space(3);
        padding-left: space(3);
      }
    }
  }

  .btn + small {
    position: relative;
    top: 3px;
    margin-left: space(4);

    @include bp-down(sm) {
      top: auto;
      display: block;
      margin-top: space(3);
      margin-left: 0;
    }
  }

  &__logo {
    display: flex;
    align-items: center;
    justify-content: center;
    width: rem(60px);
    height: rem(60px);
    margin-bottom: space(4);
    background: color("white");
    border-radius: $border-radius;
  }

  &__meta {
    margin-bottom: space(1);
    font-size: $font-size-xs;
    font-weight: $font-weight-medium;
    color: color("white");
    text-transform: uppercase;
    letter-spacing: 0.5px;
  }

  &__header {
    @include make-h2-sm();

    &--sm {
      @include make-h3();
    }

    &--xs {
      @include make-h4();
    }

    &--red {
      color: color("red");
    }

    &--yellow {
      color: color("yellow");
    }

    &--green {
      color: color("green");
    }

    &--blue {
      color: color("blue");
    }
  }

  &__text {
    margin-bottom: space(3);
  }

  &__container {
    @extend .container;
    position: relative;
    z-index: 2;
    display: flex;

    &--sm {
      max-width: rem(756px);
      padding-right: 0;
      padding-left: 0;
    }

    &--md {
      max-width: rem(832px);
      padding-right: 0;
      padding-left: 0;

      @include bp-down(sm) {
        display: block;
      }
    }
  }

  &__content {
    position: relative;
    width: 100%;
    max-width: rem(576px);
    margin-top: rem(84px);

    &--lg {
      max-width: rem(600px);
    }

    & .btn:not(.regnr-input-sibling) {
      margin-top: rem(8px);
    }

    &--box {
      &.promo__content--brand-image {
        max-width: rem(700px);
        padding: space(8) space(4) space(4);
        @include bp-up(md) {
          margin-top: rem(100px);
        }

        .promo__header {
          font-size: $h3-font-size;
        }
        .promo__meta {
          font-size: $font-size-sm;
        }
      }

      max-width: rem(490px);
      padding: space(6);
      margin-top: rem(14px);
      margin-bottom: rem(14px);
      color: $body-color;
      background: $white;
      border-radius: $border-radius;
      .promo__meta,
      .price-tag.price-tag--obsolete {
        color: color("muted-light");
      }

      .btn--outline {
        color: color("muted");
        border-color: color("muted");
      }

      @include bp-down(sm) {
        padding-right: space(4);
        padding-left: space(4);
        margin-top: -(rem(100px));
        transform: translateY(220px);
      }
    }
  }

  .sticker {
    position: absolute;
    top: space(3);
    right: space(6);
    z-index: 2;
  }

  .ribbon {
    position: absolute;
    top: -$ribbon-arrow-right-size;
    left: space(6);
    z-index: 3;
  }

  .price-tag--obsolete {
    margin-right: space(1);
    font-size: $font-size-sm;
    color: color("white");
  }

  .price-tag--discount {
    font-size: rem(22px);
  }

  &--transparent-top {
    &,
    &::before {
      border-radius: 0;
    }

    &:not(.promo--lg) {
      padding-top: $site-header-height;
      @include bp-down(sm) {
        padding-top: $site-header-height-sm;
      }
    }
  }

  &--justify-end {
    .sticker {
      right: auto;
      left: space(6);
    }
    justify-content: flex-end;
    .promo__container {
      justify-content: flex-end;
    }
  }

  &--large-heading {
    .promo__content {
      margin-top: -3.75rem;
      margin-bottom: 4rem;
    }

    .promo__header {
      font-size: 6.5rem;

      @include bp-down(sm) {
        font-size: 3rem;
      }
    }
  }
}

// BLOCK SPECIFIC
.col-md-6:not(.col-lg-4) > .promo {
  @include bp-up(md) {
    height: rem(620px);
  }
}
.col-md-6 > .promo {
  margin-bottom: space(5);
  @include bp-up(md) {
    height: rem(300px);
  }
}

.col-md-6 > .promo:not(.promo--bg-overlay) {
  @include bp-down(sm) {
    margin-bottom: rem(200px);
  }
}
