.i3-filter {
  // fade transition
  .fade-enter-active,
  .fade-leave-active {
    transition: opacity $transition-base;
  }

  .fade-enter,
  .fade-leave-to {
    opacity: 0;
  }

  // slide/slide-back transition
  .slide-enter-active {
    transition: transform $transition-bezier, opacity $transition-base;
  }

  .slide-leave-active {
    transition: transform $transition-bezier, opacity $transition-base;
  }

  .slide-enter,
  .slide-leave-to {
    opacity: 0;

    svg {
      opacity: 0;
    }
  }

  .slide-enter {
    transform: translateX(20%);
  }

  .slide-leave-to {
    transform: translateX(-20%);
  }

  .slide-back-enter-active {
    transition: transform $transition-bezier, opacity $transition-base;
  }

  .slide-back-leave-active {
    transition: transform $transition-bezier, opacity $transition-base;
  }

  .slide-back-enter,
  .slide-back-leave-to {
    opacity: 0;

    svg {
      opacity: 0;
    }
  }

  .slide-back-enter {
    transform: translateX(-20%);
  }

  .slide-back-leave-to {
    transform: translateX(20%);
  }

  .slide-leave-active,
  .slide-leave-to,
  .slide-back-leave-active,
  .slide-back-leave-to {
    position: absolute;
    top: 0;
    width: 100%;
  }
}
