.skeleton-loader {
  animation: skeletonAnimation 1s infinite;
}
@keyframes skeletonAnimation {
  0% {
    opacity: 0.6;
  }
  50% {
    opacity: 0.25;
  }
  100% {
    opacity: 0.6;
  }
}
