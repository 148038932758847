// stylelint-disable selector-no-qualifying-type
.vehicle-list {
  padding: space(5) 0;
  background: color("gray-bg");

  @include bp-down(sm) {
    padding: space(3) 0 space(5);
  }

  &__header {
    display: flex;
    align-items: center;
    margin-bottom: space(4);

    &__title {
      margin-right: space(2);
      font-size: $font-size-xs;
      font-weight: $font-weight-medium;
      color: color("muted");
      text-transform: uppercase;
    }

    &__store-selector {
      display: flex;
      align-items: center;
      margin-left: auto;
      cursor: pointer;

      @include bp-down(sm) {
        display: block;
      }
      &__input {
        display: flex;
        align-items: center;

        .vehicle-list-option {
          margin-right: space(2);
          cursor: pointer;
        }

        &:not(:last-child) {
          @include bp-down(sm) {
            margin-bottom: space(1);
          }
          margin-right: space(3);
        }
        &__text {
          font-size: $font-size-xs;
          font-weight: $font-weight-medium;
          &--muted {
            margin-left: space(1);
            font-size: $font-size-xs;
            font-weight: $font-weight-medium;
            color: color("muted");
          }
        }
      }
    }
  }

  &__items {
    @include make-row();
  }

  &__item {
    @include make-col-ready();
    @include make-col(3);
    display: flex;
    margin-bottom: space(3);

    @include bp-down(lg) {
      @include make-col(4);
    }

    @include bp-down(md) {
      @include make-col(4);
    }

    @include bp-down(sm) {
      @include make-col(12);
    }
  }

  &__full-item {
    @include make-col-ready();
    padding-top: space(5);
    padding-bottom: space(5);

    @include bp-only(lg) {
      @include make-col(12);
    }

    @include bp-only(md) {
      @include make-col(10);
      @include make-col-offset(1);
    }

    @include bp-down(sm) {
      @include make-col(12);
      width: calc(100% + #{$grid-gutter-width-sm});
      max-width: none;
      padding-right: 0;
      padding-left: 0;
      margin-right: -($grid-gutter-width-sm / 2);
      margin-left: -($grid-gutter-width-sm / 2);
    }
  }

  &__full-item-wrapper {
    width: 100%;
  }
}

.i3-search-watch {
  background-color: color("gray-bg");

  .search-watch {
    padding-top: 2.5rem;
    padding-bottom: 5rem;
    margin: 0 auto;
  }
}
