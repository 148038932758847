.ecom-packages-block {
  @include bg-contain(100% 0);
  position: relative;
  padding: rem(80px) 0;

  &__header {
    margin-bottom: rem(80px);
    text-align: center;

    h2 {
      margin-bottom: 0;
    }
  }

  &__header-meta {
    margin-bottom: space(2);
    font-size: $font-size-xs;
    font-weight: $font-weight-medium;
    text-transform: uppercase;
  }

  &__packages {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  .eon-package-spot {
    flex: 0 0 auto;
    width: calc(33.333% - #{space(4)});
  }
}
