.info-block {
  position: relative;
  margin-left: rem(28px);
  text-align: left;
  @include bp-down(sm) {
    margin-left: rem(14px);
  }

  &:not(:last-child) {
    margin-bottom: space(4);
    @include bp-down(sm) {
      margin-bottom: space(3);
    }
  }

  .link {
    display: block;
    margin-top: space(1);
    font-size: $font-size-sm;
    font-weight: $font-weight-medium;
  }

  &__icon {
    position: absolute;
    top: 1px;
    left: - rem(28px);
    font-size: rem(18px);
    color: color("primary");
    @include bp-down(sm) {
      top: 5px;
      left: - rem(22px);
      font-size: rem(14px);
    }
  }

  &__header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: space(1);
    font-weight: $font-weight-medium;

    &--alt {
      font-size: $font-size-lg;
      font-weight: $font-weight-medium;
    }
    &--large {
      font-size: $h3-font-size;
      font-weight: $font-weight-medium;
      @include bp-down(sm) {
        font-size: $font-size-lg;
      }
    }
  }

  &__meta {
    font-size: $font-size-sm;
    color: color("muted");
  }

  &--justify-left {
    margin-left: 0;
  }

  &--justify-left-sm {
    @include bp-down(sm) {
      margin-left: 0;
      .info-block__icon {
        display: none;
      }
    }
  }

  &--hours {
    max-width: rem(350px);

    .fa-angle-up {
      display: none;
    }

    &__row {
      display: flex;
      justify-content: space-between;

      &:nth-child(n + 3) {
        display: none;
      }

      &:not(:last-child) {
        margin-bottom: space(1);
      }

      &--active {
        font-weight: $font-weight-medium;
      }
    }

    &--expanded {
      .info-block--hours__row:nth-child(n + 2) {
        display: flex;
      }

      .fa-angle-up {
        display: inline-block;
      }

      .fa-angle-down {
        display: none;
      }
    }
  }
}
