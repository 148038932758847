.blog-page {
  &__hero-container {
    @include bp-down(md) {
      padding: 0;
    }
  }

  &__hero {
    width: 100%;
    height: rem(390px);
    margin-bottom: space(8);
    @include bg-image(0);

    @include bp-down(md) {
      height: rem(200px);
      margin-bottom: space(4);
    }
  }

  &__title {
    margin-bottom: space(3);

    @include bp-down(md) {
      font-size: $h3-font-size;
    }
  }

  &__header {
    display: flex;
    flex-direction: row;
    align-items: baseline;
    justify-content: space-between;
    margin-bottom: space(7);

    @include bp-down(md) {
      margin-bottom: space(6);
    }
  }

  &__intro {
    margin-bottom: space(5);
    font-size: $font-size-lg;

    @include bp-down(md) {
      margin-bottom: space(4);
      font-size: $font-size-base;
    }
  }
  &__category-listing {
    display: flex;
  }

  &__footer {
    margin-bottom: space(6);
  }

  .employee-page__social-media {
    justify-content: start;
    margin-bottom: space(5);

    @include bp-down(md) {
      margin-bottom: space(4);
    }
  }

  .employee-page__social-media__btn:not(:last-child) {
    margin-right: space(3);
  }

  &__date {
    font-size: $font-size-sm;
    color: color("muted");
  }

  &__content {
    padding-bottom: space(6);

    @include bp-down(md) {
      padding-bottom: space(4);
    }
  }

  .link-action-block {
    display: flex;
    padding: space(3) space(3) space(3) 0;
    margin: space(3) 0;
    border-top: 4px solid color("primary");
    border-bottom: $border-width solid $border-color;

    @include bp-down(md) {
      margin: space(6) 0;
    }

    &__image-container {
      flex-shrink: 0;
      height: rem(88px);
      margin-right: space(3);
      img {
        height: 100%;
      }

      @include bp-down(md) {
        height: rem(64px);
      }
    }
    &__content {
      display: flex;
      flex-direction: column;
      justify-content: center;
    }
    &__title {
      margin-bottom: space(2);
      font-size: $font-size-xs;
      font-weight: $font-weight-medium;
      text-transform: uppercase;

      @include bp-down(md) {
        font-size: $font-size-xxs;
      }
    }
    &__link {
      font-size: $font-size-lg;
      font-weight: $font-weight-medium;
      color: color("primary");

      @include bp-down(md) {
        font-size: $font-size-sm;
      }
    }
    &__icon {
      font-size: 88px;
      color: color("primary");

      @include bp-down(md) {
        font-size: 64px;
      }
    }
  }

  .similar-blogs {
    padding-top: rem(80px);
    padding-bottom: rem(80px);
    border-top: $border-width solid $border-color;

    @include bp-down(md) {
      padding-top: space(8);
      padding-bottom: space(8);
    }

    &__heading {
      margin-bottom: space(4);
      @include bp-down(md) {
        display: flex;
        justify-content: space-between;
      }
    }
    &__heading-link {
      margin-left: 12px;
      font-size: $font-size-sm;
      font-weight: $font-weight-medium;
    }
    &__heading-icon {
      margin-left: space(1);
      font-size: $font-size-xxs;
      color: color("primary");
    }
    &__item-container {
      display: flex;
      justify-content: space-between;

      .swiper-container {
        width: 100%;
        margin-right: 0;
        margin-left: 0;
        overflow: visible;
      }
    }
    &__item {
      display: flex;
      flex-direction: column;
      width: calc(32% - 16px);
      height: 100%;
      min-height: rem(380px);
      overflow: hidden;
      border: $border-width solid $border-color;
      border-radius: $border-radius;

      @include bp-down(md) {
        width: auto;
        max-width: rem(300px);
        height: auto;
        min-height: auto;
        max-height: rem(300px);
      }

      &__image {
        width: 100%;
        height: 50%;

        img {
          object-fit: cover;
          width: 100%;
          height: 100%;
        }
      }

      &__content {
        display: flex;
        flex: 1;
        flex-direction: column;
        justify-content: space-between;
        padding: space(5) space(4) space(4);
        background-color: $white;

        @include bp-down(md) {
          padding-top: space(4);
        }
      }

      &__intro {
        margin-bottom: auto;
        font-size: $font-size-sm;
        color: color("muted");

        @include bp-down(md) {
          display: none;
        }
      }

      .blog-page__category-listing {
        margin-bottom: 0;
      }
    }
  }
}
