.wizard {
  position: relative;
  max-width: rem(832px);
  padding-bottom: space(1);
  margin: 0 auto;

  &--auto-top {
    top: 0;
  }

  &--service-top {
    top: space(4);
  }

  .modal & {
    top: 0;
    padding-top: space(8);

    @include bp-down(sm) {
      padding-top: space(3);
    }
  }

  .form-step .feedback-message {
    margin-top: space(3);
  }

  &__submit {
    .form-check {
      margin-bottom: space(3);

      &__label {
        font-size: $font-size-sm;
        cursor: default;
      }
    }
  }

  @include bp-down(sm) {
    position: static;

    .hero__header,
    .hero__text {
      margin: 0 1rem;
    }

    > .container {
      padding-right: 0;
      padding-left: 0;
    }

    &__submit {
      padding: 0 space(3);
    }

    .feedback-message {
      border-right: 0;
      border-left: 0;
      border-radius: 0;
    }
  }

  .field-input-images {
    margin-top: space(3);

    label {
      margin-bottom: space(1);
    }

    &__grid {
      display: flex;
      flex-wrap: wrap;
      margin: 0 - space(2);
    }

    &__grid-item {
      .file-placeholder,
      .file-thumbnail {
        margin: rem(12px) space(2);
      }

      @include bp-down(sm) {
        width: 33.333%;

        .file-placeholder,
        .file-thumbnail {
          width: auto;
        }
      }
    }
  }

  .field-input-box {
    padding: space(3);
    margin-bottom: space(4);
    background: color("gray-bg");
    border: $border-width dashed $border-color;
    border-radius: $border-radius;
  }

  &--service-agreement__addons {
    .form-row__col--md-50 {
      width: 100%;
      margin: 0;
    }
  }

  &--trade-in {
    .trade-in-valuation {
      &__col {
        &--w-25 {
          width: calc(25% - 8px);
          .input-group {
            max-width: none;
          }

          @include bp-down(sm) {
            width: calc(50% - 6px);
            margin-bottom: space(1);
          }
        }
        &--w-50 {
          width: calc(50% - 8px);

          @include bp-down(sm) {
            width: 100%;
          }
        }
      }
      @include bp-up(md) {
        &__col:not(:last-child) {
          margin-right: rem(12px);
        }
      }

      @include bp-down(sm) {
        &__col:first-child {
          margin-right: rem(12px);
        }

        .regnr-input {
          width: 100%;
        }
      }

      &__condition {
        margin-top: space(4);
      }
    }

    .valuation-confirmation-container {
      padding-top: space(4);
    }

    .list-styled {
      margin-bottom: space(3);
      li {
        color: color("muted");
      }
    }

    .vehicle-history-forms__request-info__result {
      padding: space(3);
    }

    .lookup-form {
      display: flex;
      flex-direction: row;
      @include bp-down(md) {
        flex-direction: column;
      }

      .form-row {
        @include bp-down(md) {
          flex-wrap: nowrap;
        }

        &__col {
          flex-grow: 1;
          @include bp-down(md) {
            width: auto;
          }
        }
      }
      .regnr-input {
        margin-right: space(2);

        @include bp-down(md) {
          margin-bottom: space(3);
        }
      }

      .regnr-input-sibling {
        @include bp-down(md) {
          width: 100%;
          margin-left: 0;
        }
      }
    }

  }

  &--damage-report {
    .section__content {
      padding: space(3);
      margin-bottom: space(3);

      > img {
        max-width: rem(100px);
        max-height: rem(100px);
      }
    }

    .option-box {
      position: relative;

      &__image {
        position: absolute;
        top: calc(50% - 5px);
        right: space(2);
        max-width: rem(60px);
        max-height: rem(60px);
        margin-bottom: 0;
      }
    }
  }

  &--service-booking {
    &__loader {
      font-size: 40px;
    }

    .section__content {
      padding: space(3);
      margin-bottom: space(3);

      > img {
        max-width: rem(100px);
        max-height: rem(100px);
      }
    }

    .service-agreement-option {
      .option-box {
        background-color: color("gray-bg");

        .main-info {
          border-top: 0;
        }
      }
    }

    .option-box {
      position: relative;
      display: flex;
      flex-flow: row wrap;
      align-items: center;
      justify-content: space-between;

      &:not(.option-box--large-image) {
        .option-box__image {
          max-width: rem(76px);
          margin: 0;
          line-height: 0;

          img {
            max-height: rem(44px);
          }

          @include bp-down(sm) {
            top: rem(22px);
          }
        }
      }

      &__header {
        flex-basis: 80%;
        flex-grow: 1;

        @include bp-down(sm) {
          flex-basis: 70%;
        }
      }

      &__content {
        flex-basis: 100%;
        margin-left: rem(35px);
      }

      &__actions {
        display: flex;
        align-items: center;
        justify-content: flex-end;
        width: 100%;
      }
    }

    &__select-service-submit {
      margin-bottom: 12px;
    }

    .service-addons {
      margin-right: - space(3);
      margin-left: - space(6);

      .option-box {
        background-color: color("white");
      }

      .main-info {
        width: 100%;
        padding-right: space(3);
        padding-left: space(6);
      }

      .configurator-section__completed__sub-action {
        width: 100%;
        padding-right: space(3);
        padding-left: space(6);
      }
    }

    &__show-more-link {
      margin-bottom: space(3);
      font-weight: $font-weight-medium;
    }
  }

  @include bp-up(md) {
    .form-row__col:not(.form-row__col--md-50) + .form-row__col--large-image {
      margin-left: 0;
      & + .form-row__col--large-image {
        margin-left: space(3);
      }
    }
    .form-row__col--large-image {
      & + .form-row__col--large-image {
        margin-left: space(3);
        &:nth-of-type(2n + 0) {
          margin-left: 0;
        }
      }
    }
  }
}

.service-booking-wizard {
  padding: 3rem 0 1rem;

  @include bp-down(sm) {
    padding: 4rem 0 1rem;
  }

  .u-c-muted.u-align-center {
    margin-top: -2rem;
    font-size: $font-size-xs;

    @include bp-down(sm) {
      margin-top: -1.5rem;
    }
  }
}

.form-row__col--md-50 > div:not(.date-wizard__label-container) {
  height: 100%;
  .option-box--large-image {
    height: 100%;
  }
}

.group-row {
  display: flex;
  flex-wrap: wrap;
  width: 100%;
}

.mileage-notice-text {
  margin-top: space(2);
  font-size: $font-size-sm;
  color: color("danger");
}
