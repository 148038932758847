.meta-tag {
  display: inline-block;
  padding: font-padding(rem(1px), rem(4px));
  margin-left: space(1);
  font-size: $font-size-xs;
  font-weight: $font-weight-medium;
  color: color("primary");
  background: rgb(228, 235, 238);

  &.new {
    color: $white;
    background: color("blue");
  }

  &.black {
    color: color("white");
    background: color("black");
  }

  &.rounded {
    border-radius: $border-radius;
  }
}
