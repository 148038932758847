.product-images-area {
  .swiper-container {
    display: none;
  }

  @include bp-down(sm) {
    .gallery-grid {
      display: none;
    }

    .swiper-container {
      display: block;
    }
  }
}
