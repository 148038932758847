.tyre-text {
  height: rem(106px);
  padding-top: 1.9rem;
  font-size: rem(22px);
  font-weight: 500;
  background-image: url("/Static/images/hb-shop_tire-dimension.png");
  background-repeat: no-repeat;
  background-position-x: center;
  background-size: 400px 132px;

  span {
    color: color("muted-light");

    &:nth-child(1),
    &:nth-child(2),
    &:nth-child(3),
    &:nth-child(5),
    &:nth-child(6),
    &:nth-child(9),
    &:nth-child(10) {
      color: $white;
    }
  }
}
