// stylelint-disable selector-no-qualifying-type
body:not(.scrolled) {
  .vehicle-listing-hero__top-filter {
    transform: translateY($site-header-height-sm - rem(55px));
  }
  &.has-site-alerts .vehicle-listing-hero__top-filter {
    transform: translateY($site-header-height-sm + $site-alerts-height - rem(55px));

    @include bp-down(md) {
      transform: translateY($site-header-height-sm + $site-alerts-height - rem(50px));
    }
  }
  &.has-large-site-alerts .vehicle-listing-hero__top-filter {
    transform: translateY($site-header-height-sm + $site-alerts-large-height - rem(55px));

    @include bp-down(md) {
      transform: translateY($site-header-height-sm + $site-alerts-large-height - rem(50px));
    }
  }
}
.vehicle-listing-hero {
  @include bp-down(sm) {
    padding-top: rem(56px);
  }

  @include bp-down(xs) {
    padding-top: rem(51px);
  }
  &__top-filter {
    position: absolute;
    body.scrolled--up & {
      position: fixed;
    }
    top: rem(55px);
    right: 0;
    left: 0;
    z-index: $zindex-site-header - 1;
    height: rem(56px);
    background: color("white");
    box-shadow: 0px 1px 2px #0000000d;
    transform: translateY($site-header-height-sm - rem(55px));

    @include bp-up(md) {
      display: none;
    }

    body.scrolled & {
      border-top: $border-width solid $border-color;
      transition: transform $transition-base, background $transition-base;
      transform: translateY(0);
    }

    // body:not(.scrolled--up) & {
    //   transform: translateY(- rem(55px));
    // }

    .container {
      height: 100%;
      padding-right: 0;
    }

    .filter-bar {
      height: 100%;

      &__tags {
        width: rem(250px);
        max-width: none;
      }
    }
  }

  &__overlay {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background: color("black");
    opacity: 0.5;
  }

  .page-header {
    position: relative;
    background-position: center;

    &__content {
      display: flex;

      h2 {
        @include make-h2-sm();
      }

      @include bp-down(sm) {
        //padding: rem(24px) 0;

        p {
          margin-bottom: space(3);
        }
      }
    }

    &__description {
      z-index: 1;
      display: flex;
      flex-direction: column;
    }

    &--image-bg {
      padding: rem(80px) rem(40px);

      @include bp-down(sm) {
        padding: rem(40px) rem(16px);
      }

      .page-header__content {
        color: color("white");

        a,
        h2,
        h3 {
          color: color("white");
        }
      }
    }
  }
  .page-nav {
    border-bottom: none;
  }
}

.link-toggler--active .shaded-expand__content {
  margin-top: 1rem;
}
