.eon-product-title {
  &__line {
    &:not(:last-child) {
      margin-bottom: space(2);
    }
  }

  &__text {
    font-size: $h4-font-size;
    font-weight: $font-weight-medium;
    line-height: $h4-line-height;
  }

  &__meta {
    font-size: $font-size-sm;
    font-weight: $font-weight-regular;
    color: color("muted");
  }

  &--lg {
    .eon-product-title__text {
      @include make-h3();
      margin-bottom: space(1);
      font-weight: $font-weight-medium;
    }
  }
}
