.accessories-product-spot {
  position: relative;
  display: flex;
  flex-direction: column;
  width: calc(25% - 18px);
  min-height: 200px;
  padding: space(4);
  margin-bottom: space(4);
  background: $white;
  border: $border-width solid transparent;
  border-color: $border-color;
  border-radius: $border-radius;
  box-shadow: $box-shadow-border-bottom;

  &:not(:nth-child(4n)) {
    margin-right: space(4);
  }

  .eon-product-buy-actions__row {
    .form-stack {
      width: 50%;
    }
    .btn {
      width: 50%;
    }
  }

  @include bp-down(md) {
    width: calc(50% - 12px);
    &:not(:nth-child(4n)) {
      margin-right: 0;
    }


    &:not(:nth-child(2n)) {
      margin-right: space(4);
    }
  }

  @include bp-down(sm) {
    width: 100%;
    &:not(:nth-child(4n)) {
      margin-right: 0;
    }
  }

  &__desc {
    display: flex;
  }

  &__info {
    flex-direction: column;
    width: 100%;
    margin-right: space(2);
  }

  &__image {
    max-width: 100%;
  }

  &__meta {
    font-size: $font-size-xs;
    color: color("muted");
    //text-transform: uppercase;

    .mark {
      position: relative;
      top: -1px;
      margin-right: rem(2px);
    }
  }

  &__buy {
    margin-top: auto;
  }

  .eon-product-title {
    margin-top: space(2);
  }

  .feedback-message {
    margin-top: rem(12px);
  }

  &__price {
    margin-top: space(3);

    .price-tag {
      font-size: $h4-font-size;
      font-weight: $font-weight-medium;
    }

    .price-tag--obsolete {
      font-weight: $font-weight-regular;
    }

    &__meta {
      margin-top: rem(2px);
      margin-bottom: space(3);
      font-size: $font-size-xs;
      color: color("muted");
    }
  }
}
