// stylelint-disable declaration-no-important
.eon-product-slider {
  @include bp-down(sm) {
    margin-right: - $grid-gutter-width-sm;
  }

  @include bp-up(md) {
    .swiper-container {
      overflow: visible;
    }
  }

  .swiper-container {
    clear: both;
  }


  .swiper-slide {
    @include bp-down(sm) {
      width: calc(100% - 40px) !important;
    }
  }
}
