.lookup-result {
  display: flex;
  font-size: $font-size-sm;

  label {
    margin-bottom: space(1);
    font-size: rem(9px);
  }

  &__item {
    &:not(:last-child) {
      margin-right: space(5);

      @include bp-down(xs) {
        margin-right: space(4);
      }
    }
  }
}
