.tall-card-slider {
  //margin-top: space(5);
  //margin-bottom: space(5);

  @include bp-down("sm") {
    //margin-top: space(3);
  }

  .swiper-wrapper {
    // stylelint-disable declaration-no-important
    transition-timing-function: linear !important;
  }
}
