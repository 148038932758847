.configurator-teaser {
  padding-top: rem(50px);
  padding-bottom: space(6);

  @include bp-down(sm) {
    padding-top: space(5);
    padding-bottom: space(4);
  }

  &__colors {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;

    label {
      width: 100%;
      margin: space(2) 0;
      text-align: center;
    }
  }

  &__preview {
    text-align: center;
  }

  .ribbon {
    position: absolute;
    top: - $ribbon-arrow-right-size;
    left: space(6);

    @include bp-down(sm) {
      left: space(3);
    }
  }

  .list-styled {
    margin-bottom: space(4);
  }

  .btn:not(.btn--block) {
    @include bp-down(sm) {
      display: none;
    }
  }

  .btn--block {
    display: none;
    margin-top: space(2);

    @include bp-down(sm) {
      display: block;
    }
  }
}
