.reviews-block {
  &__list {
    @include bp-down(md) {
      display: block;
    }
  }

  &__swiper {
    overflow: visible;
  }

  &__card {
    //width: calc(25% - 18px);
    min-height: rem(174px);
    padding: space(4);
    background-color: color("white");
    border: $border-width solid $gray-400;
    border-radius: 6px;
    box-shadow: 0px 0.5px 0px #00000029;

    @include bp-down(md) {
      width: 80%;
    }

    .reviews-widget {
      .reviews-widget__google-logo {
        margin-bottom: space(3);
      }

      .reviews-widget__star-container {
        margin-bottom: space(2);
      }

      .reviews-widget__star {
        top: 0;
        width: rem(24px);
        height: rem(24px);
      }
    }

    .reviews-widget__review__rating-container {
      margin-top: 0;
      margin-bottom: space(2);
      .reviews-widget__star {
        top: 6px;

        @include bp-down(sm) {
          top: 3px;
        }
      }
    }

    &__comment {
      margin-bottom: space(2);
      font-size: $font-size-sm;
    }

    &__icon {
      font-size: $font-size-sm;
      color: color("success");
    }

    &__name {
      font-size: $font-size-xs;
      font-weight: $font-weight-medium;
    }

    .reviews-widget__container {
      flex-direction: column;
      align-items: start;

      .reviews-widget__google-logo {
        display: block;
      }
    }
  }
}
