.transparent-top {
  padding-top: 0;

  &.has-site-alerts {
    padding-top: $site-alerts-height;
  }

  &.has-large-site-alerts {
    padding-top: $site-alerts-large-height;
  }

  &:not(.scrolled--up) {
    .site-header:not(.site-header--nav-overlay-open) {
      @extend .site-header--transparent;
    }
  }

  &.scrolled--down {
    .site-header:not(.site-header--nav-overlay-open) {
      @extend .site-header--transparent-scrolled;
    }
  }

  &:not(.scrolled) {
    .site-header {
      transition-delay: 0.22s;

      &--nav-overlay-open {
        transition: none;
      }
    }
  }
}
