// stylelint-disable selector-no-qualifying-type
body:not(.scrolled) {
  &.has-site-alerts .site-header {
    top: $site-alerts-height;
  }

  &.has-large-site-alerts .site-header {
    top: $site-alerts-large-height;
  }

  &.transparent-top .site-header {
    top: auto;
  }
}

.site-header {
  @include border-bottom();
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  z-index: $zindex-site-header;
  background: $white;
  transition: transform $transition-base, background $transition-base;

  body.employee-card-page & {
    display: none;
  }

  @include bp-down(sm) {
    //padding: 0 0 rem(18px);
  }

  > .container,
  > .site-header__meta .container {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  &__search {
    position: relative;
    display: flex;
    flex: 1 0 auto;
    flex-direction: column;
    margin: space(3) space(6);

    @include bp-down(sm) {
      display: none;
    }

    &__input {
      z-index: $zindex-site-header + 2;
      display: flex;
      width: 100%;
      background: color("gray-bg");
      border: $border-width solid $border-color;
      border-radius: $border-radius;

      &--open {
        border-radius: $border-radius $border-radius 0 0;
      }

      .search-bar {
        flex: 1 0 auto;
        border: 0;
      }

      .form-control:focus {
        background: color("gray-bg");
      }

      input {
        flex: 1 0 auto;
        height: auto;
        padding-top: rem(12px);
        padding-bottom: rem(12px);
        background: color("gray-bg");
        border: 0;
      }

      input:focus {
        outline-width: 0;
      }
    }

    &__icon {
      margin-right: space(2);
      font-size: $font-size-lg;
      color: color("primary");
    }

    .search-content {
      position: absolute;
      top: rem(49px);
      right: 0;
      left: 0;
      z-index: $zindex-site-header + 1;
      background: color("white");
      border: $border-width solid $border-color;
      border-radius: 0 0 $border-radius $border-radius;

      &__results {
        display: flex;
        padding: space(4) 0;
        border-bottom: $border-width solid $border-color;

        &__heading {
          margin-bottom: 12px;
          font-size: $font-size-xxs;
          font-weight: $font-weight-bold;
          text-transform: uppercase;
        }

        &__section {
          &:not(:first-child) {
            margin-top: space(4);
          }
        }

        &__column {
          height: 100%;
          max-height: 506px;
          padding: 0 space(4);
          &:not(:last-child) {
            border-right: $border-width solid $border-color;
          }

          &:not(:only-child) {
            width: 50%;
          }

          &__product-spot {
            width: calc(50% - 8px);
            margin-bottom: space(3);
          }

          &__product-spot:nth-child(2n) {
            margin-left: space(3);
          }

          &__search-spot {
            margin-bottom: space(2);
            font-size: $font-size-sm;
            font-weight: $font-weight-medium;
            color: color("primary");
          }
          &__empty {
            font-size: $font-size-sm;
            font-weight: $font-weight-medium;
          }

          &--scrollable {
            overflow: auto;
          }
        }

        &__list {
          display: flex;
          flex-wrap: wrap;
        }

        &__popular {
          padding: space(4);
          &__list {
            display: flex;
            flex-wrap: wrap;
          }

          &__item {
            &:not(:last-child) {
              margin-right: rem(12px);
            }
            margin-bottom: space(2);
            font-size: $font-size-sm;
            font-weight: $font-weight-medium;
            color: color("primary");
            cursor: pointer;
          }

          &__empty {
            padding-top: space(3);
            font-weight: $font-weight-medium;
            border-top: $border-width solid $border-color;
          }
        }
      }

      &__btn {
        display: flex;
        align-items: center;
        justify-content: center;
      }

      &__empty {
        display: flex;
        align-items: center;
        justify-content: center;
        padding: space(4);
        font-weight: $font-weight-medium;
      }

      &__loading {
        display: flex;
        align-items: center;
        justify-content: center;
        padding: space(4);
        &__spinner {
          font-size: $font-size-lg;
        }
      }
    }

    .search-field-enter-active {
      transition: all 0.2s ease;
    }
    .search-field-leave-active {
      transition: all 0.2s cubic-bezier(1, 0.5, 0.8, 1);
    }
    .search-field-enter,
    .search-field-leave-to {
      opacity: 0;
      transform: translateY(-56px);
    }

    &__overlay {
      position: absolute;
      top: 0;
      right: 0;
      bottom: -1px;
      left: 0;
      z-index: $zindex-site-header - 1;
      display: none;
      background: rgba(0, 0, 0, 0.5);

      &--visible {
        body:not(.scrolled) & {
          display: block;
        }
      }
    }
  }

  &__logo,
  &__logo--transparent {
    flex: 0 0 auto;
    height: 34px;

    img {
      width: 179px;
      height: 34px;

      &.camping {
        width: 202px;
      }
    }

    @include bp-down(sm) {
      height: 32px;

      img {
        width: 168px;
        height: 32px;

        &.camping {
          width: 190px;
        }
      }
    }

    @include bp-down(xs) {
      height: 27px;

      img {
        width: 143px;
        height: 27px;

        &.camping {
          width: 160px;
        }
      }
    }
  }

  &__logo--transparent {
    display: none;
  }

  &__nav-container {
    position: relative;
    flex: 1 1 auto;
    padding: rem(20px) 0;

    @include bp-down(sm) {
      display: none;
    }
  }

  &__nav {
    @include list-unstyled();
    display: flex;
    align-items: center;
  }

  &__nav-border {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 0;
    height: rem(3px);
    background: $border-active-color;
    transition: width $transition-base;

    &--in {
      transition: width $transition-base, left $transition-base;
    }
  }

  &__nav-item {
    &:not(:first-child) {
      padding-left: space(4);
    }
    a {
      display: flex;
      align-items: center;
      font-size: $font-size-sm;
      font-weight: $font-weight-medium;
      color: color("primary");

      body.transparent-top & {
        color: color("white");
      }

      body.transparent-top.scrolled &,
      body.transparent-top.overlay-open & {
        color: color("primary");
      }

      span {
        @include font-top();
      }

      @include bp-down(md) {
        padding: 0 space(2);
      }
    }

    &--active {
      .fa-angle-down {
        transform: rotate(180deg);
      }

      a {
        font-weight: $font-weight-medium;
      }
    }

    .fa-angle-down {
      position: relative;
      top: 1px;
      margin-left: rem(6px);
      font-size: rem(18px);
    }
    &--no-dropdown {
      .fa-angle-down {
        display: none;
      }
    }
  }

  &__actions {
    display: flex;
    align-items: center;

    .btn {
      padding: rem(14px) rem(24px);
      @include bp-down(md) {
        display: none;
      }
    }
  }

  &__action-item {
    position: relative;
    display: flex;
    align-items: center;
    margin-left: space(4);
    font-size: $font-size-sm;
    font-weight: $font-weight-medium;
    color: color("primary");
    cursor: pointer;

    body.transparent-top:not(.scrolled) & {
      color: color("white");
    }

    body.transparent-top:not(.scrolled) .site-header--nav-overlay-open & {
      color: color("primary");
    }

    @include bp-down(sm) {
      margin-left: space(3);
    }

    @include bp-down(xs) {
      margin-left: space(2);
    }

    svg {
      margin-right: space(2);
      font-size: rem(18px);

      @include bp-down(sm) {
        font-size: rem(18px);
      }
    }

    .badge {
      position: absolute;
      top: rem(-4px);
      left: rem(15px);
      display: none;
      color: $white;

      &.active {
        display: block;
      }
    }

    &--search {
      @include bp-up(md) {
        display: none;
      }
    }
    &--login {
      @include bp-up(md) {
        display: none;
      }
    }
    &--cart {
      @include bp-up(md) {
        display: none;
      }
    }

    &--nav {
      display: none;
      height: rem(18px);

      @include bp-down(sm) {
        display: flex;
      }

      i {
        position: relative;
        display: block;
        width: 26px;
        height: 2px;
        background: $primary;
        transition: all 0.22s;

        &::before,
        &::after {
          position: absolute;
          right: 0;
          display: block;
          height: 2px;
          content: "";
          background: $primary;
          transition: all 0.3s;
        }

        &::before {
          top: -9px;
          width: 19px;
        }

        &::after {
          top: 9px;
          width: 20px;
        }
      }

      &.site-header__action-item--active {
        i {
          background: none;

          &::before {
            top: 0;
            transform: rotate(-45deg);
          }

          &::after {
            top: 0;
            transform: rotate(45deg);
          }
        }
      }
    }
  }

  &--transparent {
    background: transparent;
    border-bottom: 0;
    box-shadow: none;

    .site-header__action-item--nav {
      i {
        &::before,
        &::after {
          background: $white;
        }
      }

      &:not(.site-header__action-item--active) {
        i {
          background: $white;
        }
      }
    }
  }

  &--transparent-scrolled {
    background: transparent;
    border-bottom: 0;
    box-shadow: none;

    .site-header__action-item--nav {
      i {
        &::before,
        &::after {
          background: $primary;
        }
      }

      &:not(.site-header__action-item--active) {
        i {
          background: $primary;
        }
      }
    }
  }

  &__meta {
    border-bottom: $border-width solid $border-alt-color;

    body.transparent-top:not(.scrolled) & {
      border-bottom: 0;
    }

    @include bp-down(sm) {
      //margin-bottom: rem(15px);
    }

    .tab-bar {
      display: flex;
      border: none;

      &__item {
        padding: space(3) 0 space(2);
        margin-right: space(4);
        font-size: $font-size-sm;
        @include bp-down(sm) {
          padding: rem(13px) 0 space(2);
          margin-right: rem(12px);
          font-size: $font-size-xs;
        }

        body.transparent-top:not(.scrolled) & {
          color: color("white");
        }

        body.transparent-top:not(.scrolled) .site-header--nav-overlay-open & {
          color: color("black");
        }

        @include bp-down(sm) {
          body.transparent-top .site-header--nav-overlay-open & {
            color: color("black");
          }
        }

        &--active {
          body.transparent-top:not(.scrolled) & {
            border-color: color("white");
          }

          body.transparent-top:not(.scrolled) .site-header--nav-overlay-open & {
            border-color: color("primary");
          }

          @include bp-down(sm) {
            body.transparent-top .site-header--nav-overlay-open & {
              border-color: color("primary");
            }
          }
        }
      }
    }

    &__links {
      display: flex;

      @include bp-down(md) {
        display: none;
      }

      &__item {
        margin-left: space(3);
        font-size: $font-size-sm;
        color: color("muted");

        body.transparent-top:not(.scrolled) & {
          color: color("white");
        }
      }
    }
  }

  &__main {
    position: relative;
    background-color: $white;

    @include bp-down(sm) {
      padding: rem(14px) 0;
    }
    .container {
      display: flex;
      align-items: center;
      justify-content: space-between;
    }

    @include bp-up(md) {
      border-bottom: $border-width solid $border-alt-color;
    }
    body.transparent-top:not(.scrolled) & {
      background: transparent;
      border-bottom: none;
    }
  }

  &__sub-bar {
    position: relative;
    z-index: -1;
    background-color: $white;

    .container {
      display: flex;
      align-items: center;
      justify-content: space-between;
    }

    .site-header__action-item--login {
      display: block;
    }
    .site-header__action-item--cart {
      display: block;
    }

    @include bp-down(sm) {
      display: none;
    }

    body.transparent-top:not(.scrolled) & {
      background: transparent;
    }
  }
}

body.transparent-top:not(.scrolled) {
  .site-header__logo {
    display: none;
  }

  .site-header__logo--transparent {
    display: block;
  }
}

body.transparent-top.overlay-open {
  .site-header__logo {
    display: block;
  }

  .site-header__logo--transparent {
    display: none;
  }
}

.siteselector {
  display: flex;
  flex-direction: row;
  justify-content: space-between;

  .btn {
    display: block;
    width: 100%;
    margin: space(2);
  }

  @include bp-down(sm) {
    flex-direction: column;

    .btn {
      margin: space(2) 0;
    }
  }
}
