.toolbar {
  position: relative;
  z-index: 2;
  padding-top: rem(12px);
  padding-bottom: rem(12px);
  background: color("green-bg");
  border-bottom: $border-width solid $border-color;

  @include bp-up(md) {
    border: $border-width solid color("green");
    border-radius: $border-radius;
  }

  .btn--xxs {
    @include bp-up(sm) {
      margin-top: -2px;
    }
  }
  .tooltip-container {
    position: absolute;
    left: -1px;
    padding-top: 20px;

    .tooltip-content {
      max-width: rem(406px);
      padding: 20px;
      background-color: color("white");
      border: 1px solid color("green");
      border-radius: 5px;

      @media (max-width: 460px) {
        margin: 0 1rem;
      }

      &--arrow {
        &::after {
          position: absolute;
          top: 6px;
          left: 186px;
          width: 0;
          height: 0;
          pointer-events: none;
          content: " ";
          border: solid transparent;
          border-color: rgba(136, 183, 213, 0);
          border-width: 7px;
          border-bottom-color: color("green");

          @media (max-width: 460px) {
            display: none;
          }
        }
      }

      .tooltip-title {
        margin-bottom: 12px;
        font-size: $font-size-base;
        font-weight: $font-weight-medium;
      }
      .tooltip-content-text {
        margin-bottom: 12px;
      }
      .tooltip-product {
        margin-bottom: 12px;
        font-weight: $font-weight-medium;
      }
    }

  }

  > .container {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    font-size: $font-size-xs;
  }

  &--danger {
    background: color("red-bg");
  }

  &--warning {
    background: color("yellow-bg");
    border-color: color("yellow");
  }

  @include bp-down(sm) {
    &--arrow {
      &::after,
      &::before {
        position: absolute;
        top: 100%;
        left: 50%;
        width: 0;
        height: 0;
        pointer-events: none;
        content: " ";
        border: solid transparent;
      }

      &::after {
        margin-left: -7px;
        border-color: rgba(136, 183, 213, 0);
        border-width: 7px;
        border-top-color: color("green-bg");
      }

      &::before {
        margin-left: -9px;
        border-color: rgba(194, 225, 245, 0);
        border-width: 9px;
        border-top-color: $border-color;
      }
    }
  }
}
