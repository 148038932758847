.campaign-row {
  display: flex;
  justify-content: space-between;
  @include bp-down(sm) {
    display: block;
  }
  .promo {
    @include bg-cover();
    width: calc(33.333% - 0.5px);

    @include bp-down(sm) {
      width: 100%;
      margin-top: space(1);
    }
  }
}

.promo + .campaign-row {
  margin-top: 0.5px;
}
