.logo-list {
  display: flex;
  flex-wrap: wrap;
  align-items: center;

  &--horizontal {
    flex-wrap: nowrap;
    overflow: auto;
  }

  &--button {
    display: inline-flex;
    height: 64px;
    margin-left: 1rem;

    & .btn {
      line-height: rem(25px);
    }

    @include bp-down(sm) {
      height: 48px;
      margin: 1rem;

      & .btn {
        line-height: rem(8px);
      }
    }
  }

  &--wrapper {
    display: flex;
    flex-direction: row;
    align-items: center;

    @include bp-down(sm) {
      flex-direction: column;
    }
  }

  &:not(:last-child) {
    margin-bottom: space(4);

    @include bp-down(sm) {
      margin-bottom: space(3);
    }
  }

  .vehicle-listing-hero & {
    margin-top: space(1);
    margin-bottom: space(1);
  }

  &__item-wrapper {
    margin: space(2);

    &:first-child {
      margin-left: 0;
    }

    &:last-child {
      margin-right: 0;
    }

    @include bp-down(sm) {
      margin: space(2) 0;
    }
  }

  &__item {
    width: calc(16.66% - #{space(4)});
    min-width: rem(20px);
    max-width: rem(40px);

    margin: space(2);

    &:first-child {
      margin-left: 0;
    }

    &:last-child {
      margin-right: 0;
    }

    &--lg {
      width: 64px;
      min-width: rem(64px);
      max-width: rem(64px);
    }

    &--bordered {
      display: inline-flex;
      width: 64px;
      height: 64px;
      padding: space(1);
      border: $border-width solid $border-color;
      border-radius: 0.25rem;

      & img {
        object-fit: contain;
      }
    }
  }

  &--lg {
    .logo-list__item {
      max-width: rem(70px);
      margin: space(2) space(4);

      @include bp-down(sm) {
        width: calc(50% - #{space(4)});
      }
    }
  }

  &--centered {
    justify-content: center;
  }
}

.page-header__description {
  .logo-list--horizontal {
    @include bp-down(sm) {
      flex-flow: row wrap;
      overflow: auto;

      .logo-list__item-wrapper {
        display: flex;
        flex-basis: 33.3333%;
        flex-shrink: 0;
        flex-grow: 0;
        justify-content: center;
      }
    }
  }
}
