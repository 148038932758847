.checkout-section {
  position: relative;
  padding: space(5);
  margin-bottom: space(6);
  background: $white;
  border: $border-width solid $border-color;
  border-radius: $border-radius;

  @include bp-down(sm) {
    padding: space(3);
    margin-bottom: rem(12px);
    border-radius: 0;
  }

  &__summary {
    padding: space(5);
    margin: - space(5);
    margin-top: space(5);
    background: color("blue-bg");
    border-top: $border-width solid $border-color;
    border-bottom-right-radius: $border-radius;
    border-bottom-left-radius: $border-radius;

    .properties {
      padding-bottom: 0;
    }

    .feedback-message {
      margin-bottom: space(2);
    }

    @include bp-down(sm) {
      margin: - space(3);
      margin-top: space(3);
      border-radius: 0;
    }
  }

  &__header {
    margin-bottom: space(4);

    svg {
      margin-right: rem(6px);
      font-size: rem(24px);
    }
  }

  &--arrow {
    @include bp-up(md) {
      &::after,
      &::before {
        position: absolute;
        top: 100%;
        left: 50%;
        width: 0;
        height: 0;
        pointer-events: none;
        content: " ";
        border: solid transparent;
      }

      &::after {
        margin-left: -10px;
        border-color: rgba(136, 183, 213, 0);
        border-width: 10px;
        border-top-color: $white;
      }

      &::before {
        margin-left: -12px;
        border-color: rgba(194, 225, 245, 0);
        border-width: 12px;
        border-top-color: $border-color;
      }
    }
  }

  &--summary {
    &.checkout-section--arrow {
      &::after {
        border-top-color: color("blue-bg");
      }
    }
  }
}
