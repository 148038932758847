.configurator-app {
  overflow-x: hidden;

  @include bp-down(sm) {
    margin-right: -1rem;
    margin-left: -1rem;
    box-shadow: 0px 1px 1px #0000000a;
  }

  &__selections {
    padding: space(8) 0;

    @include bp-down(md) {
      padding: space(8) 0;
    }

    @include bp-down(sm) {
      padding: space(5) space(3);
    }
  }

  &__summary {
    @include bp-down(sm) {
      padding: space(3);
      background: color("white");
    }

    &__container {
      padding: space(5);
      background: color("white");
      border: $border-width solid $border-color;
      border-radius: $border-radius;

      @include bp-down(sm) {
        padding: 0;
        border: 0;
        border-radius: 0;
      }
    }

    &__wrapper {
      padding-right: space(5);
      padding-left: space(5);
      margin-bottom: space(5);
      background: color("gray-bg");
      border: $border-width dashed $border-color;

      @include bp-up(md) {
        padding: space(5);
      }

      @include bp-down(sm) {
        padding: space(3) space(4) space(3) space(4);
      }
    }
  }

  &__config-group {
    &:not(:last-child) {
      margin-bottom: space(6);
    }
  }

  &__contact-section {
    margin-top: space(4);

    &:not(:last-child) {
      margin-bottom: space(6);
    }

    &__selected-option {
      background: color("gray-bg");
    }

    &__header {
      margin-top: space(3);
      margin-bottom: space(3);
      font-size: $font-size-lg;
      font-weight: $font-weight-medium;
    }

    &__regnr {
      margin-top: space(2);
      margin-bottom: space(5);
      &__title {
        font-size: $font-size-lg;
        font-weight: $font-weight-medium;
      }
      &__text {
        font-size: $font-size-sm;
      }
    }
  }

  &__summation-footer {
    margin-top: space(3);
  }

  &__summation-footer-info {
    margin: space(3) 0;
  }

  .tab-bar {
    display: flex;
  }

  .tab-bar__item {
    padding-right: 0;
    padding-left: 0;
    margin-right: space(4);
  }

  .properties {
    @include bp-down(sm) {
      .properties__item--header {
        .properties__label {
          font-weight: $font-weight-bold;
        }
      }

      li {
        flex-direction: column;
        align-items: flex-start;

        .properties__label {
          display: flex;
        }

        .properties__meta {
          margin-left: space(1);
        }
      }
    }
  }

  .vehicle-line {
    .properties__item {
      justify-content: flex-start;
      &__image {
        max-width: 105px;
        max-height: 70px;
        margin-right: space(3);
        border-radius: $border-radius;

        @include bp-down(sm) {
          display: none;
        }
      }

      &__label {
        font-size: $font-size-lg;
        font-weight: $font-weight-medium;
      }

      &__sub-label {
        margin-bottom: space(2);
      }
    }

    .properties__value {
      margin-left: auto;
      white-space: nowrap;
    }
  }
}
