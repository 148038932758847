.bottom-action-bar {
  position: fixed;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1000;
  display: flex;
  padding: space(3) space(3);
  background: color("white");
  box-shadow: 0px -4px 6px #0000000d;
  transition: 0.2s all linear;
  transform: translateY(100%);

  &--visible {
    display: flex;
    transform: none;
  }

  &__data {
    display: flex;
    flex: 1;
    align-items: center;
    justify-content: space-between;

    @include bp-down(md) {
      display: none;
    }
  }

  &__vehicle {
    display: flex;

    &__info {
      margin-left: space(3);
    }

    &__title {
      margin-bottom: 2px;
      font-size: $font-size-sm;
      font-weight: $font-weight-medium;
    }

    &__subtitle {
      font-size: $font-size-sm;
    }
  }

  &__image {
    max-width: rem(56px);

    img {
      width: 100%;
      height: 100%;
      border-radius: rem(6px);
      object-fit: cover;
    }
  }

  &__price {
    font-size: $h3-font-size;

    &__pricing-meta {
      font-size: $font-size-xxs;
      color: color("muted-light");
      text-align: end;
    }

    .price-tag {
      margin-right: space(1);
      font-size: $font-size-base;
    }

    .price-tag--discount {
      margin-right: 0;
    }

    .price-tag--obsolete {
      font-size: $font-size-xs;
      color: color("muted-light");
      text-align: end;
    }
  }

  &__actions {
    display: flex;
    align-items: center;
    margin-left: space(4);

    @include bp-down(md) {
      flex: 1;
      margin-left: 0;
    }
  }

  a {
    padding: space(3);
    font-size: $font-size-xs;
    &:last-child {
      margin-left: space(2);
    }

    @include bp-down(md) {
      flex: 1;
    }
  }

  .expanded {
    width: 100%;
  }
}
