.teaser {
  position: relative;
  display: block;

  &__image {
    @include bg-image();
    height: rem(138px);
    margin-bottom: space(3);
  }

  .ribbon {
    position: absolute;
    top: rem(12px);
    left: - rem(8px);
  }

  &__header {
    margin-bottom: space(1);
    font-weight: $font-weight-medium;
    color: color("primary");
  }

  &__meta,
  &__text {
    font-size: $font-size-sm;
    color: color("muted");
  }
}
