.product-images {
  position: relative;
  display: flex;
  width: 100%;

  &__item {
    @include bg-image(0);
    width: 100%;
    padding-bottom: aspect-ratio-percent(1.5, 1);

    .phy-ply-holmgrensbil .phy-overlay-ctrls {
      margin-bottom: 40px;
    }

    .phy-ply-holmgrensbil {
      // stylelint-disable declaration-no-important
      position: absolute !important;
      top: 0;
      display: flex;
      width: 100%;
      height: 100%;
      object-fit: cover;
      background: #000;
    }

    &--vertical {
      padding-bottom: aspect-ratio-percent(1.8, 1);
    }

    &--overlay {
      span {
        position: absolute;
        top: calc(50% - #{rem(14px)});
        z-index: 1;
        width: 100%;
        font-size: $font-size-lg;
        color: $white;
        text-align: center;

        @include bp-down(sm) {
          font-size: $font-size-base;
        }
      }

      &::after {
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        content: "";
        background: rgba($black, 0.7);
      }
    }

    &__actions {
      position: absolute;
      right: space(3);
      bottom: space(3);

      &__btn {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 40px;
        height: 40px;
        color: color("muted-light");
        background-color: rgba($white, 0.75);
        border: $border-width solid rgba($gray-400, 0.5);
        border-radius: $border-radius;

        &--selected {
          color: $white;
          background-color: color("blue");
          border-width: 0;
        }
      }
    }

    &--fullscreen {
      cursor: pointer;
      user-select: none;
    }
  }

  &__counter,
  &__fullscreen {
    position: absolute;
    bottom: space(3);
    z-index: 100;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 89px;
    padding-top: space(2);
    padding-bottom: space(2);
    font-size: $font-size-xs;
    font-weight: $font-weight-medium;
    color: color("primary");
    background-color: color("white");
    border-radius: $border-radius;
    box-shadow: 0px 1px 2px #0000000d;

    svg,
    i {
      margin-right: space(1);
    }
  }
  &__counter {
    left: space(4);
  }

  &__fullscreen {
    right: space(4);
    cursor: pointer;
  }

  .swiper-container {
    display: flex;
    width: 100%;

    .swiper-wrapper {
      height: auto;
    }

    .swiper-slide {
      display: flex;
      height: auto;
    }
  }

  .swiper-pagination-bullets {
    bottom: space(4);
  }
  .swiper-pagination-bullet {
    background: gray("500");
    opacity: 1;
  }

  .swiper-pagination-bullet-active {
    background: color("primary");
  }

  &__nav {
    position: absolute;
    top: calc(50% - 20px);
    z-index: 1;
    display: flex;
    align-items: center;
    justify-content: center;
    width: rem(48px);
    height: rem(48px);
    font-size: $font-size-lg;
    color: color("primary");
    cursor: pointer;
    background-color: rgba(255, 255, 255, 0.9);
    border-radius: rem(48px);
    transition: opacity $transition-base;

    @include bp-down(sm) {
      display: none;
    }

    &--prev {
      left: space(3);
    }

    &--next {
      right: space(3);
    }

    &--disabled {
      cursor: default;
      opacity: 0.3;
    }
  }
}
