.eon-product-services-modal {
  > .feedback-message {
    margin-bottom: space(4);
  }

  &__actions {
    display: flex;
    justify-content: space-between;
    margin-top: space(3);

    .btn--block {
      margin-left: space(3);
    }

    @include bp-down(sm) {
      display: block;
      .btn {
        width: 100%;

        &:first-child {
          margin-bottom: space(3);
        }
      }
      .btn--block {
        margin-left: 0;
      }
    }
  }
}
