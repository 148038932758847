.eon-result {
  &__header {
    margin-bottom: rem(12px);
    font-size: $font-size-sm;

    > strong {
      margin-left: space(1);
      font-size: $font-size-xs;
      text-transform: uppercase;
    }

    > span {
      margin-left: space(1);
      font-size: $font-size-xs;
      color: color("muted");
    }

    i,
    svg {
      font-size: rem(14px);
      color: color("danger");
    }
  }

  &__items {
    @include bp-up(xl) {
      @include make-row();
    }
  }

  &__item {
    display: flex;
    margin-bottom: space(4);

    @include bp-up(xl) {
      @include make-col-ready();
      @include make-col(6);
    }
  }

  &__querying {
    opacity: 0.5;
  }

  &__querying-content {
    position: absolute;
    z-index: 1;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    padding-right: 40px;
    margin-top: 50px;
    font-size: 100px;
  }

  &__querying-content-end {
    position: absolute;
    z-index: 1;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    padding-right: 40px;
    font-size: 60px;
    transform: translate(0%, -200%);
  }

  .feedback-message--danger {
    margin-bottom: space(4);
    &__text {
      color: color("black");
    }
  }

  &__empty {
    padding: space(5);
    background: $white;
    border-radius: rem(6px);
    box-shadow: 0px 1px 1px #0000000a;
    &__title {
      margin-bottom: space(3);
      font-size: $font-size-lg;
      font-weight: $font-weight-medium;
    }

    &__text {
      margin-top: space(4);
      font-size: $font-size-xs;
      color: color("muted");
    }
  }
}
