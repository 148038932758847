.link-list {
  ul {
    @include list-unstyled();
  }

  li {
    font-size: $font-size-sm;

    &:not(:last-child) {
      margin-bottom: space(2);
    }

    a {
      color: color("primary");
    }
  }

  &__header {
    display: block;
    margin-bottom: space(2);
    font-size: $font-size-lg;
    font-weight: $font-weight-medium;
  }
}
