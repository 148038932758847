.facet-page-list {
  @include make-row();
  margin-top: 0.5rem;
  margin-bottom: 0.5rem;

  @include bp-down(sm) {
    &.expanded {
      display: flex;
    }
  }

  h4 {
    border-bottom: 1px $border-color solid;
  }

  &__item {
    @include make-col-ready();
    margin-bottom: space(3);

    @include bp-up(xl) {
      @include make-col(12/6);
    }

    @include bp-only(lg) {
      @include make-col(3);
    }

    @include bp-only(md) {
      @include make-col(4);
    }

    @include bp-down(sm) {
      @include make-col(6);
    }
  }

  .list-expand__content {
    position: relative;
    height: 105px;
    margin-bottom: 12px;
    overflow: hidden;
  }

  &--contracted {
    display: none;

    &.expanded {
      display: flex;
    }
  }

  .link-toggler--active {
    .list-expand__content {
      height: auto;
    }

    .shaded-expand__cover {
      display: none;
    }

    .shaded-expand__btn--expanded {
      display: inline;
    }

    .shaded-expand__btn--expand {
      display: none;
    }
  }
}
