.ecom-usp-block {


  .container {
    max-width: rem(1040px);
  }

  .section__header {
    margin-bottom: space(4);
  }

  &__meta {
    margin-bottom: space(8);
    font-size: $h3-font-size;
    color: color("muted");
    text-align: center;
    p {
      font-size: $h3-font-size;
      color: color("muted");
    }
  }

  &__brands {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
    padding-top: space(7);
    text-align: center;
    border-top: $border-width solid $border-color;

    @include bp-down(sm) {
      margin-top: space(5);
    }

    img {
      max-width: rem(160px);
      margin-bottom: space(3);

      &:not(:last-child) {
        margin-right: space(4);
      }
    }

    @include bp-up(sm) {
      margin-top: space(5);
    }
  }

  &__usp {
    margin-bottom: space(8);

    @include bp-down(sm) {
      display: flex;
      margin-bottom: space(2);
    }
  }

  &__usp-icon {
    display: flex;
    align-items: center;
    justify-content: center;
    width: rem(60px);
    height: rem(60px);
    padding: rem(12px);
    margin-bottom: space(4);
    border-radius: 12px;
    box-shadow: $box-shadow;

    i,
    svg {
      font-size: rem(24px);
    }

    @include bp-down(sm) {
      flex-shrink: 0;
      margin-right: space(3);
      margin-bottom: 0;
    }
  }

  &__usp-header {
    margin-bottom: space(1);
    font-weight: $font-weight-medium;
  }

  &__usp-text {
    font-size: $font-size-sm;
    color: color("muted");
  }

  &--horizontal {
    padding-top: space(3);

    .ecom-usp-block__usp {
      display: flex;

      &-icon {
        margin-right: space(3);
      }
    }
  }
}
