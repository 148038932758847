$site-footer-border-color: #2c2c2c;

.site-footer {
  &__section {
    padding: font-padding(space(7), 0);

    &:not(:last-child) {
      border-bottom: 1px solid $site-footer-border-color;
    }

    @include bp-down(sm) {
      padding: font-padding(space(6), 0);
    }

    &.footer-brand-list {
      width: 100%;
    }
  }

  &__section-header {
    color: color("white");

    .header-meta {
      color: gray("500");
    }
  }

  &__about {
    font-size: $font-size-sm;
  }

  &__main {
    display: flex;
    flex-direction: column;
    align-items: center;
    color: $white;
    background: gray("900");

    .link-list {
      li a {
        color: $white;
      }

      &__header {
        color: color("white");
      }
    }
  }

  &__to-top-btn {
    z-index: 100;
    display: flex;
    align-items: center;
    padding: space(1) space(5);
    margin-top: -(space(4));
    cursor: pointer;
    background: gray("900");
    border-radius: $border-radius;

    &__icon {
      margin-left: space(2);
      font-size: $font-size-lg;
    }
  }

  &__logo {
    display: block;
    margin-bottom: space(3);

    img {
      width: 179px;
      height: 34px;

      @include bp-down(sm) {
        width: 168px;
        height: 32px;
      }
    }

    .camping {
      width: 202px;

      @include bp-down(sm) {
        width: 190px;
      }
    }
  }

  &__brands {
    @include list-unstyled();
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
    margin: 0 -#{rem(35px)};

    @include bp-down(sm) {
      margin: 0 -#{rem(13px)};
    }

    li {
      padding: rem(18px) rem(35px);

      @include bp-down(sm) {
        padding: rem(15px) rem(13px);
      }
    }

    a {
      display: flex;
      align-items: center;
      justify-content: center;
      width: rem(60px);
      height: rem(60px);
    }

    .wider {
      width: rem(80px);
      height: rem(80px);
    }

    .regular {
      padding: space(1);
      background: color("white");
      border-radius: $border-radius;
    }
  }

  &__nav {
    display: flex;

    @include bp-only(md) {
      margin-top: space(6);
    }

    @include bp-down(sm) {
      display: none;
    }
  }

  &__meta {
    padding: font-padding(space(4), 0);
    font-size: $font-size-xs;
    color: $white;
    background: gray("900");

    > .container {
      display: flex;
      align-items: center;
      justify-content: space-between;

      @include bp-down(sm) {
        display: block;
        text-align: center;
      }
    }
  }

  &__meta-links {
    @include list-unstyled();
    display: flex;
    order: 2;

    li {
      &:not(:last-child) {
        margin-right: space(4);
      }
    }

    @include bp-down(sm) {
      display: block;
      margin-bottom: space(3);

      li {
        display: inline-block;
      }
    }
  }

  .nav-tree {
    display: none;

    > ul > .nav-tree__item {
      color: color("white");
    }

    &__item {
      border-color: $site-footer-border-color;

      svg {
        color: $white;
      }
    }

    @include bp-down(sm) {
      display: block;
    }
  }
}
