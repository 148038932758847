.i3-site-search {
  .overlay-header {
    background: color("gray-bg");
  }

  .search-bar {
    margin-top: 2px;
    background: $white;
  }

  &__suggestions {
    padding: space(4);

    @include bp-down(sm) {
      padding-right: space(3);
      padding-left: space(3);
    }

    &__list {
      display: flex;
      flex-wrap: wrap;

      .link {
        margin-right: 12px;
        margin-bottom: 12px;
        font-weight: $font-weight-medium;
      }
    }
  }

  &__no-results {
    padding: space(4);
    color: color("muted");
    text-align: center;

    @include bp-down(sm) {
      padding-right: space(3);
      padding-left: space(3);
    }
  }

  label {
    margin-bottom: space(3);
    color: gray("900");
  }
}
