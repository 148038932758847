.fullheight-iframe {
  width: 100%;
  height: auto;
  overflow-x: auto;
  overflow-y: scroll;

  iframe {
    width: 100%;
    height: 100vh;
    min-height: 100vh;
    border: none;
  }
}
