.product-main {
  position: relative;
  background: $white;
  border-right: $border-color solid $border-width;
  border-left: $border-color solid $border-width;

  @include bp-up(md) {
    padding: space(6) space(5) space(4);
  }

  @include bp-down(sm) {
    padding-right: space(3);
    padding-bottom: space(3);
    padding-left: space(3);
    border: 0;
    border-bottom: $border-width solid $border-color;
    border-radius: 0;
  }

  &--pull-up {
    margin-top: - space(8);
    border-top: $border-color solid $border-width;
    border-top-left-radius: $border-radius;
    border-top-right-radius: $border-radius;

    @include bp-down(sm) {
      margin-top: 0;
    }
  }

  .ribbon {
    position: absolute;
    top: - $ribbon-arrow-right-size;
    left: space(5);

    @include bp-down(md) {
      left: space(3);
      z-index: 2;
    }
  }

  .product-info {
    display: flex;
    justify-content: space-between;
  }

  .product-meta {
    margin-bottom: space(2);
  }

  .product-usps {
    margin-bottom: space(3);
  }

  .product-facts {
    margin-top: auto;
  }

  .bookmark {
    display: flex;
    align-items: flex-start;
    justify-content: flex-end;
    min-width: rem(100px);
    color: color("blue");
    &__link {
      margin-top: rem(2px);
      font-size: $font-size-xs;
      &--selected {
        color: gray("900");
      }
    }

    &__icon-container {
      margin-right: space(1);
    }

    &__icon {
      &--filled {
        color: color("blue");
      }
    }

  }

  &__footer {

    @include bp-up(md) {
      display: flex;
      justify-content: space-between;
      padding-top: space(3);
      padding-right: space(5);
      padding-left: space(5);
      margin-right: - space(5);
      margin-left: - space(5);
      border-top: $border-width solid $border-color;
    }
  }

  &__footer-section {
    position: relative;
    display: flex;
    flex-grow: 1;
    align-items: center;
  }

  &__pricing-text {
    position: absolute;
    top: -25px;
    right: - space(2);
    padding-right: space(2);
    padding-left: space(2);
    font-size: $font-size-sm;
    font-weight: $font-weight-medium;
    color: color("attention");
    background: $white;
  }

  &__pricing {
    text-align: right;

    @include bp-down(sm) {
      text-align: left;
    }

    .price-tag {
      display: block;

      &--obsolete {
        font-size: $font-size-sm;
      }

      &--discount {
        font-size: $font-size-lg;
      }
    }

    &__meta {
      font-size: rem(10px);
      color: color("muted-light");
      text-transform: uppercase;
      letter-spacing: 0.5px;
    }
  }

  &__actions {
    flex-grow: 1;
    margin-left: space(3);
  }

  @include bp-up(md) {
    + .section__content {
      margin-bottom: space(8);
    }
  }
}
