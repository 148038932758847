.call-action {
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: $font-size-sm;

  &:not(:last-child) {
    margin-bottom: rem(12px);
  }

  &__number {
    display: none;
    font-size: $font-size-sm;

    &--toggler {
      display: block;

      span {
        font-size: $font-size-sm;
        color: $white;
        text-decoration: none;
      }
    }
  }

  i,
  svg {
    margin-right: space(1);
    margin-bottom: 2px;
  }

  &--toggled {
    .call-action__number {
      display: block;
    }

    .call-action__number--toggler {
      display: none;
    }
  }
}

.btn--muted {
  .call-action__number--toggler span {
    color: $blue;
  }
}
