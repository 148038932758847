// stylelint-disable declaration-no-important

// .footer-contact-modal-icon {
//   position: fixed;
//   right: 2rem;
//   bottom: 4rem;
//   z-index: $zindex-overlay;
//   display: flex;
//   align-items: center;
//   justify-content: center;
//   width: 50px;
//   height: 50px;
//   font-size: rem(24px);
//   color: #fff;
//   cursor: pointer;
//   background: color("primary");
//   border-radius: 100%;

//   @include bp-down(sm) {
//     .vehicle-page & {
//       bottom: 6rem;
//     }
//   }

//   &__icon-open {
//     display: none;
//   }
// }

.modal-dialog--quick-contact {
  width: 450px;
  .modal-body {
    padding: 0;
    overflow-x: hidden;
    overflow-y: auto;
    background: $white;
  }
}

.contact-pop-up {
  top: auto !important;
  bottom: 100px;
  left: auto;
  .modal-dialog {
    right: 0;
  }

  .modal-content {
    height: 65vh;
  }

  @include bp-down(sm) {
    top: 0 !important;
    bottom: 0;
    width: 100%;

    .modal-dialog {
      width: 100%;
    }
  }
}

.contact-forms--quick-contact {
  .contact-forms {
    display: block;
    margin-right: -12px;
    margin-left: -12px;
    background: $white;

    &__item {
      position: relative;
      background: $white;
      border-bottom: $border-width solid $border-color;

      &--selected {
        padding-bottom: space(3);
        background-color: color("gray-bg");

        @include bp-down(sm) {
          background-color: color("white");
        }

        .contact-forms__item-name {
          padding-bottom: rem(12px);
          font-weight: $font-weight-medium;
        }

        .contact-forms__item-icon {
          transform: rotate(180deg);
        }
      }
    }
    &__item-name {
      padding: font-padding(space(4), space(3));
      line-height: 1;
      cursor: pointer;
    }

    &__item-icon {
      position: absolute;
      top: rem(20px);
      right: space(3);
      font-size: rem(24px);
      pointer-events: none;
    }

    &__form {
      @include bp-up(md) {
        padding: space(3);
        background: none;
        border: none;
      }
      .form-stack {
        margin-bottom: space(3);
      }

      @include bp-down(sm) {
        width: auto;
        margin-right: space(3);
        margin-left: space(3);
        background-color: color("gray-bg");
      }
    }
  }

  .info-block {
    margin-bottom: space(3);
  }
}
