// stylelint-disable declaration-no-important
.ecom-list-page {
  @include bp-up(md) {
    .page-header {
      padding-top: space(6);
      padding-bottom: space(6);
    }

    .toolbar {
      margin-top: space(3);
      .container {
        padding: 0 space(3);
      }
    }
  }

  @include bp-down(sm) {
    .page-header {
      padding-top: 0;
      padding-bottom: 0;
      margin-bottom: -1px;

      &__controls {
        padding-right: space(3);
        padding-left: space(3);
      }

      &__controls:first-child {
        .form-control {
          border-top: 0;
        }
      }

      &__control:not(:only-child) {
        &:nth-child(2n) {
          .form-control {
            border-right: 0;
          }
        }
        &:nth-child(1n) {
          .btn {
            border-left: 0;
          }
        }
      }

      &__control:only-child {
        .form-control {
          border-right: 0;
          border-left: 0;
        }
      }

      &__description {
        display: none;
      }

      > .container {
        padding: 0;
      }
    }
  }
}

.eon-filter-page {
  &__header {
    border-bottom: $border-width solid $border-color;
  }

  &__desktop-results {
    margin-top: space(5);
  }
}

.eon-filter {
  @include bp-down(md) {
    padding: space(3);
  }

  &--column {
    display: flex;

    .eon-filter__field {
      display: flex;

      .tab-button {
        margin-right: space(2);
        margin-bottom: 0;
      }
    }

    .eon-filter__section:not(:first-child) {
      .eon-filter__section-header {
        margin-left: space(5);
      }
    }
  }

  &__section {
    margin-bottom: space(3);

    &--injected-filter {
      .eon-filter__field {
        margin-left: 0 !important;
      }

      .eon-filter__section-header {
        @include bp-down(md) {
          min-height: 0;
          margin-bottom: 0;
        }
      }
    }

    &:first-child {
      &.eon-filter__section--collapsible {
        border-top: $border-width solid $border-color;
      }
    }

    &:not(:first-child) {
      .eon-filter__field {
        margin-left: space(5);
      }
    }
    &--child {
      margin-bottom: 0 !important;
    }

    &--collapsible {
      padding: font-padding(space(3), 0);
      margin-bottom: 0;
      border-bottom: $border-width solid $border-color;

      &:not(.eon-filter__section--collapsed) {
        .eon-filter__section--collapsible__header {
          margin-bottom: space(2);
        }
      }

      &__header {
        position: relative;
        font-size: $font-size-sm;
        cursor: pointer;

        .badge {
          margin-left: space(2);
        }

        i,
        svg {
          position: absolute;
          right: 0;
          font-size: rem(24px);
        }
      }
    }
  }

  &__section-header {
    display: flex;
    justify-content: space-between;
    min-height: rem(32px);
    margin-bottom: space(1);
    font-weight: $font-weight-medium;

    a {
      position: relative;
      top: - space(1);
    }

    // &--injected-filter {
    //   min-height: 0;
    //   margin-bottom: 0;
    // }
  }

  &__field {
    .form-check {
      margin-bottom: space(2);
    }
  }
}

.build-tyre {
  margin-top: space(5);
  &__heading {
    margin-bottom: space(4);
  }

  &__title {
    margin-bottom: space(3);
    font-size: $h3-font-size;
    font-weight: $font-weight-medium;
  }

  &__text {
    color: color("muted");
  }

  &__container {
    display: flex;

    @include bp-down(md) {
      flex-direction: column;
    }
  }

  &__section {
    position: relative;
    display: flex;
    flex: 1;
    flex-direction: column;
    background-color: $white;
    border-color: $border-color;
    border-style: solid;
    border-width: $border-width 0 $border-width $border-width;

    @include bp-down(md) {
      border-width: $border-width $border-width 0 $border-width;
    }

    &:first-child {
      border-top-left-radius: $border-radius;
      border-bottom-left-radius: $border-radius;

      @include bp-down(md) {
        border-top-right-radius: $border-radius;
        border-bottom-left-radius: 0;
      }
    }

    &:last-child {
      border-style: dashed;
      border-right-width: $border-width;
      border-top-right-radius: $border-radius;
      border-bottom-right-radius: $border-radius;

      @include bp-down(md) {
        border-style: solid;
        border-top-right-radius: 0;
        border-bottom-left-radius: $border-radius;
      }
    }
    &--selected {
      @include bp-up(md) {
        z-index: 1;
        border-color: transparent;
        border-radius: $border-radius;
        box-shadow: 0 0 0 4pt #1d69d41a;

        &::after,
        &::before {
          position: absolute;
          top: 100%;
          left: 50%;
          z-index: 2;
          width: 0;
          height: 0;
          pointer-events: none;
          content: " ";
          border: solid transparent;
        }

        &::after {
          margin-left: -12px;
          border-color: rgba(136, 183, 213, 0);
          border-width: 12px;
          border-top-color: $white;
        }

        &::before {
          margin-left: -18px;
          border-color: rgba(194, 225, 245, 0);
          border-width: 18px;
          border-top-color: #e1e9f6;
        }
      }
    }

    &--addon {
      @include bp-up(md) {
        background-color: color("blue-bg");
      }
    }

    &__header {
      padding: space(4) space(4) space(3) space(4);
      font-size: $font-size-lg;
      font-weight: $font-weight-medium;
      border-bottom: $border-width solid $border-color;

      @include bp-down(md) {
        display: none;
      }
    }
  }

  &__main {
    height: 100%;

    &__image {
      display: flex;
      align-items: center;
      justify-content: center;
      height: 100%;
      padding-top: space(6);
      padding-bottom: space(3);

      img {
        max-width: 240px;
        max-height: 240px;

        @include bp-down(md) {
          max-width: 65px;
          max-height: 65px;
        }
      }

      @include bp-down(md) {
        padding-top: 0;
        padding-bottom: 0;
      }

      &--placeholder {
        img {
          max-width: 160px;
          max-height: 160px;

          @include bp-down(md) {
            max-width: 40px;
            max-height: 40px;
          }
        }
      }
    }
  }

  &__content {
    flex: 1;
  }

  &__content-container {
    display: flex;
    flex-direction: column;
    height: 100%;
  }

  &__footer {
    padding: space(4);
    &__type {
      margin-bottom: space(2);
    }

    &__content {
      @include bp-down(md) {
        display: flex;
        align-items: center;
        justify-content: space-between;
      }

      &--price {
        display: block;
      }
    }

    &__action {
      display: flex;
      margin-top: space(3);

      &--with-selector {
        .form-stack {
          margin-bottom: 0;
        }

        .btn {
          margin-left: space(3);
        }
      }
    }

    &__price-row {
      display: flex;
      justify-content: space-between;

      &--meta {
        .build-tyre__footer__price-title,
        .build-tyre__footer__price {
          font-size: $font-size-xs;
          font-weight: $font-weight-regular;
          color: color("muted");
        }
      }
    }

    &__price-title {
      font-size: $font-size-xs;
      font-weight: $font-weight-bold;
    }

    &__price {
      font-weight: $font-weight-medium;
    }
  }

  &__vehicle-data {
    &__specs {
      margin-bottom: space(2);
    }

    &__price {
      .price-tag {
        font-weight: $font-weight-medium;
      }
    }
  }

  &__addon {
    padding: space(4);
    border-bottom: $border-width solid $border-color;
    &__content {
      display: flex;
      margin-bottom: 12px;
    }
    &__title-container {
      flex: 1;
    }
    &__label {
      font-size: $font-size-sm;
      color: color("muted");
    }
    &__title {
      font-weight: $font-weight-medium;
    }
    &__description {
      font-size: $font-size-sm;
      color: color("muted");
    }

    &__price {
      font-size: $font-size-sm;
      font-weight: $font-weight-medium;
      &--discount {
        margin-right: space(1);
        color: color("danger");
      }
      &--obsolete {
        color: color("muted-light");
        text-decoration: line-through;
      }
    }
  }
}
