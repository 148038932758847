.choose-dimensions {
  &__form {
    display: flex;
    justify-content: space-between;

    .form-stack {
      width: calc(33.333% - #{rem(4px)});
      margin-bottom: space(3);
    }

    @media (max-width: 600px) {
      flex-direction: column;
      align-items: center;

      .form-stack {
        width: calc(100% - #{rem(4px)});
        margin-bottom: space(3);
      }
    }
  }
  &__action-container {
    display: flex;
    .btn {
      flex: 1;
    }
  }
}
