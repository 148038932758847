.regnr-cta-box {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: space(3);
  background: color("blue-bg");
  border: $border-width solid color("border-light");
  border-radius: $border-radius;

  &__content {
    position: relative;
    top: -3px;
  }

  &__header {
    margin-bottom: space(1);
    font-size: $font-size-lg;
  }

  &__text {
    font-size: $font-size-sm;
    color: color("muted");
  }

  &__input {
    flex-shrink: 0;
    margin-left: space(6);
  }

  @include bp-down(sm) {
    display: block;

    &__input {
      margin-top: space(3);
      margin-left: 0;
    }
  }
}
