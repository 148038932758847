.vehicle-dynamic-block {
  padding: space(4);
  background: color("gray-bg");
  border: $border-width solid $border-color;
  border-radius: $border-radius;
  &__image {
    // background: transparent url('img/Nissan_CARED4_logo.png') 0% 0% no-repeat padding-box;
    max-height: 2rem;
    margin-bottom: space(3);
  }

  &__tags {
    margin-bottom: space(2);
  }

  &__title {
    margin-bottom: space(2);
    font-weight: $font-weight-medium;
  }

  &__sub-title {
    margin-bottom: space(3);
    font-size: $font-size-sm;
  }

  &__link {
    margin-top: space(3);
    font-size: $font-size-sm;
    font-weight: $font-weight-medium;
  }

  &__btn {
    margin-top: space(3);
  }

  &__price-container {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    margin-bottom: space(3);

    .product-main-action__pricing {
      margin-top: space(2);
    }
  }

  .product-usps {
    font-size: $font-size-sm;
  }

  &:not(:last-child) {
    margin-bottom: space(5);
  }
}
