.product-tag {
  padding: font-padding(rem(5px), rem(12px));
  margin-right: space(2);
  margin-bottom: 0.75rem;
  font-size: $font-size-xs;
  font-weight: 500;
  color: color("muted");
  text-align: center;
  background: $gray-400;
  border-radius: 20px;

  &.red {
    color: color("white");
    background: color("red");

    a {
      color: color("white");
    }
  }

  &.black {
    color: color("white");
    background: color("black");

    a {
      color: color("white");
    }
  }

  &.yellow {
    color: color("white");
    background: color("yellow");

    a {
      color: color("white");
    }
  }

  &.white {
    color: color("black");
    background: color("white");

    a {
      color: color("black");
    }
  }

  &.blue {
    color: color("white");
    background: color("blue");

    a {
      color: color("white");
    }
  }

  &.blueblue {
    color: color("blue");
    background: color("blue-light");

    a {
      color: color("blue");
    }
  }

  &.green {
    color: color("white");
    background: color("green");

    a {
      color: color("white");
    }
  }

  &.gold {
    color: color("black");
    background: color("gold");

    a {
      color: color("black");
    }
  }

  &--xs {
    font-size: $font-size-xxs;
  }

  a {
    color: color("blue");
  }

  svg {
    margin-right: space(1);
  }
}
