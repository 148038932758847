.service-agreement-block {
  display: block;
  max-width: 800px;
  margin: 1rem auto;

  .regnr-input {
    width: 100%;
    .input-group {
      max-width: 100%;
    }
  }

  .form-row {
    width: 100%;
  }

  .form-check {
    display: flex;
    align-items: center;
    justify-content: center;
    padding-left: 0;

    input {
      position: relative;
      margin-left: 0;
    }
  }
  .form-check--disabled {
    opacity: 0.5;
  }

  .ttjs {
    float: right;
    cursor: pointer;
  }

  .form-check__input:not(:disabled) {
    cursor: pointer;
  }

  .date-info {
    font-size: $font-size-xxs;
  }
}
