.overlay-header {
  @include border-bottom();
  padding: font-padding(space(3));

  &__text {
    font-weight: $font-weight-medium;
    text-align: center;
  }

  &__meta {
    font-size: $font-size-sm;
    color: color("muted-light");
  }

  &__back {
    position: absolute;
    top: rem(18px);
    left: space(3);
    cursor: pointer;

    svg {
      font-size: rem(20px);
    }
  }

  .circle-action {
    position: absolute;
    top: rem(13px);
    right: space(3);
  }
}
