.employee-page {
  padding-bottom: space(5);
  background-color: color("gray-bg");

  &__header {
    z-index: 1000;
    display: flex;
    align-items: center;
    width: 100%;
    height: 80px;
    background-color: color("white");

    &__content {
      display: flex;
      align-items: center;
      justify-content: space-between;

      &__actions {
        display: flex;
        align-items: center;

        @include bp-down(md) {
          display: none;
        }
      }
    }
  }

  &__details {
    display: flex;
    align-items: center;
    justify-content: center;
    padding-top: space(5);
    margin-bottom: space(5);
    &__image {
      width: 145px;
      height: auto;
      border-radius: 100%;
    }
    &__info {
      margin-left: space(5);
      &__name {
        font-size: $h2-sm-font-size;
      }
      &__title {
        font-size: $font-size-sm;
        color: color("muted-light");
      }
    }
  }

  &__contact-actions {
    display: flex;
    flex-direction: column;
    align-items: left;
    justify-content: flex-start;
    padding-top: space(3);

    &--mobile {
      @include bp-up(sm) {
        display: none;
      }
    }

    &--desktop {
      @include bp-down(xs) {
        display: none;
      }
    }

    &.btn {
      margin-bottom: space(2);
    }

    &__btn {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      font-size: $font-size-xs;
      color: color("muted-light");

      &:not(:last-child) {
        margin-right: space(5);
      }
      &__icon {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 40px;
        height: 40px;
        margin-bottom: space(2);
        font-size: 20px;
        color: $white;
        background-color: color("blue");
        border-radius: 40px;
        transition: background-color 0.2s ease-out;
      }

      @include bp-up(md) {
        &__icon:hover {
          background-color: $black;
        }
      }
    }
  }

  &__social-media-container {
    display: flex;
    justify-content: center;
    padding-top: space(5);
    border-top: $border-width solid $border-color;

    .employee-page__social-media {
      @include bp-down(md) {
        justify-content: space-around;
      }
    }
  }

  &__social-media {
    display: flex;
    justify-content: center;
    &__btn {
      display: flex;
      flex-direction: column;
      align-items: center;
      font-size: $font-size-xs;
      color: color("muted-light");
      cursor: pointer;
      user-select: none;
      transition: color 0.2s ease-out;
      &:not(:last-child) {
        margin-right: space(5);

        @include bp-down(md) {
          margin-right: 0;
        }
      }

      &__icon {
        margin-bottom: space(2);
        font-size: 24px;
      }
    }

    @include bp-down(md) {
      justify-content: space-between;
      width: 100%;
    }

    @include bp-up(md) {
      &__btn:hover {
        color: $black;
      }
    }
  }

  &__action-bar {
    position: fixed;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 1001;
    display: none;
    align-items: center;
    padding: space(3);
    background-color: $white;
    @include bp-down(md) {
      display: flex;
      justify-content: space-between;
      padding: 0;
    }

    &__btn {
      display: flex;
      align-items: center;
      width: 150px;
      padding: space(2);
      font-size: $font-size-sm;
      color: $white;
      cursor: pointer;
      user-select: none;
      background-color: color("blue");
      border-radius: $border-radius;
      transition: background-color 0.2s ease-out;
      @include bp-up(md) {
        &:not(:last-child) {
          margin-right: space(1);
        }
      }

      &__text {
        flex: 1;
        text-align: center;
      }

      &__icon {
        font-size: $font-size-lg;
        @include bp-down(md) {
          margin-top: space(1);
        }
      }

      @include bp-down(md) {
        flex-direction: column;
        width: calc(50% - 0.5px);
        height: 100%;
        border-radius: 0;
      }
    }

    @include bp-up(md) {
      &__btn:hover {
        background-color: $black;
      }
    }
  }

  &__modal-input {
    margin-top: space(3);
  }

  &__modal-qr {
    display: flex;
    justify-content: center;
    margin-top: space(5);

    img {
      width: 200px;
      height: 200px;
    }
  }
}
