.btn-list {
  .btn {
    margin-right: space(2);
    margin-bottom: space(2);
  }

  &--horizontal {
    @include bp-down(md) {
      overflow: auto;
      white-space: nowrap;
    }
  }
}
