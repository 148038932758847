.department-images {
  position: relative;
  display: flex;
  width: 100%;
  overflow: hidden;
  border-radius: rem(6px);

  @include bp-down(md) {
    width: 100vw;
    margin-right: rem(-16px);
    margin-left: rem(-16px);
    border-radius: 0;
  }

  &__item {
    @include bg-image(0);
    width: 100%;
    padding-bottom: aspect-ratio-percent(1.5, 1);
    background-repeat: no-repeat;
    background-size: cover;
  }

  &__counter {
    position: absolute;
    bottom: space(3);
    left: calc(50% - 36px);
    z-index: 100;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 72px;
    padding-top: space(2);
    padding-bottom: space(2);
    font-size: $font-size-xs;
    font-weight: $font-weight-medium;
    color: color("white");
    background-color: #18191abf;
    border-radius: $border-radius;
    box-shadow: 0px 1px 2px #0000000d;

    svg,
    i {
      margin-right: space(1);
    }
  }

  .swiper-container {
    display: flex;
    width: 100%;

    .swiper-wrapper {
      height: auto;
    }

    .swiper-slide {
      display: flex;
      height: auto;
    }
  }

  &__nav {
    position: absolute;
    top: calc(50% - 20px);
    z-index: 1;
    display: flex;
    align-items: center;
    justify-content: center;
    width: rem(48px);
    height: rem(48px);
    font-size: 20px;
    color: color("white");
    cursor: pointer;
    background-color: #18191abf;
    border-radius: rem(48px);
    transition: opacity $transition-base;

    @include bp-down(sm) {
      display: none;
    }

    &--prev {
      left: space(3);
    }

    &--next {
      right: space(3);
    }

    &--disabled {
      cursor: default;
      opacity: 0.3;
    }
  }
}
