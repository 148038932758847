.link-toggler {
  .link {
    .fa-angle-up {
      display: none;
    }
  }

  &__content {
    display: none;
  }

  &--active {
    .link {
      .fa-angle-up {
        display: inline-block;
      }

      .fa-angle-down {
        display: none;
      }
    }

    .link-toggler__content {
      display: block;
    }
  }

  &--sm {
    font-size: $font-size-sm;
  }
}

.expand-toggler {
  font-size: 14px;
  font-weight: 500;
  color: color("primary");
  text-transform: none;
  cursor: pointer;

  &:hover {
    text-decoration: underline;
  }

  &:active {
    text-decoration: none;
  }

  .fa-angle-down {
    display: inline-block;
    @include bp-up(md) {
      display: none;
    }
  }

  .fa-angle-up {
    display: none;
  }

  &--active {
    .fa-angle-up {
      display: inline-block;
      @include bp-up(md) {
        display: none;
      }
    }

    .fa-angle-down {
      display: none;
    }

    &.expand-toggler--contracted {
      .fa-angle-up {
        display: inline-block;
      }
    }
  }

  &--contracted:not(.expand-toggler--active) {
    .fa-angle-down {
      display: inline-block;
    }
  }
}
