.info-button {
  display: flex;
  align-items: center;
  justify-content: center;
  width: rem(18px);
  height: rem(18px);
  font-size: $font-size-xxs;
  font-weight: $font-weight-bold;
  color: color("white");
  cursor: pointer;
  user-select: none;
  background-color: color("primary");
  border-radius: 9px;
}
