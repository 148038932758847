.releasing-block {
  padding: 1rem 0 0 0;
  color: color("primary");
  text-align: center;

  .text_danger {
    color: color("red");
  }

  &__intro {
    max-width: 52.75rem;
    margin: auto;
    margin-top: 2rem;
  }

  &__body {
    display: flex;
    flex-direction: row;
    margin-top: 2rem;

    @include bp-down(sm) {
      flex-direction: column;
    }
  }

  &__section {
    display: flex;
    flex-direction: column;
    width: 100%;
    padding: 1rem;

    @include bp-down(sm) {
      padding: 1rem 0;
    }

    &-header {
      padding: 2rem 0;
      background-color: color("blue-bg");
      border: 1px solid color("primary");
      border-radius: $border-radius;
    }

    &-footer {
      padding: 1rem;
      margin-top: 2rem;
      text-align: left;

      table {
        margin-bottom: 3rem;
      }

      td {
        width: 35%;
      }

      td + td {
        text-align: right;
      }
    }
  }

  &__footer {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 3rem 0;
    margin-top: 2rem;

    &__body {
      width: auto;
      padding: 1rem;
    }
  }

  &__form {
    max-width: 30rem;
    margin: 1rem 0;
    text-align: left;
  }

  &__offer {
    font-size: 2.5rem;
  }

  .form-check__input[type="checkbox"] {
    @include bp-down(sm) {
      width: 40px;
    }
  }
}
