.product-description {
  &__header {
    margin-bottom: space(1);
    font-size: $h2-sm-font-size;
    font-weight: $font-weight-medium;
    @include bp-down(sm) {
      font-size: $h3-font-size;
    }
  }

  &__text {
    font-size: $font-size-base;
    font-weight: $font-weight-regular;
  }
}
