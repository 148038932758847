.vehicle-page {
  .section__content {
    padding-right: 0;
    padding-left: 0;
  }

  .container--sticky-panel {
    @include bp-up(lg) {
      padding-right: 42%;
    }
    @include bp-up(xl) {
      padding-right: 595px;
    }
  }
  .modal .container--sticky-panel {
    padding-right: 0;
  }

  &__main {
    display: flex;
  }

  &__side-bar {
    position: relative;
    display: flex;
    justify-content: flex-end;
    width: 42%;
    margin-left: 58%;

    @include bp-down(md) {
      display: none;
    }
  }

  &__image-container {
    @include bp-down(md) {
      padding: 0;
    }
  }

  .phy-ply-holmgrensbil .phy-overlay-ctrls {
    margin-bottom: 40px;
  }

  .product-images {
    &__item {
      position: relative;
      img,
      .phy-ply-holmgrensbil {
        // stylelint-disable declaration-no-important
        position: absolute !important;
        top: 0;
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }

    &__counter {
      display: flex;
      align-items: center;

      &__text {
        margin-left: space(1);
      }
    }
  }
  .vehicle-list__header {
    padding: space(3) 0 0;

    @include bp-down(sm) {
      display: none;
    }
  }
  &__column {
    position: relative;
    width: 60%;
    min-height: 1px;

    &--right {
      width: 40%;

      @include bp-down(md) {
        display: none;
      }
    }

    .container {
      padding-left: 0;
      @include bp-down(md) {
        padding-right: 0;
      }
    }

    @include bp-down(md) {
      flex: none;
      width: auto;
      width: 100%;
      padding-right: 0;
    }

    &__blocks {
      padding: 0 space(8);
    }
  }

  &__list {
    padding-left: 0;
    list-style: none;
    @include bp-up(md) {
      column-count: 2;
      column-gap: rem(58px);
    }

    &__line {
      display: inline-flex;
      width: 100%;
      padding: font-padding(rem(12px), 0);
      font-size: $font-size-sm;
      border-bottom: $border-width solid gray("300");
    }

    &__title {
      flex: 0 0 55%;
      font-weight: $font-weight-medium;
      color: color("primary");
    }

    &__item {
      flex: 0 0 45%;
      text-align: right;
    }
  }

  &__content {
    display: flex;
    flex-direction: column;

    @include bp-down(md) {
      flex-direction: column;
    }

    @include bp-down(sm) {
      padding: 0 space(3);

      .section__content {
        padding-right: 0;
        padding-left: 0;
      }
    }

    .product-meta {
      margin-top: space(3);
    }

    .product-description {
      margin-top: space(4);
      @include bp-down(md) {
        margin-top: space(2);
      }
    }

    .product-usps {
      margin-bottom: space(3);
    }

    .product-main__pricing-text {
      top: -(space(2));
    }

    .product-main__footer {
      position: relative;
      padding: space(3) 0 space(3) 0;
      margin: 0;

      & .section__content {
        padding: 0;
      }

      @include bp-down(md) {
        border: 0;
      }
    }
  }

  .product-column {
    display: flex;
    flex: 0 0 33.333333%;
    align-items: flex-start;
    margin-bottom: space(2);

    @include bp-down(sm) {
      flex: 0 0 50%;
    }
  }

  &__info {
    padding-top: space(3);
    padding-bottom: space(3);
    border-top: $border-width solid $border-color;
  }

  &__employee-list {
    display: flex;
    flex-wrap: wrap;
    &__item {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      min-width: 220px;
      padding: space(5) 0;
      margin-bottom: space(5);
      border: $border-width solid $border-color;
      border-radius: $border-radius;

      &:not(:last-child) {
        margin-right: space(5);
      }

      &__image {
        width: 80px;
        height: 80px;
        margin-bottom: space(3);
        border-radius: 40px;
      }

      &__text-container {
        display: flex;
        flex-direction: column;
        align-items: center;
      }

      &__name {
        margin-bottom: space(2);
        font-weight: $font-weight-medium;
      }

      &__title {
        margin-bottom: space(3);
        color: color("muted-light");
      }

      &__link {
        font-weight: $font-weight-medium;
      }

      &__separated-links {
        display: none;
        margin-left: auto;
        &__item {
          display: flex;
          align-items: center;
          justify-content: center;
          width: 40px;
          height: 40px;
          margin-left: space(2);
          color: color("primary");
          cursor: pointer;
          border: $border-width solid $border-color;
          border-radius: $border-radius;
        }
      }

      @include bp-down(sm) {
        flex-direction: row;
        justify-content: flex-start;
        width: 100%;
        max-width: none;
        padding: space(3) 0;
        margin-top: space(3);
        margin-right: 0;
        border: 0;
        border-bottom: $border-width solid $border-color;
        border-radius: 0;

        &:not(:last-child) {
          margin-right: 0;
        }

        &__image {
          width: 40px;
          height: 40px;
          margin-right: space(3);
          margin-bottom: 0;
          border-radius: 20px;
        }

        &__text-container {
          display: flex;
          flex-direction: column;
          align-items: flex-start;
        }

        &__name {
          margin-bottom: space(1);
          font-size: $font-size-sm;
          font-weight: $font-weight-medium;
        }
        &__title {
          margin-bottom: 0;
          font-size: $font-size-xs;
        }

        &__link {
          display: none;
        }

        &__separated-links {
          display: flex;
        }
      }
    }
  }

  .vehicle-print {
    display: flex;
    align-items: center;
    margin-left: space(3);
    font-size: $font-size-sm;
    font-weight: $font-weight-medium;
    color: color("primary");
    a {
      margin-left: space(1);
    }
  }

  .floating-action-btn--contact {
    bottom: 8rem;
  }

  .site-footer {
    padding-bottom: rem(104.5px);
  }
}
