.site-usps {
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  z-index: $zindex-site-usps;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: $site-alerts-height;
  overflow: hidden;
  background: color("primary");

  &__item {
    margin: 0 space(3);

    svg {
      position: relative;
      top: -1px;
      margin-right: rem(2px);
      font-size: rem(12px);
    }

    p {
      display: inline-block;
      font-size: $font-size-xs;
      font-weight: $font-weight-medium;
    }
  }

  &--slider {
    .site-usps__item {
      position: absolute;
      width: 100%;
      margin: 0;
      text-align: center;
      opacity: 0;
      transition: transform $transition-base, opacity linear 0s 0.22s;
      transform: translateX(100%);

      &.site-usps__item--active {
        opacity: 1;
        transition: transform $transition-base, opacity linear 0s 0s;
        transform: none;
      }

      &.site-usps__item--out {
        display: block;
        width: 100%;
        transform: translateX(-100%);
      }
    }
  }
}
