.overlay-open {
  overflow: hidden;

  @include bp-up(md) {
    //padding-right: 15px; // compensate scrollbar
  }


  @include bp-down(sm) {
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
  }
}

.overlay {
  position: fixed;
  top: 0;
  left: 100%;
  z-index: $zindex-overlay;
  width: 100%;
  overflow-y: auto;
  -webkit-overflow-scrolling: touch;
  background: $white;
  opacity: 0;
  transition: transform $transition-base, opacity $transition-base, height $transition-base, left 0s linear 0.22s;
  transform: translateY(-3rem);

  @include bp-up(md) {
    box-shadow: $box-shadow;
  }

  &--active {
    left: 0;
    z-index: 1010;
    opacity: 1;
    transition: transform $transition-base, opacity $transition-base, height $transition-base;
    transform: none;
  }

  &--aside {
    left: 100%;
    z-index: $zindex-overlay-aside;
    width: 400px;
    height: 100%;
    transform: translateX(6rem);

    .overlay__content {
      height: 100%;
    }

    &.overlay--active {
      left: calc(100% - 400px);
      transform: none;
    }

    @include bp-down(sm) {
      width: 100%;
      opacity: 1;
      transform: translateX(100%);

      &.overlay--active {
        left: 0;
        transform: none;
      }
    }
  }

  &:not(.overlay--aside) {
    &.overlay--replace-transition {
      overflow: hidden;
    }

    .overlay__content {
      @include make-container();
      position: relative;
      padding: space(6);
      transition: all $transition-base;

      &--forward,
      &--backward {
        position: absolute;
        top: 0;
        width: 100%;
        opacity: 0;
      }

      &--forward {
        transform: translateX(3rem);
      }

      &--backward {
        transform: translateX(-3rem);
      }
    }
  }
}

.site-overlay {
  position: fixed;
  top: 0;
  right: 0;
  bottom: -1px;
  left: 0;
  z-index: $zindex-site-header - 1;
  display: none;
  background: rgba(0, 0, 0, 0.5);

  &--visible {
    display: block;
  }
}
