.department-main-action {
  &__container {
    padding: space(5) space(4) space(3);
    background: color("white");
    border: $border-width solid $gray-400;
    border-radius: 4px;

    @include bp-down(md) {
      padding: space(4) space(3) space(3);
      border-right: 0;
      border-left: 0;
      border-radius: 0;
    }
  }

  &__loader {
    display: flex;
    justify-content: center;
    margin-bottom: space(3);
    svg {
      font-size: 48px;
    }
  }

  &__error {
    display: flex;
    justify-content: center;
    margin-bottom: space(3);
    font-weight: $font-weight-medium;
    text-align: center;
  }

  &__title {
    display: flex;
    margin-bottom: rem(12px);
    font-weight: $font-weight-medium;

    @include bp-down(md) {
      margin-bottom: space(2);
    }
  }

  &__select {
    margin-bottom: space(3);
  }

  &__button-container {
    padding-bottom: rem(20px);
    .btn {
      margin-bottom: space(2);

      svg {
        margin-right: space(1);
      }

      &:last-child {
        margin-bottom: 0;
      }

      @include bp-down(md) {
        padding-top: rem(14px);
        padding-bottom: rem(12px);
        font-size: $font-size-xs;

        .call-action__number {
          font-size: $font-size-xs;

          span {
            font-size: $font-size-xs;
          }
        }
      }
    }
  }

  &__modal-opening-hours {
    position: relative;
    padding-top: space(4);
    padding-left: space(5);
    border-top: $border-width solid $gray-400;

    svg {
      position: absolute;
      top: 24px;
      left: 0;
    }

    .department-info-row__opening-hours__item {
      display: flex;
      justify-content: space-between;
    }
  }

  @include bp-down(md) {
    &__select {
      .configurator-select__input__value {
        font-size: $font-size-xs;
      }
      .configurator-select__input {
        padding-top: rem(11px);
        padding-bottom: rem(11px);
      }

      .configurator-select__dropdown {
        top: rem(50px);
      }
    }
  }
}
