.configurator-checkbox {
  flex: 1;
  &__container {
    position: relative;
    display: flex;
    align-items: center;
    padding-left: rem(40px);
    margin-bottom: 0;
    overflow: hidden;
    text-transform: none;
    cursor: pointer;
    user-select: none;
    background: $white;
    border: $border-color solid $border-width;
    border-radius: rem(6px);
    box-shadow: 0 1px 2px #0000000d;

    &--checked {
      border-left: 3px solid color("primary");

      .configurator-checkbox__image-container {
        left: -2px;
      }

      .configurator-checkbox__checkmark {
        left: 10px;
      }
    }
  }

  &__title {
    display: flex;
    flex: 1;
    justify-content: space-between;
    margin-right: space(3);
  }

  &__image-container {
    position: absolute;
    top: 0px;
    right: 0px;
    bottom: 0px;
    left: 0px;
    background-repeat: no-repeat;
    background-size: cover;
    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }

  &__tag-container {
    position: absolute;
    bottom: 0;

    .product-tag {
      display: inline-flex;
      align-items: center;
      margin-bottom: rem(12px);
      margin-left: rem(12px);

      @include bp-down(md) {
        font-size: $font-size-xxs;
      }
    }
  }

  &__input {
    position: absolute;
    width: 0;
    height: 0;
    cursor: pointer;
    opacity: 0;
  }

  &__checkmark {
    position: absolute;
    left: 12px;
    width: 16px;
    height: 16px;
    background-color: $white;
    border: $border-color solid $border-width;
    border-radius: 4px;
  }

  &__container &__checkmark::after {
    top: 1px;
    left: 5px;
    width: 5px;
    height: 9px;
    border: solid $black;
    border-width: 0 1.5px 1.5px 0;
    transform: rotate(45deg);
  }


  &__container:hover input ~ &__checkmark {
    background-color: #ccc;
  }

  &__container input:checked ~ &__checkmark {
    border-color: color("primary");
  }

  &__checkmark::after {
    position: absolute;
    display: none;
    content: "";
  }

  &__container input:checked ~ &__checkmark::after {
    display: block;
  }

  &:not(:last-child) &__container {
    margin-bottom: rem(12px);
  }
}
