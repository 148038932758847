.quickbuy {
  display: flex;
  width: 100%;
  border: 4px solid #e0e8f5;
  border-radius: 4px;

  @include bp-down(md) {
    display: block;
  }

  &__aside {
    display: flex;
    flex: 0 0 auto;
    width: 645px;
    overflow: hidden;
    border-top-left-radius: $border-radius;
    border-bottom-left-radius: $border-radius;
    @include bp-down(md) {
      width: 100%;
      border-radius: 0;
    }
  }

  &__main {
    position: relative;
    display: flex;
    flex-direction: column;
    width: 100%;
    padding: space(5);
    overflow: hidden;
    background: $white;
    border-top-right-radius: $border-radius;
    border-bottom-right-radius: $border-radius;

    @include bp-down(md) {
      display: block;
      padding-right: space(3);
      padding-left: space(3);
      border: 0;
      border-radius: 0;
    }

    @include bp-down(sm) {
      padding-top: space(3);
      padding-bottom: space(1);
    }
  }

  &__main-content {
    display: flex;
    flex-direction: column;
    width: 100%;

    @include bp-down(md) {
      margin-right: 0;
    }
  }

  &__main-aside {
    display: flex;
    flex-direction: column;
    flex-shrink: 0;
    align-items: flex-end;
    justify-content: space-between;

    @include bp-down(sm) {
      flex-direction: column;
      width: 100%;
    }
  }

  &__pricing {
    width: 100%;

    .product-spot__price {
      flex-wrap: nowrap;
      justify-content: flex-start;

      @include bp-down(md) {
        flex-wrap: wrap;
      }
    }

    .product-spot__price-item {
      flex-basis: 25%;
      margin-top: space(2);
      margin-bottom: space(1);

      @include bp-down(md) {
        flex-basis: 50%;
      }

      &.offset-3 {
        margin-left: space(2);

        @include bp-down(md) {
          margin-left: 0;
        }
      }

      &.offset-4 {
        margin-left: space(3);

        @include bp-down(md) {
          margin-left: 0;
        }
      }
    }

    &__option {
      font-size: $font-size-xs;
      color: color("muted-light");

      &--attention {
        color: color("attention");
      }

      @include bp-down(md) {
        margin-bottom: 0;
      }
    }
  }

  &__actions-container {
    width: 100%;
  }

  &__actions {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-top: space(2);

    a {
      flex: 1;
    }

    .offset {
      margin-right: space(3);
    }

    @include bp-down(lg) {
      flex-direction: column-reverse;

      .offset {
        margin-top: space(3);
        margin-right: 0;
      }
    }

    @include bp-down(sm) {
      width: 100%;

      .offset {
        margin-top: space(2);
      }
    }
  }

  .ribbon {
    position: absolute;
    top: -$ribbon-arrow-right-size;
    left: space(4);

    @include bp-down(md) {
      left: space(3);
      z-index: 2;
    }
  }

  .product-detail-modals-container {
    display: flex;
    justify-content: space-between;
    margin-top: space(1);
    margin-bottom: space(1);
  }

  .product-meta {
    display: flex;
    justify-content: space-between;
    margin-bottom: space(2);

    &-actions {
      display: flex;
      justify-content: right;
    }

    .bookmark-top {
      display: flex;
      align-items: center;
      margin-left: space(3);
      font-weight: $font-weight-medium;
      color: color("blue");
      &__link {
        display: flex;
        align-items: center;
        font-size: $font-size-xs;
        white-space: nowrap;
        &--selected {
          color: gray("900");
        }
      }

      &__icon {
        margin-right: space(1);
        font-size: $font-size-sm;

        &--selected {
          color: color("blue");
        }
      }

      @include bp-down(sm) {
        display: none;
      }
    }
  }

  .product-tag {
    display: flex;
    align-items: center;
    width: auto;
    height: rem(30px);
    padding: 0 space(3);
    margin-right: space(2);
  }

  .product-facts {
    //padding-top: space(3);
    //padding-bottom: rem(6px);

    padding: rem(12px) 0 rem(8px);
    border-top: $border-width solid $border-color;
    border-bottom: $border-width solid $border-color;

    @include bp-up(xl) {
      justify-content: flex-start;
    }
  }

  &__bookmarks {
    display: flex;
    width: 100%;
    @include bp-up(md) {
      display: none;
    }
  }

  .bookmark-bottom {
    display: flex;
    flex: 1;
    align-items: center;
    justify-content: center;
    padding-top: space(3);
    padding-bottom: space(3);
    color: color("blue");
    cursor: pointer;
    &__link {
      font-size: $font-size-sm;
      font-weight: $font-weight-medium;
      color: color("blue");
      &--selected {
        color: gray("900");
      }
    }

    &__icon {
      margin-right: space(1);

      &--selected {
        color: color("blue");
      }
    }
  }

  .ttjs-tooltip {
    font-size: $font-size-sm;
    font-weight: $font-weight-medium;
    background: $white;
  }

  .quickbuy_gallery {
    display: block;
  }
}
