.my-vehicles {

  &__add {
    position: relative;
    display: block;
    padding: space(3) 0;
    margin: space(4) 0 space(4);
    font-weight: $font-weight-medium;
    color: color("primary");
    cursor: pointer;
    border-top: 1px solid color("border");
    border-bottom: 1px solid color("border");

    i {
      margin-right: space(4);
    }
    .fa-chevron-right {
      position: absolute;
      top: 1.3rem;
      right: 1rem;
      margin-right: space(2);
    }
  }

  ul {
    margin-top: space(3);
    font-size: $font-size-sm;
  }
}

.add-my-vehicle {

  h4 {
    margin-top: space(3);
    margin-bottom: 0;
  }

  .regnr-input {
    margin-top: space(3);
  }

  .btn {
    display: block;
    margin-top: space(3);
  }

  .feedback-message {
    margin-bottom: space(3);
  }
}
