@mixin product-facts-sm() {
  .product-facts__item {
    svg {
      margin-bottom: space(1);
      font-size: rem(18px);
    }

    &:not(:last-child) {
      margin-right: space(2);

      @include bp-down(sm) {
        margin-right: space(3);
      }

      @include bp-up(xl) {
        margin-right: space(4);
      }
    }
  }
}

.product-facts {
  display: flex;
  flex-wrap: wrap;
  justify-content: left;
  padding: space(2) 0;
  overflow: hidden;

  @include bp-down(sm) {
    justify-content: left;
    overflow-x: auto;
  }

  &__item-wrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 5rem;
    height: 5rem;
    margin-right: 0.5rem;
    margin-bottom: 0.5rem;
    cursor: pointer;
    background: #fff;
    border-radius: 0.25rem;
  }
  &__item {
    font-size: $font-size-xs;
    font-weight: $font-weight-medium;
    text-align: center;

    @include bp-down(sm) {
      margin: space(1) 0;
    }

    > div {
      white-space: nowrap;
    }

    svg {
      margin-bottom: space(1);
      font-size: rem(23px);
    }

    .bordered {
      max-width: 30px;
      padding: 0 2px;
      margin: auto;
      border: 1px solid $body-color;
      border-radius: 2px;
    }

    .meta-tag {
      margin-bottom: space(1);
      margin-left: 0;
      font-weight: 400;
      text-transform: uppercase;
    }

    &:not(:last-child) {
      margin-right: space(6);
    }
  }

  @include bp-down(sm) {
    @include product-facts-sm();
  }

  &--sm {
    @include product-facts-sm();
  }
}
