.page-header {
  @include border-bottom();
  padding: space(5) 0;

  @include bp-down(sm) {
    padding: space(4) 0;
  }

  &--filter {
    padding: 0;
    border: 0;
  }

  &__content {
    display: flex;
    flex-direction: column;
    justify-content: center;

    .campaign-rollup & {
      flex-direction: row;
    }

    &--center {
      align-items: center;
    }

    &--padding-sm-b {
      @include bp-down(sm) {
        padding-bottom: space(3);
      }
    }

    @include bp-down(sm) {
      display: block;

      > .eon-filter {
        padding-right: 0;
        padding-left: 0;
      }
    }
  }

  &__title {
    @include make-h2();
    margin-bottom: rem(6px);

    &--sm {
      @include make-h2-sm();
    }

    &:last-child {
      margin-bottom: 0;
    }

    .sub-heading {
      font-size: rem(16px);
    }
  }

  &__tag-line {
    margin-bottom: 1rem;
    font-size: 1rem;
    font-weight: 400;
  }

  &__description {
    margin-right: space(5);

    p {
      color: color("muted");
    }

    p,
    ul,
    ol {
      a:not(.btn) {
        @extend .link;
        @extend .link--primary;
      }
    }

    + .page-header__controls {
      margin-top: space(3);
    }
  }

  &__progress {
    display: flex;
    align-items: center;
    @include bp-up(md) {
      min-width: rem(500px);
      margin-right: space(5);
    }
    @include bp-down(sm) {
      padding-bottom: space(3);
      margin-bottom: space(3);
      border-bottom: $border-width solid $border-color;
    }

    small {
      @include bp-down(sm) {
        font-size: $font-size-xs;
      }
    }

    .progress-bar {
      width: 65%;
      margin-right: space(2);
      margin-bottom: 0;
      border: 1px solid rgba(0, 0, 0, 0.1);
      @include bp-down(sm) {
        width: 50%;
        height: rem(13px);
      }
    }
  }

  &__controls {
    display: flex;
    justify-content: flex-end;
    margin-left: auto;

    @include bp-down(sm) {
      margin-bottom: -1px;

      &:last-child {
        margin-bottom: calc(-1px - #{space(5)});
      }
    }
  }

  &__control {
    flex: 1 1 auto;
    min-width: rem(226px);

    &:not(:last-child) {
      margin-right: rem(40px);
    }

    + .page-header__control {
      margin-left: -1px;
    }

    &--mobile-only {
      display: none;

      @include bp-down(sm) {
        display: block;
      }
    }

    .toggle-switch-button {
      max-width: rem(180px);
      padding: space(3);
      margin-left: auto;
      border: none;

      @include bp-down(sm) {
        position: absolute;
        right: 0;
        width: 158px;
        margin-top: -38px;
      }

      span {
        color: color("black");
        @include bp-down(sm) {
          font-size: $font-size-xs;
        }
      }
    }
    .form-stack {
      position: relative;
      cursor: pointer;

      i,
      svg {
        position: absolute;
        top: 1.5rem;
        right: space(3);
        font-size: rem(24px);
      }

      .form-control {
        font-weight: $font-weight-medium;
        color: color("primary");
      }
    }

    &--filter {
      .badge {
        position: relative;
        top: -2px;
        right: -12px;
        flex-shrink: 0;
        margin-right: rem(6px);
        margin-left: auto;

        @include bp-down(sm) {
          margin-right: 0;
        }
      }

      .btn {
        display: flex;
        align-items: center;
        height: $input-height - rem(2px);
        font-size: $font-size-sm;

        @include bp-down(sm) {
          padding-left: space(3);
          font-size: $font-size-base;

          &.btn--icon svg {
            right: space(3);
          }
        }
      }
    }

    &--sort {
      .form-select {
        &::before {
          right: space(4);
        }
      }

      .form-control {
        padding-right: space(4);
        padding-left: space(4);
        font-size: $font-size-sm;
        font-weight: $font-weight-medium;
        color: color("primary");

        @include bp-down(sm) {
          padding-right: space(3);
          padding-left: space(3);
          font-size: $font-size-base;

          &::before {
            right: space(3);
          }
        }
      }
    }

    @include bp-down(sm) {
      width: 50%;
      min-width: 0;

      &:not(:last-child) {
        margin-right: 0;
      }

      &:first-child {
        margin-left: -$grid-gutter-width-sm;
      }

      &:last-child {
        margin-right: -$grid-gutter-width-sm;
      }

      .toggle-switch-button,
      .btn,
      .form-control {
        border-radius: 0;
      }
    }
  }

  &--slim {
    padding-bottom: space(3);
    border-bottom: 0;
    box-shadow: none;

    + .vehicle-list {
      padding-top: 0;
    }

    .page-header__description > div {
      margin-bottom: 0;
    }
  }

  &--sm {
    padding: space(2) 0;
  }

  &--extra-slim {
    padding-top: space(2);
    padding-bottom: space(1);
    border-bottom: 0;
    box-shadow: none;

    + .vehicle-list {
      padding-top: 0;
    }

    .page-header__description > div {
      margin-bottom: 0;
    }
  }

  &--white {
    background-color: color("white");
  }

  &--gray {
    background-color: color("gray-bg");
    border-bottom: none;
    .page-header__content {
      border-bottom: $border-width solid $border-color;
    }
  }

  &--push-down {
    @include bp-up(lg) {
      margin-bottom: space(5);
    }
  }

  &--image-bg {
    p,
    ul,
    ol {
      a:not(.btn) {
        color: color("white");
      }
    }
    h1,
    p,
    li,
    a {
      color: color("white");
    }
  }

  &__price {
    &__finance {
      margin-top: space(2);
      font-size: $h2-sm-font-size;
      font-weight: $font-weight-medium;
    }
  }

  &--container {
    max-width: rem(1370px);
    padding-right: space(6);
    padding-left: space(6);
    margin: 0 auto;

    @include bp-down(sm) {
      padding-right: 0;
      padding-left: 0;
    }
  }
}
