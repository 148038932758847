.campaign-list {
  .vehicle-list__header {
    padding-top: space(4);
    margin-bottom: 0;
  }

  .swiper-pagination-bullets {
    bottom: 0;

    @include bp-up(md) {
      display: none;
    }
  }

  .swiper-pagination-bullet {
    background: gray("500");
    opacity: 1;
  }

  .swiper-pagination-bullet-active {
    background: color("primary");
  }

  &__items {
    @include make-row();
    padding: space(6) 0;

    &--no-padding {
      padding: 0;
    }
  }

  &__item {
    > div {
      height: 100%;
      border-bottom-right-radius: $border-radius;
      border-bottom-left-radius: $border-radius;
      box-shadow: $box-shadow-sm;
      transition: box-shadow $transition-bezier;
    }

    @include make-col-ready();
    margin-bottom: space(6);

    @include make-col(4);

    @include bp-down(md) {
      @include make-col(6);
    }

    @include bp-down(sm) {
      @include make-col(12);
      margin-bottom: space(3);
    }

    &:nth-child(n + 8) {
      .campaign-list__item__image {
        order: 10;
      }
    }

    &--large {
      > a {
        box-shadow: 0 1px 5px rgba(0, 0, 0, 0.1);
      }
    }

    @include bp-up(lg) {
      &--large {
        @include make-col(12);

        a {
          display: flex;
          .campaign-list__item__image {
            width: 69%;
            height: auto;
            border-top-left-radius: $border-radius;
            border-bottom-left-radius: $border-radius;
          }

          .campaign-list__item__logo {
            position: relative;
            top: 0;
            margin-bottom: space(3);
          }
          .campaign-list__item__content {
            width: 31%;
            min-height: rem(450px);
            padding: space(4);
          }
        }
      }
    }

    &__image {
      @include bg-image(0);
      height: rem(247px);

      @include bp-down(sm) {
        height: rem(162px);
      }

      img {
        border-top-left-radius: $border-radius;
        border-top-right-radius: $border-radius;
      }

      .sticker {
        position: absolute;
        top: 0;
        right: 0;
        z-index: 10;
        margin: space(3);
      }
    }

    &:hover {
      > div {
        box-shadow: 0 1px 10px rgba(0, 0, 0, 0.1);
      }
    }

    &__logo {
      position: absolute;
      top: -(rem(34px));
      display: flex;
      align-items: center;
      justify-content: center;
      width: rem(68px);
      height: rem(68px);
      padding: space(2);
      background: $white;
      border-radius: 12px;
      box-shadow: 0 4px 12px rgba(0, 0, 0, 0.08);

      @include bp-down(sm) {
        top: -(rem(30px));
        width: rem(60px);
        height: rem(60px);
      }
    }

    &__meta {
      margin-bottom: space(1);
      font-size: $font-size-xs;
      font-weight: $font-weight-medium;
      text-transform: uppercase;
      letter-spacing: 0.5px;
    }

    &__content {
      position: relative;
      padding: rem(50px) space(4) space(4);
      background: $white;
      border-bottom-right-radius: $border-radius;
      border-bottom-left-radius: $border-radius;

      @include bp-up(md) {
        display: flex;
        flex-direction: column;
        height: calc(100% - 247px);
      }

      @include bp-down(sm) {
        padding: rem(39px) space(3) space(3);
      }

      p {
        font-size: $font-size-sm;
      }
    }

    &__text {
      margin-bottom: space(3);
    }

    .btn {
      margin-top: auto;
      background: $gray-400;
      border-color: $gray-400;

      &:hover {
        background: $gray-500;
        border-color: $gray-500;
      }
    }
  }
}

.campaigngridblock {
  .campaign-list {
    &__items {
      @include bp-down(md) {
        flex-wrap: nowrap;
        padding-bottom: rem(32px);
      }
    }
  }
}
