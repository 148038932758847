.accordion {

  &.red {
    .accordion-trigger[aria-expanded="true"],
    .accordion-trigger:focus,
    .accordion-trigger:hover {
      color: color("white");
      background-color: color("red");
    }
  }

  &.black {
    .accordion-trigger[aria-expanded="true"],
    .accordion-trigger:focus,
    .accordion-trigger:hover {
      color: color("white");
      background-color: color("black");
    }
  }

  &.yellow {
    .accordion-trigger[aria-expanded="true"],
    .accordion-trigger:focus,
    .accordion-trigger:hover {
      color: color("white");
      background-color: color("yellow");
    }
  }

  &.white {
    .accordion-trigger[aria-expanded="true"],
    .accordion-trigger:focus,
    .accordion-trigger:hover {
      color: color("black");
      background-color: color("white");
    }
  }

  &.blue {
    .accordion-trigger[aria-expanded="true"],
    .accordion-trigger:focus,
    .accordion-trigger:hover {
      color: color("white");
      background-color: color("blue");
    }
  }

  &.green {
    .accordion-trigger[aria-expanded="true"],
    .accordion-trigger:focus,
    .accordion-trigger:hover {
      color: color("white");
      background-color: color("green");
    }
  }

  &.gold {
    .accordion-trigger[aria-expanded="true"],
    .accordion-trigger:focus,
    .accordion-trigger:hover {
      color: color("white");
      background-color: color("gold");
    }
  }

  &.focus {
    background-color: color("gray-bg");

    h3 {
      background-color: color("gray-bg");
    }
  }

  &-trigger {
    &:focus,
    &:hover {
      background-color: color("gray-bg");
    }
  }
}
