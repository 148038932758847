.nav-tree {
  width: 100%;
  ul {
    @include list-unstyled();
  }

  &__header {
    @include make-container();
    position: relative;
    padding: rem(20px) space(3) rem(18px);
    cursor: pointer;
    background: color("gray-bg");
    border-bottom: $border-width solid $border-alt-color;

    span {
      font-size: $font-size-xs;
      font-weight: $font-weight-bold;
      text-transform: uppercase;
    }

    &--sm {
      padding: rem(15px) space(3) rem(13px);
    }

    svg {
      position: absolute;
      top: space(3) + rem(4px);
      right: space(3);
      font-size: rem(24px);
    }

    &.nav-tree__header--active {
      svg {
        transform: rotate(180deg);
      }
    }
  }

  &__children {
    display: none;
    padding: 0 0 0 space(3);

    .nav-tree__item {
      display: block;
    }
    ul {
      padding: 0 0 0 space(3);

      .nav-tree__item > a {
        padding-left: 0;
      }
    }
  }

  .list-label,
  .btn-list {
    display: none;
    &.shown {
      display: block;
    }
  }
  &__item {
    position: relative;
    display: none;
    cursor: pointer;
    border-bottom: $border-width solid $border-alt-color;

    .site-footer & {
      display: block;
    }

    &.shown {
      display: block;
    }

    > a {
      display: block;
    }

    > a,
    > div {
      padding: space(4) space(3);
    }

    &--parent {
      svg {
        position: absolute;
        top: space(3) + rem(8px);
        right: space(3);
        font-size: rem(24px);
        color: color("primary");
      }

      &.nav-tree__item--active {
        svg {
          transform: rotate(180deg);
        }
      }
    }

    &--active {
      > div {
        font-weight: $font-weight-medium;
      }

      + .nav-tree__children {
        display: block;
      }
    }
  }

  &__child-meta {
    margin-left: rem(2px);
    font-size: $font-size-xs;
    color: color("muted-light");
  }

  .list-label {
    margin-top: space(3);
    margin-left: space(3);
  }

  .btn-list {
    margin: space(3) 0 space(2) space(3);
  }
}
