.shaded-expand {
  margin-bottom: space(3);
  &__header {
    @include make-h4();
    margin-bottom: 12px;
    font-weight: 500;
  }

  &__content {
    position: relative;
    height: 0;
    margin-bottom: 12px;
    overflow: hidden;
    &--hint {
      height: 140px;
    }
  }

  &__btn {
    font-size: 14px;
    font-weight: 500;
    color: #004a61;

    &--expanded {
      display: none;
    }
  }

  .list-styled + & {
    margin-top: 0;
  }

  .link-toggler--active {
    .shaded-expand__content {
      height: auto;
    }

    .shaded-expand__cover {
      display: none;
    }

    .shaded-expand__btn--expanded {
      display: inline;
    }

    .shaded-expand__btn--expand {
      display: none;
    }
  }
}
