.date-wizard {

  &__label-container {
    margin: 0 0 space(3) 0;

    &__label {
      font-family: $font-family-base;
      font-size: $font-size-xs;
      color: gray("700");
      text-align: left;
      text-transform: uppercase;
      letter-spacing: 0.6px;
      opacity: 1;
    }
  }
  .time-container {
    display: flex;
    flex-wrap: wrap;

    &__cell {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      min-width: rem(60px);
      height: rem(30px);
      padding: 0 space(2);
      margin-right: space(2);
      margin-bottom: space(2);
      cursor: pointer;
      background: color("white");
      border: $border-width solid gray("500");
      border-radius: $border-radius;
      box-shadow: $box-shadow-border-bottom;
      opacity: 1;
    }

    &--selected {
      font-weight: $font-weight-medium;
      color: color("white");
      background: color("primary");

      & .time-container__cell-text {
        color: color("white");
      }
    }

    &__cell-text {
      font-family: $font-family-base;
      font-size: $font-size-sm;
      color: gray("900");
      text-align: center;
      letter-spacing: 0;
      user-select: none;

      &__separator {
        line-height: 0.5;
      }
    }
  }

}
