$product-colors: (
  white:   #f8f8f9,
  black:   #30333d,
  silver:  #d1d4d3,
  brown:   #62584a,
  red:     #f06449,
  yellow:  #f1d331,
  green:   #296417,
  blue:    #1c2965
);

@mixin product-color-gradient($color) {
  background: linear-gradient(180deg, $color 0%, lighten($color, 8%) 35%, lighten($color, 8%) 50%, $color 75%, darken($color, 8%) 100%);
}

.product-color {
  display: inline-block;
  margin-right: rem(10px);
  margin-bottom: space(3);
  font-size: $font-size-xs;
  color: color("primary");
  text-align: center;
  cursor: pointer;

  &:hover:not(.product-color--selected) {
    .product-color__gradient {
      border: 2px solid color("primary");
    }
  }

  &--selected {
    .product-color__gradient {
      border: 2px solid color("primary");
    }
  }

  &__gradient {
    display: block;
    width: rem(52px);
    height: rem(52px);
    margin: 0 auto;
    border-radius: $border-radius;
    transition: border-color $transition-base;

    &--multi-colored {
      background: linear-gradient(180deg, color("blue"), color("green"), color("yellow"));
    }

    @each $color, $value in $product-colors {
      &--#{$color} {
        @include product-color-gradient($value);
      }
    }
  }

  &__name {
    margin-top: space(2);
  }
}
