.reviews {

  .swiper-container {
    padding-bottom: space(4);

    @include bp-down("sm") {
      width: calc(100% + 2rem);
      margin: space(4) -1rem 0;
    }
  }

  .swiper-pagination-bullet-active {
    background: color("yellow");
  }

  &__item {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: space(3);

    &__bubble {
      position: relative;
      z-index: 0;
      width: 100%;
      padding: space(4);
      margin-bottom: space(4);
      background-color: color("white");
      border-radius: rem(5px);
      box-shadow: 0 0 space(1) rgba(0, 0, 0, 0.1);
    }

    &__text {
      margin-bottom: space(1);
      font-weight: $font-weight-bold;
    }

    &__meta {
      font-size: $font-size-sm;

      a {
        color: color("muted");
        text-decoration: underline;
      }
    }
  }

  &__stars {
    margin-bottom: space(2);

    i,
    svg {
      font-size: $font-size-sm;
      color: color("yellow");
    }
  }
}


.col-md-12 {

  @include bp-up("md") {
    .reviews {
      display: flex;
      .swiper-container {
        width: calc(100% + 2rem);
        padding-bottom: 0;
        margin: space(4) -1rem 0;

      }
      .swiper-slide {
        flex: 1;
      }
      .reviews__item__bubble {
        height: 100%;
      }
    }
  }
}
