.blog-category-item {
  display: flex;
  align-items: center;
  height: rem(36px);
  padding: 0 space(3);
  margin-top: space(2);
  font-size: $font-size-sm;
  font-weight: $font-weight-medium;
  color: color("primary");
  cursor: pointer;
  user-select: none;
  border: $border-width solid $border-color;
  border-radius: $border-radius;
}

.blog-category-item:not(:last-child) {
  margin-right: space(2);
}
