.eon-package-spot {
  padding: space(5);
  background: $white;
  border: $border-width solid $border-color;
  border-radius: $border-radius;

  &__top {
    padding-bottom: space(3);

    h4 {
      margin-bottom: 0;
    }

    .mark {
      font-size: $font-size-xs;
    }

    img {
      float: right;
      width: rem(72px);
      border-radius: $border-radius;
    }
  }

  // &__price {

  // }

  &__price-total {
    font-size: rem(32px);
    font-weight: $font-weight-medium;
  }

  &__price-meta {
    font-size: $font-size-xs;
    color: color("muted-light");
  }

  &__description {
    padding: space(3) 0;
    border-top: $border-width solid $border-color;

    small {
      display: block;
      font-size: rem(10px);
      color: color("muted-light");
    }
  }

  &__actions {
    display: flex;
    padding-top: space(3);
    border-top: $border-width solid $border-color;
  }

  &--promoted {
    padding: space(7);
    border: 0;
    box-shadow: $box-shadow;
  }
}
