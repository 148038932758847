// stylelint-disable all
.EPiServerForms {
  max-width: rem(420px);

  .justify-content-center &,
  .u-align-center & {
    margin: 0 auto;
  }

  button {
    @extend .btn;
    @extend .btn--primary;
  }

  input[type="text"],
  input[type="email"],
  input[type="tel"],
  textarea,
  select {
    @extend .form-control;
    height: $input-height + $input-padding-y - rem(8px);
  }

  .FormTextbox,
  .FormSelection {
    @extend .form-stack;

    &.ValidationRequired {
      @extend .form-stack--required;
    }
  }

  .FormSelection {
    @extend .form-select;
  }

  .FormSubmitButton {
    @extend .btn--block;
    @extend .btn--lg;
  }

  .FormResetButton {
    @extend .btn;
    @extend .btn--sm;
    @extend .btn--secondary;
  }

  .FormCaptcha__Refresh {
    @extend .btn--secondary;
    @extend .btn--sm;
  }

  .Form__Element.FormParagraphText {
    margin-top: space(4);
    margin-bottom: space(2);
    font-size: $font-size-xs;
    color: color("muted");
    a {
      color: color("primary");
      text-decoration: underline;
    }
  }

  .ValidationFail {
    input,
    select,
    textarea {
      @extend .form-control--invalid;
      color: color("black");
    }
  }

  .Form__Element .Form__Element__ValidationError {
    font-size: $font-size-xs;
    color: color("danger");
  }

  .Form__Status__Message.hide {
    display: none !important;
  }

  .Form__Status {
    .Form__Success__Message {
      @extend .feedback-message;
      @extend .feedback-message--success;
      background: color("green-bg") !important;
    }

    .Form__Error__Message {
      @extend .feedback-message;
      @extend .feedback-message--danger;
      background: color("red-bg") !important;
    }

    strong {
      @extend .feedback-message__header;
    }
  }

  .FormChoice {
    margin-bottom: space(2);
    &.ValidationRequired {
      @extend .form-stack--required;
    }
    fieldset {
      border: none !important;
      padding: 0 !important;
    }
    label {
      @extend .option-box;
    }
    .option-box__control-label--sm {
      font-size: $font-size-sm;
    }
  }
  .Form__Element {
    .Form__Element__Caption {
      display: block !important;
      margin-bottom: space(2);
      text-transform: uppercase;
      color: color("muted");
    }

    fieldset legend.Form__Element__Caption {
      margin-top: space(4);
      font-size: $font-size-xs;
    }

    &.FormCaptcha {
      display: flex;
      flex-direction: column;

      label {
        order: 1;
      }

      .FormCaptcha__Input {
        order: 2;
        width: 100%;
      }

      img {
        order: 3;
        margin-top: space(2);
      }

      button {
        order: 4;
        margin-top: space(2);
      }
    }
  }
  .small--xs {
    display: block;
  }

  .FormFileUpload__Input {
    -webkit-appearance: none;
    text-align: left;
    -webkit-rtl-ordering: left;
  }
}
