.vehicle-listing-filter {
  &__row {
    display: flex;
    align-items: center;
    border-bottom: $border-width solid $border-color;

    @include bp-down(sm) {
      flex-direction: column;
      align-items: flex-start;
      padding: space(3) 0;
    }

    &--wrap {
      @include bp-down(sm) {
        flex-wrap: wrap;

        .vehicle-listing-filter__filter-container .filter-bar .filter-bar__tags {
          @include bp-down(sm) {
            max-width: 100%;
          }
        }

        .filter-bar {
          @include bp-down(sm) {
            margin-bottom: space(3);
          }
        }
      }
    }
  }

  &--page-header {
    .vehicle-listing-filter__filter-container {
      @include bp-down(sm) {
        display: block;
      }
    }

    .filter-bar__tags {
      @include bp-down(sm) {
        max-width: 195px;
      }
    }
  }

  &__filter-container {
    @include bp-down(sm) {
      margin-right: 0;
    }
  }

  &__store-selector {
    display: flex;
    align-items: center;
    height: space(5);
    padding-left: space(3);
    margin-right: space(3);
    border-left: $border-width solid $border-color;

    @include bp-down(sm) {
      padding-left: 0;
      border-left: 0;
    }

    &__input {
      display: flex;
      align-items: center;

      &:not(:last-child) {
        margin-right: space(3);
      }

      .vehicle-list-option {
        margin-right: space(2);
        cursor: pointer;
      }

      &__text {
        margin-bottom: 0;
        font-size: $font-size-xs;
        font-weight: $font-weight-medium;
        color: color("black");
        text-transform: capitalize;
        letter-spacing: normal;
        cursor: pointer;
      }
    }
  }

  &__sort {
    margin-left: auto;

    .form-select::before {
      top: calc(50% - 4px);
      right: 0;
      font-size: $font-size-xs;
      font-weight: $font-weight-bold;
    }

    &__select {
      width: auto;
      height: auto;
      padding-right: space(3);
      font-size: $font-size-sm;
      font-weight: $font-weight-medium;
      color: color("primary");
      background: transparent;
      border: none;
      appearance: none;

      @include bp-down(sm) {
        font-size: $font-size-xs;
      }
    }
  }
}
