.tall-card {
  display: block;
  width: rem(300px);
  cursor: pointer;

  &__image {
    @include bg-image();
    width: 100%;
    height: rem(338px);
    margin-bottom: rem(12px);
  }

  @include bp-down(sm) {
    width: rem(163px);

    .tall-card__image {
      height: rem(180px);
    }
  }

  &__label {
    font-weight: $font-weight-medium;

    span {
      margin-left: rem(2px);
      font-size: $font-size-sm;
      font-weight: $font-weight-regular;
      color: color("muted");
    }
  }
}
