.content-block {
  &:not(:last-child) {
    margin-bottom: space(6);
  }

  @include bp-down(md) {
    margin-bottom: space(2);
  }
}

/* stylelint-disable */
.content-block-expand .link-toggler .shaded-expand__content {
  height: 150px;
  margin-top: 0px;
  mask-image: linear-gradient(to bottom, black 50%, transparent 100%);
}
/* stylelint-enable */

.content-block-expand .link-toggler--active .shaded-expand__content {
  height: auto;
  mask-image: none;
}
