.floating-action-btn {
  position: fixed;
  right: 2rem;
  z-index: $zindex-overlay;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 50px;
  height: 50px;
  color: color("white");
  cursor: pointer;
  background: color("primary");
  border-radius: 100%;
  /* stylelint-disable-next-line selector-no-qualifying-type */
  body.overlay-open & {
    display: none;
  }

  @include bp-down(sm) {
    display: none;
  }

  &--filter {
    bottom: 7.5rem;
  }

  &--contact {
    bottom: 4rem;

    @include bp-down(sm)
    {
      .vehicle-page & {
        bottom: 6rem;
      }
    }
  }

  &--watch {
    bottom: 11rem;
  }

  &__icon-open {
    display: none;
  }
}
