.department-list {
  padding: space(4);
  margin-top: space(3);
  background-color: $white;
  border: $border-width solid $border-color;
  border-radius: $border-radius;
  &__row:not(:first-child) {
    padding-top: 16px;
    border-top: $border-width solid $border-color;
  }
  &__row {
    display: flex;
    justify-content: space-between;
    padding-bottom: 16px;
  }

  &__label {
    font-size: $font-size-xxs;
    color: color("muted");
    text-transform: uppercase;
  }

  &__title {
    font-size: $font-size-sm;
  }

  &__icon {
    margin-right: space(1);
    font-size: $font-size-sm;
    color: color("primary");
  }

  &__status {
    display: flex;
    align-items: center;
    font-size: $font-size-xs;
    color: color("muted");
    &__indicator {
      width: 6px;
      height: 6px;
      margin-right: space(2);
      border-radius: 6px;

      &--available {
        background-color: color("success");
      }
      &--pending {
        background-color: color("yellow");
      }
      &--not-available {
        background-color: color("danger");
      }
    }
  }
}
