// stylelint-disable declaration-no-important
.contact-print-page {
  // stylelint-disable property-no-vendor-prefix
  -webkit-print-color-adjust: exact;
  height: 100%;
  padding: 0;
  &__container {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 100%;
  }

  &__title-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-top: 40px;
    padding-bottom: rem(40px);
    background-size: cover;
    &--white {
      background-color: color("white");
    }
    &--yellow {
      background-color: color("yellow");
    }
    &--red {
      background-color: color("red");
    }
    &--green {
      background-color: color("green");
    }
    &--blue {
      background-color: color("blue");
    }
    &--white-text {
      color: $white;
    }
  }

  &__icon {
    margin-bottom: space(3);
    font-size: rem(64px);
    color: color("blue");
  }

  &__title {
    font-size: rem(64px);
    font-weight: $font-weight-bold;
  }

  &__sub-title {
    font-size: rem(32px);
    font-weight: $font-weight-medium;
  }

  &__main-container {
    padding: rem(32px) rem(100px);
    margin: 0 rem(40px);
    border-top: $border-width solid color("muted-light");
    ul {
      padding-inline-start: 0;
    }
  }

  &__disclaimer {
    margin-bottom: rem(24px);
    font-size: rem(24px);
    text-align: center;
  }

  &__date-row {
    display: flex;
    justify-content: space-between;
    margin-bottom: rem(12px);
    font-size: $h2-font-size;
    font-weight: $font-weight-medium;
  }

  &__date-closed {
    color: color("danger");
  }

  &__meta-container {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-top: space(4);
    margin: 0 rem(40px) rem(40px);
    border-top: $border-width solid color("muted-light");
  }

  &__meta-info {
    display: flex;
  }

  &__qr-code {
    max-height: rem(100px);
  }

  &__address-container {
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    margin-left: rem(12px);
  }

  &__address-line {
    font-size: $font-size-lg;
    &--highlight {
      font-weight: $font-weight-medium;
    }
  }

  &__logo {
    max-height: rem(48px);
  }

  ._hj_feedback_container {
    display: none !important;
  }
}

.contact-print {
  display: flex;
  align-items: center;
  margin-left: space(3);
  font-size: $font-size-sm;
  font-weight: $font-weight-medium;
  color: color("primary");
  a {
    margin-left: space(1);
  }
}
