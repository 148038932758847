.logo-card-list {
  @include make-row();

  &__item {
    @include make-col-ready();
    margin-bottom: space(6);

    @include bp-up(xl) {
      @include make-col(12/5);
    }

    @include bp-only(lg) {
      @include make-col(3);
    }

    @include bp-only(md) {
      @include make-col(4);
    }

    @include bp-down(sm) {
      @include make-col(6);
      margin-bottom: space(3);
    }
  }
}
