.cart-item {
  padding: space(3);
  margin-bottom: rem(12px);
  overflow: hidden;
  background: $white;
  border: $border-width solid $border-color;
  border-radius: $border-radius;

  &__feedback {
    padding: rem(12px) space(3);
    margin: - space(3);
    margin-top: space(3);
    font-size: $font-size-xs;
    font-weight: $font-weight-medium;
    border-top: $border-width solid $border-color;

    i,
    svg {
      margin-right: space(1);
    }

    &--success {
      color: color("success");
      background: color("green-bg");
    }

    &--danger {
      color: color("danger");
      background: color("red-bg");
    }
  }

  &__product {
    display: flex;
    align-items: center;
  }

  &__product-thumbnail {
    margin-right: space(2);

    img {
      width: auto;
      max-width: rem(45px);
      height: auto;
      max-height: rem(50px);
      border-radius: $border-radius;
    }
  }

  &__product-main {
    margin-top: - rem(6px);
  }

  &__product-meta {
    label {
      display: inline-block;
      margin-bottom: 0;
      font-size: $font-size-xxs;
    }

    .mark {
      margin-left: rem(2px);
      font-size: $font-size-xs;
      border-radius: 2px;
    }
  }

  &__product-name {
    font-size: $font-size-xs;
    font-weight: $font-weight-medium;
    line-height: rem(16px);
  }

  &__line-delivery-meta {
    font-size: rem(10px);

    .checkout & {
      @include bp-up(md) {
        font-size: $font-size-xs;
      }
    }
  }

  &__line-meta {
    font-size: $font-size-xxs;
  }

  &__line-name {
    font-size: $font-size-xs;
    font-weight: $font-weight-medium;
  }

  &__line-price {
    .price-tag {
      display: block;
      font-size: $font-size-xs;
      text-align: right;

      &:first-child {
        font-weight: $font-weight-medium;
      }
    }
  }

  &__line-remove {
    /* stylelint-disable-next-line declaration-no-important */
    text-align: right !important;

    i,
    svg {
      font-size: rem(12px);
      color: color("muted-light");
      cursor: pointer;
    }
  }

  .table {
    margin-top: space(2);
    margin-bottom: - space(3);

    td {
      padding: space(2) space(1);
      vertical-align: middle;
      /* stylelint-disable-next-line selector-no-qualifying-type */
      &:nth-child(1) {
        width: 40%;
      }
      &:nth-child(2) {
        width: 25%;
      }
      &:nth-child(3) {
        width: 27%;
      }
      &:nth-child(4) {
        width: 8%;
      }

      @include bp-down(sm) {
        select {
          font-size: rem(12px);
        }
      }

      .checkout & {
        @include bp-up(md) {
          /* stylelint-disable-next-line selector-no-qualifying-type */
          &:nth-child(1) {
            width: 60%;
          }
          &:nth-child(2) {
            width: 15%;
          }
          &:nth-child(3) {
            width: 20%;
          }
          &:nth-child(4) {
            width: 5%;
          }
        }
      }
    }

    tr:first-child td {
      padding-top: 0;
      border-top: 0;
    }
  }

  &--loading {
    cursor: wait;
    opacity: 0.5;
    transition: opacity $transition-base;

    > * {
      pointer-events: none;
    }
  }
}
