.contact-forms {
  display: flex;
  flex-wrap: wrap;
  margin-right: -(space(4) / 2);
  margin-left: -(space(4) / 2);

  > * {
    margin-right: space(4) / 2;
    margin-left: space(4) / 2;
  }

  .tab-button {
    @include bp-up(md) {
      flex: 1 1 0;
      order: -1;
      margin-bottom: rem(12px);
    }

    @include bp-down(sm) {
      @include tab-button-horizontal();
      width: 100%;
      border-bottom-right-radius: 0;
      border-bottom-left-radius: 0;

      &:not(.tab-button--active) {
        margin-bottom: rem(12px);
      }

      &.tab-button--active {
        + .contact-forms__form {
          margin-bottom: rem(12px);
        }
      }

      + .contact-forms__form {
        border-top-left-radius: 0;
        border-top-right-radius: 0;
      }
    }

    + .contact-forms__form {
      display: none;
    }

    &--active + .contact-forms__form {
      display: block;
    }
  }

  &__form {
    width: 100%;
    padding: space(4);
    background: $white;
    border: $border-width solid $border-color;
    border-radius: $border-radius;

    @include bp-down(sm) {
      padding: space(3);
    }

    &--yellow {
      background: color("yellow-bg");
    }

    &--plain {
      padding: 0;
      background: transparent;
      border: 0;
    }
  }

  .contact-form-row {
    & > div {
      width: 100%;
      margin-bottom: space(3);
    }
    @include bp-up(md) {
      justify-content: space-between;

      & > div {
        margin-right: space(3);

        &:last-child {
          margin-right: 0;
        }
      }

      .form-stack {
        flex: 2;
      }
      .regnr-input {
        flex: 1;
      }

      &.contact-form-row--single {
        .form-stack {
          flex: 7;
        }
        .regnr-input {
          flex: 2;
        }
      }
    }
  }
}
