body {
  padding-top: $site-header-height;

  @include bp-down(sm) {
    //padding-top: $site-header-height-sm;
    padding-top: rem(99px);
  }
}

.has-site-alerts {
  padding-top: $site-header-height + $site-alerts-height;

  @include bp-down(sm) {
    padding-top: $site-header-height-sm + $site-alerts-height;
  }
}

.has-large-site-alerts {
  padding-top: $site-header-height + $site-alerts-large-height;

  @include bp-down(sm) {
    padding-top: $site-header-height-sm + $site-alerts-large-height;
  }
}

.ecom-checkout,
.accessories-checkout {
  padding-top: $site-header-height-checkout;

  @include bp-down(sm) {
    padding-top: $site-header-height-checkout-sm;
  }
}


.employee-card-page {
  padding-top: 0;

  @include bp-down(md) {
    padding-bottom: space(8);
  }
}

.employee-card-page.has-site-alerts {
  padding-top: $site-alerts-height;

  @include bp-down(sm) {
    padding-top: $site-alerts-height;
  }
}

.employee-card-page.has-large-site-alerts {
  padding-top: $site-alerts-large-height;

  @include bp-down(sm) {
    padding-top: $site-alerts-large-height;
  }
}
