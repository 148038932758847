.breadcrumbs {
  display: flex;

  &__item {
    font-size: $font-size-xs;

    &:not(:first-child) {
      &::before {
        margin: 0 space(1) 0 space(1);
        font-weight: $font-weight-regular;
        color: color("muted-light");
        content: "/";
      }
    }

    &:not(:last-child) {
      flex-shrink: 0;
      font-weight: $font-weight-medium;
      color: color("primary");
    }

    &--last {
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }

    @include bp-down(sm) {
      &--second {
        &:not(:first-child) {
          &::before {
            content: "/ ...";
          }
        }

        a {
          display: none;
        }
      }

      &--mids {
        display: none;
      }

      &--second-last {
        &:not(:first-child) {
          &::before {
            margin: 0 space(1) 0 0;
          }
        }
      }
    }
  }
}

.breadcrumbs--overlay {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1;
  width: 100%;
  padding-top: 1rem;
  padding-left: 0;
  color: color("white");

  a {
    color: color("white");
  }
}
