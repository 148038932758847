.page-nav {
  padding: space(3) 0;
  font-size: $font-size-sm;
  border-bottom: $border-width solid $border-color;

  &--no-border {
    border-bottom: none;
  }

  &--sm {
    font-size: $font-size-xs;
  }

  .link--icon {
    i,
    svg {
      margin: 0 space(1);
      color: color("primary");

      &:first-of-type {
        margin-left: 0;
      }
    }
  }
  svg,
  i {
    margin: 0 space(1);
  }
}
