.hero-image {
  @include bg-cover();
  position: relative;
  max-width: map-get($container-max-widths, xl);
  height: rem(260px);
  margin: 0 auto;
  color: $white;

  @include bp-down(sm) {
    display: none;
  }
  &--full-width {
    max-width: 100%;

    @include bp-down(sm) {
      display: block;
    }
  }

  &--transparent-top {
    padding-top: $site-header-height;
    @include bp-down(sm) {
      padding-top: $site-header-height-sm;
    }
  }

  &--large {
    height: rem(661px);
    @include bp-down(sm) {
      height: rem(488px);
    }
  }

  &--large.hero-image--transparent-top {
    padding-top: rem(201px) + $site-header-height;
    @include bp-down(sm) {
      padding-top: rem(128px) + $site-header-height-sm;
    }
  }

  &--auto-height {
    height: auto;
  }

  &--overlay {
    @include promo-bg-overlay();

    &::before {
      z-index: 0;
    }

    .wizard {
      position: relative;
    }
  }
}
