.tyre-order-page {

  .section__content {
    max-width: 100%;
    padding: 2.5rem 0 3.5rem 0;
  }

  .tyre-order {
    .table {
      margin-bottom: 0;
    }

    &__header {
      &__title {
        font-size: $font-size-xs;
        color: gray("700");
      }

      &__status {
        &__container {
          display: flex;
          flex-direction: row;
          margin-top: space(2);
        }

        &__icon {
          width: rem(16px);
          height: rem(16px);
          margin-top: space(1);
          margin-right: space(2);
          background: $white;
          border-radius: 16px;

          &--cancelled {
            background: color("red");
          }

          &--pending {
            background: color("blue");
          }

          &--arrived {
            background: color("green");
          }

        }

        &__text {
          font-size: $font-size-lg;
        }
      }
    }

    &__order-item {
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding-top: space(2);
      padding-bottom: space(2);
      border-bottom: $border-width solid $border-color;
      &:first-child {
        @include bp-down(sm) {
          flex-direction: column;
          align-items: baseline;
        }
      }

      &__main-data {
        flex: 1;
      }
    }

    &__stats-container {
      display: flex;
      flex: 2;
      align-items: center;
      justify-content: flex-end;
      margin-top: space(2);

      &__cell {
        flex: 1;

        &__divider {
          display: none;
          @include bp-down(sm) {
            display: block;
          }
        }

        &--right-aligned {
          text-align: right;
        }

        &:not(:first-child) {
          text-align: right;
        }
      }

      &__quantity-text {
        font-size: $font-size-sm;
      }

      @include bp-down(sm) {
        width: 100%;
      }
    }

    &__spec {
      margin-top: space(3);
      &__row {
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding-top: space(3);
        padding-bottom: space(3);

        &__sub-container {
          display: flex;
          flex-direction: column;

          &:last-child {
            align-items: flex-end;
          }
        }

        &__title {
          font-size: $font-size-xs;
          font-weight: $font-weight-bold;
        }

        &__data {
          font-size: $font-size-sm;

          &--discount {
            color: color("red");
          }
        }

        &__sub-info {
          font-size: $font-size-xs;
          color: gray("600");
        }

        &--muted {
          font-size: $font-size-xs;
          color: gray("700");
        }


        &:not(:last-child) {
          border-bottom: $border-width solid $border-color;
        }
      }
    }
  }
}
