.service-spot {
  padding: space(4);
  margin-bottom: space(2);
  background: $white;
  border: $border-width dashed $border-color;
  border-radius: $border-radius;

  &__main {
    display: flex;
    align-items: center;
    margin-bottom: space(3);

    img {
      margin-right: rem(12px);
      margin-bottom: auto;
    }

    label {
      margin-bottom: rem(2px);
      font-size: rem(9px);
    }

    strong {
      font-size: $font-size-sm;
    }

    .price-tag {
      margin-left: auto;
    }

    > div:last-child {
      margin-left: auto;

      .price-tag {
        display: block;
      }
    }
  }

  &__desc {
    font-size: $font-size-sm;
  }

  .feedback-message,
  .btn {
    margin-top: rem(12px);
  }

  .link {
    font-size: $font-size-xs;
  }

  img {
    width: rem(60px);
    border-radius: $border-radius;
  }

  &__meta {
    margin-top: rem(12px);
    font-size: $font-size-xs;
    color: color("muted-light");
  }
}
