.department-block {
  display: flex;
  min-height: 560px;
  overflow: hidden;
  background-color: color("white");
  border: $border-width solid $gray-400;
  border-radius: 6px;

  @include bp-down(md) {
    flex-direction: column-reverse;
    min-height: initial;
  }

  &__content {
    width: 50%;
    padding: space(5) space(6);

    @include bp-down(md) {
      width: 100%;
      padding: space(4) space(3);
    }
  }
  &__title {
    margin-bottom: space(3);
    font-size: $h2-sm-font-size;
    font-weight: $font-weight-medium;

    @include bp-down(md) {
      margin-bottom: 12px;
      font-size: $h4-font-size;
    }

  }
  &__actions {
    display: flex;
    padding-bottom: space(3);
    .btn {
      margin-top: 0;
      margin-bottom: 0;

      @include bp-down(md) {
        font-size: $font-size-xs;
      }
    }

    .call-action__number {
      @include bp-down(md) {
        font-size: $font-size-xs;

        span {
          font-size: $font-size-xs;
        }
      }
    }
  }
  &__phone-btn {
    margin-right: space(2);
  }
  &__section {
    position: relative;
    padding: space(3) space(5);
    border-top: $border-width solid $gray-400;

    &:last-child {
      padding-bottom: 0;
    }

    svg {
      position: absolute;
      left: space(1);
      margin-top: 1px;
    }

    &--no-bottom-padding {
      padding-bottom: 0;
    }
  }
  &__line {
    font-size: $font-size-sm;

    @include bp-down(md) {
      font-size: $font-size-xs;
    }

    .link {
      text-decoration: underline;
      opacity: 0.75;
    }
  }
  &__opening-hours {
    &__item {
      margin-bottom: space(2);
      font-size: $font-size-sm;
      opacity: 0.75;
      &--open {
        font-weight: $font-weight-medium;
        color: color("success");
        opacity: 1;
      }
      &.link {
        text-decoration: underline;
      }

      &:last-child {
        margin-bottom: 0;
      }

      @include bp-down(md) {
        margin-bottom: space(1);
        font-size: $font-size-xs;
      }
    }
  }

  &__brands {

    &__title {
      margin-bottom: 12px;
      font-size: $font-size-sm;
      font-weight: $font-weight-medium;

      @include bp-down(md) {
        font-size: $font-size-xs;
      }
    }
    &__list {
      display: flex;
      flex-wrap: wrap;
    }
    &__item {
      min-width: 1.25rem;
      max-width: 2.5rem;
      margin-bottom: space(3);
      img {
        height: 100%;
        object-fit: contain;
      }

      &:not(:last-child) {
        margin-right: space(4);
      }
    }
  }

  &__image {
    width: 50%;
    min-height: 100%;

    @include bp-down(md) {
      width: 100%;
      aspect-ratio: 5/3; /* stylelint-disable-line */
    }
    img {
      object-fit: cover;
      width: 100%;
      height: 100%;
    }
  }
}
