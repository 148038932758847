.gallery-grid {
  display: grid;
  width: calc(100% - 2px); // subtract the width generated by gaps
  height: 484px;
  grid-template-columns: 50% 25% 25%;
  grid-gap: 1px;

  &__item {
    @include bg-image(0);
    width: 100%;
    height: 100%;
    cursor: pointer;

    &:nth-child(1) {
      grid-row: 1 / 3;
    }

    &:last-child {
      &:nth-child(2) {
        grid-row: 1 / 3;
        grid-column: 2 / 4;
      }

      .gallery-grid__item-link {
        top: calc(50% - 30px);
        right: auto;
        bottom: auto;
        left: 0;
        z-index: 1;
        width: 100%;
      }

      &::after {
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        content: "";
        background: rgba($black, 0.6);
      }
    }
  }

  &__item-link {
    position: absolute;
    right: rem(46px);
    bottom: rem(40px);
    color: $white;
    text-align: center;

    i,
    svg {
      margin-bottom: space(2);
      font-size: rem(28px);
    }

    div {
      font-size: rem(18px);
      text-decoration: underline;
    }
  }

  @include media-ie11() {
    display: flex;
    width: 100%;

    &__inner {
      display: block;
      width: 100%;

      .gallery-grid__item {
        display: none;

        &:last-child {
          display: block;
        }
      }
    }
  }
}
