.tyreshop-type-selector {
  display: flex;
  align-items: center;
  justify-content: center;

  @include bp-down(sm) {
    display: block;
  }

  .btn {
    width: rem(250px);
    margin: 0 space(2);

    @include bp-down(sm) {
      display: block;
      width: 100%;
      margin: space(3) 0;
    }
  }
}
