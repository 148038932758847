
.sites-list,
.contact-page,
.brand-list,
.tyre-order-page,
.ecom-thankyou {
  .site-content {
    padding-bottom: space(8);
  }
}
