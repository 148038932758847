.vehicle-history-forms {
  &__carfax-logo {
    margin-bottom: space(3);
  }

  &__request-info {
    p {
      margin-bottom: space(3);
    }

    &__result {
      padding: space(4);
      font-size: $font-size-sm;
      background: color("green-bg");
      border: $border-width solid color("green");
      border-radius: $border-radius;

      &__header {
        margin-bottom: space(2);
        font-size: $font-size-base;
        font-weight: $font-weight-medium;
        color: color("green");
      }

      .btn {
        margin-top: rem(12px);
      }
    }
  }
}
