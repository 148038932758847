.campaign-configurator {
  &.configurator-app {
    @include bp-down(sm) {
      margin-right: 0;
      margin-left: 0;
    }
  }

  &__header {
    text-align: center;
  }

  &__models {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    margin-bottom: space(3);
  }

  &__model-item {
    width: rem(200px);
    padding: space(4) space(3);
    margin: 0 space(3) space(5);
    text-align: center;
    cursor: pointer;
    background: $white;
    border: $border-width solid $border-color;
    border-radius: $border-radius;

    @include bp-down(sm) {
      width: calc(50% - #{space(3)});
      margin: 0 space(1) space(2);
    }

    &--selected {
      border: 2px solid color("primary");
    }
  }

  &__main {
    position: relative;
    background: $white;
    border: $border-width solid $border-color;
    border-bottom: 0;
    border-top-left-radius: $border-radius;
    border-top-right-radius: $border-radius;

    @include bp-down(sm) {
      border-radius: 0;
    }

    .ribbon {
      position: absolute;
      top: -$ribbon-arrow-right-size;
      left: 50%;
      transform: translateX(-50%);
    }

    &__text {
      margin: space(2) auto 0;
    }
  }

  &__config-group {
    margin: 0 auto;

    .option-box__content {
      p:last-child {
        margin-bottom: space(3);
      }
    }
  }

  &__thumb-image {
    max-width: rem(150px);
    margin: 0 auto;
  }

  &__main-image {
    max-width: rem(586px);
    margin: space(4) auto space(6);
    text-align: center;
  }

  &__product {
    h2,
    .product-meta {
      text-align: center;
    }
  }

  & .configurator-app__summary {
    padding: 0;
    margin-top: space(3);
  }

  &__summation {
    border-bottom-right-radius: $border-radius;
    border-bottom-left-radius: $border-radius;

    @include bp-down(sm) {
      border-radius: 0;
    }

    &__inner {
      max-width: rem(404px);
      margin: 0 auto;
    }
  }
}
