// stylelint-disable declaration-no-important
.eon-single-product {
  .sound-comfort-button {
    position: absolute;
    top: 0;
    left: 0;
    z-index: 1;
    max-width: 160px;
    height: 51px;

    @include bp-down(sm) {
      max-width: 120px;
    }
  }
  &__images {
    display: block;
    min-width: 50%;
    img {
      width: auto;
      max-width: 100%;
      height: auto;
      max-height: 100%;
      border-radius: $border-radius;
    }

    @include bp-up(md) {
      > div {
        height: 100%;
        .swiper-container-autoheight {
          height: 80%;
        }
        .swiper-container-thumbs {
          height: 20%;
        }
      }
    }

    .product-swiper-container:not(.swiper-container-thumbs),
    > div {
      @include bp-down(sm) {
        height: 44vh !important;
      }
      .magnifying-glass {
        position: absolute;
        right: 20px;
        bottom: 10px;
        z-index: 100;
        display: flex;
        align-items: center;
        justify-content: center;
        padding: space(2);
        pointer-events: none;
        background: rgba(255, 255, 255, 0.2);
        border-radius: 50%;
        opacity: 0;
        transition: ease-in 0.2s all;
      }

      &:hover {
        .magnifying-glass {
          opacity: 1;
        }
      }
    }

    .product-swiper-container:not(.swiper-container-thumbs) .swiper-wrapper {
      @include bp-down(sm) {
        height: 38vh !important;
      }
    }

    .swiper-container.swiper-container-thumbs,
    .swiper-container.swiper-container-thumbs .swiper-wrapper {
      height: rem(80px);
    }

    .swiper-pagination-bullet {
      background: gray("500");
      opacity: 1;
    }

    .swiper-pagination-bullet-active {
      background: color("primary");
    }

    .swiper-slide {
      display: flex;
      align-items: center;
      justify-content: center;
      height: 100% !important;
      text-align: center;
      cursor: pointer;
    }

    .swiper-container-thumbs {
      margin-top: rem(12px);

      .swiper-slide {
        width: rem(56px);
        border: 2px solid transparent;
      }

      .swiper-slide-thumb-active {
        border-color: $border-active-color;
      }

      img {
        border-radius: $border-radius;
      }
    }
  }

  &__main {
    &.hidden {
      opacity: 0;
    }
  }

  &__meta {
    margin-bottom: space(2);
    font-size: $font-size-sm;
    font-weight: $font-weight-medium;

    .mark {
      position: relative;
      top: -1px;
      margin-right: space(1);
      font-size: $font-size-xs;
    }
  }

  &__buy {
    position: relative;
    padding: space(4);
    margin-top: space(3);
    background: color("blue-bg");
    border: $border-width solid $border-color;
    border-radius: $border-radius;

    .ribbon {
      position: absolute;
      top: -$ribbon-arrow-right-size;
      right: space(4);
    }
  }

  &__buy-actions {
    .btn {
      width: 100%;
      margin-left: rem(12px);
    }

    .form-stack {
      margin-bottom: 0;
    }
  }

  &__buy-price {
    margin-bottom: space(2);

    .btn {
      float: right;
      margin-top: rem(2px);
    }
  }

  &__buy-price-total {
    font-size: rem(32px);
  }

  &__buy-price-total,
  &__buy-price-quantity {
    font-weight: $font-weight-medium;
    color: color("attention");
  }

  &__buy-price-meta {
    position: relative;
    top: -4px;
    font-size: $font-size-xs;
    color: color("muted");
  }

  &__buy-meta {
    margin-top: space(3);

    .list-styled li {
      margin-bottom: space(1);
    }
  }

  &__buy-meta-delivery {
    &:not(.eon-single-product__buy-meta-delivery--custom) {
      display: flex;
      align-items: center;
    }

    margin-bottom: space(1);
    font-size: $font-size-sm;

    .attention-circle {
      margin-right: space(2);
      margin-left: 3px;
    }
  }

  &__sub-options-header {
    margin-bottom: rem(8px);
    font-size: $font-size-sm;
    font-weight: $font-weight-medium;
  }
}
