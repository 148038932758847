.cart-promo {
  &__cta {
    font-size: $font-size-xs;
  }

  &__code {
    margin-top: space(1);
    font-size: $font-size-xs;
    font-weight: $font-weight-medium;

    a {
      margin-left: space(1);
    }
  }

  &__form {
    display: flex;
    margin-top: space(2);
    margin-bottom: 0;

    .form-group {
      margin-bottom: 0;
    }
  }
}
