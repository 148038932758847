.configurator-select {
  flex: 1;
  &:not(:first-child) {
    margin-left: rem(12px);

    // @include bp-down(md) {
    //   margin-left: 0;
    //   margin-top: rem(12px);
    // }
  }

  &__dropdown-open {
    z-index: 10;
  }

  &__overlay {
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
  }

  // &__button {

  // }

  &__heading {
    margin-bottom: space(2);
    font-size: $font-size-sm;
    font-weight: $font-weight-medium;
  }
  &__container {
    position: relative;
  }

  &__dropdown {
    position: absolute;
    top: 55px;
    right: 0;
    left: 0;
    max-height: 0;
    padding: rem(2px) 0;
    background-color: color("white");
    border: $border-width solid $border-color;
    border-radius: rem(6px);
    box-shadow: 0 4px 6px #0000001a;
    opacity: 0;
    &--open {
      z-index: 1;
      max-height: none;
      opacity: 1;
    }
  }

  &__item {
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: rem(40px);
    padding: 0 space(3);
    font-size: $font-size-sm;
    cursor: pointer;
    user-select: none;

    &:hover {
      background-color: gray("100");
    }

    &--selected {
      font-weight: $font-weight-medium;
    }
  }

  &__input {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    padding: rem(12px) space(3);
    cursor: pointer;
    background-color: color("white");
    border: $border-width solid $border-color;
    border-radius: rem(6px);

    &--date {
      height: rem(47px);
      padding: 0;

      .datepicker {
        display: flex;
        align-items: start;
        width: 100%;
        height: 100%;
      }

      input {
        width: 100%;
        height: 100%;
        font-size: $font-size-sm;
        color: $black;
        border: none;
        border-radius: rem(6px);

        &::placeholder {
          color: #18191abf;
        }
      }

      .configurator-select__input__icon {
        top: space(3);
      }
    }

    &__value {
      font-size: $font-size-sm;
    }

    &__icon-container {
      display: flex;
      align-items: center;
      font-size: $font-size-sm;
    }

    &__icon {
      margin-left: rem(12px);
      font-size: $font-size-sm;
      color: $black;
    }
  }


  &__department-item {
    display: flex;
    justify-content: space-between;
    height: rem(40px);
    padding: 0 space(3);
    font-size: $font-size-sm;
    cursor: pointer;
    user-select: none;

    &:hover {
      background-color: gray("100");
    }

    &__name-container {
      display: flex;
      align-items: center;
      padding-left: rem(20px);

      &--selected {
        font-weight: $font-weight-medium;
      }
    }
    &__check {
      position: absolute;
      left: 12px;
      color: color("success");
    }

  }

  &__availability-container {
    display: flex;
    align-items: center;
    .delivery-indicator {
      margin-right: space(2);
    }
  }
}
