.header-meta {
  position: relative;
  top: -1px;
  margin-left: space(2);
  font-size: $font-size-base;
  color: color("primary");

  &--sm {
    font-size: $font-size-sm;
  }

  @include bp-down(sm) {
    top: rem(4px);
    float: right;
    font-size: $font-size-sm;
  }
}
