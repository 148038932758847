.product-usps {
  @include list-unstyled();
  display: flex;
  flex-wrap: wrap;

  &--sm {
    font-size: $font-size-sm;
  }

  &__item {
    margin-right: space(3);
    margin-bottom: space(1);

    svg {
      margin-right: space(1);
      color: color("primary");
    }

    @include bp-down(sm) {
      font-size: $font-size-sm;
    }
  }
}
