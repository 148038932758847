.search-watch {
  max-width: rem(402px);
  margin: rem(40px) auto rem(80px);
  text-align: center;

  @include bp-down(sm) {
    padding: 0 space(3);
  }

  &__icon {
    margin-bottom: space(3);
    font-size: rem(48px);
    color: color("primary");
  }

  &__header {
    margin-bottom: space(2);
    font-size: $h2-font-size;
    font-weight: $font-weight-medium;
    line-height: $h2-line-height;

    @include bp-down(sm) {
      font-size: $h3-font-size;
      line-height: $h3-line-height;
    }
  }

  &__text {
    margin-bottom: space(3);

    @include bp-down(sm) {
      font-size: $font-size-sm;
    }
  }

  &__filters {
    margin-bottom: space(4);
  }

  &__filter {
    display: inline-block;
    padding: font-padding(rem(8px));
    margin-bottom: space(1);
    font-size: $font-size-xs;
    font-weight: $font-weight-medium;
    color: color("primary");
    text-transform: uppercase;
    background: color("blue-bg");
    border-radius: $border-radius;

    &:not(:last-child) {
      margin-right: space(1);
    }
  }

  .form-text {
    text-align: center;
  }


  &__button-container {
    display: flex;
    justify-content: center;
  }

  &__option-text {
    text-align: left;

    &:not(:last-child) {
      margin-bottom: space(3);
    }
  }

  &__modal {
    @include bp-down(sm) {
      padding-bottom: 144px;
    }
    &__form {
      width: 100%;
      padding: space(4);
      margin-top: space(3);
      background: color("blue-bg");
      border: $border-width solid $border-color;
      border-radius: $border-radius;

      @include bp-down(sm) {
        padding: space(3);
      }

      a {
        color: color("primary");
        text-decoration: underline;
      }

      i,
      svg {
        color: color("primary");
      }

      .feedback-message {
        margin-top: space(3);
      }
    }
  }
}
