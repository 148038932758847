.modal-body {
  .section__header--collapse-toggler::after {
    display: none;
  }

  [data-modal-source="ajax"] {
    color: $body-color;
    text-decoration: none;
    pointer-events: none;
  }
}
