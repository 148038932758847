// stylelint-disable declaration-no-important

.filter-bar {
  display: flex;
  overflow: hidden;

  @include bp-down(sm) {
    border-right: 0;
  }

  &__btn {
    display: flex;
    align-items: center;
    font-size: $font-size-sm;
    font-weight: $font-weight-medium;
    color: color("primary");
    cursor: pointer;
    &__icon {
      margin-right: space(2);
      font-size: 20px;
    }
  }

  &__tags {
    position: relative;
    display: flex;
    flex: 1;
    align-items: center;
    max-width: 600px;
    margin-left: space(2);

    @include bp-down(lg) {
      max-width: 400px;
    }
    @include bp-down(md) {
      max-width: 250px;
    }

    &__shadow {
      position: absolute;
      top: 0;
      bottom: 0;
      z-index: 2;
      width: 6px;
      background: transparent linear-gradient(90deg, #000 0%, #fff 100%) 0% 0% no-repeat padding-box;
      opacity: 0.1;

      &--left {
        left: 0;
        background: transparent linear-gradient(90deg, #000 0%, #fff 100%) 0% 0% no-repeat padding-box;
      }

      &--right {
        right: 0;
        background: transparent linear-gradient(90deg, #fff 0%, #000 100%) 0% 0% no-repeat padding-box;
      }
    }
  }

  .modal {
    top: space(5) !important;
    overflow: initial;

    @include bp-down(sm) {
      top: 5px !important;
    }

    @include bp-down(xs) {
      top: 0 !important;
    }

    .modal-body {
      max-height: 75vh;
      overflow: auto;

      @include bp-down(sm) {
        max-height: none;
      }
    }
  }

  .swiper-container {
    width: 100%;
  }

  .swiper-wrapper {
    align-items: center;
    height: 60px;
    padding-left: space(3);
  }

  .swiper-slide {
    width: auto;
  }

  &__tag {
    display: flex;
    align-items: center;
    height: rem(30px);
    padding: 0 space(3);
    margin-right: space(2);
    font-size: $font-size-xs;
    font-weight: $font-weight-medium;
    color: color("primary");
    cursor: pointer;
    user-select: none;
    background: color("border-light");
    border-radius: 15px;

    &.link {
      margin: space(1) 0;
    }

    &__icon {
      margin-left: rem(6px);
    }

    &--group {
      display: flex;
      height: rem(34px);
      padding-right: rem(2px);
      cursor: default;
      user-select: initial;
      background: color("border");
      border-radius: 21px;
    }

    &--child {
      margin-right: 0;
      margin-left: space(1);
    }

    &--bordered {
      background: color("gray-bg");
      border: $border-width solid $border-color;
    }

    &--watch {
      color: color("white");
      cursor: pointer;
      background: color("primary");

      .vehicle-page & {
        color: color("primary");
        background: color("border-light");
      }

      .filter-bar__tag__icon {
        margin-right: rem(6px);
        margin-left: 0;
      }

      &--active {
        color: color("green");
        background: color("green-bg");
        border: $border-width solid color("green");
      }

    }
  }
}

.pulsing {
  animation: pulse-primary 2s infinite;
}

@keyframes pulse-primary {
  0% {
    z-index: 10000;
    box-shadow: 0 0 0 0 color("primary");
  }

  70% {
    z-index: 10000;
    box-shadow: 0 0 0 10px rgba(0, 0, 0, 0);
  }

  100% {
    z-index: 10000;
    box-shadow: 0 0 0 0 rgba(0, 0, 0, 0);
  }
}

.facet-filter-bar {
  display: flex;
  flex-wrap: wrap;

  .filter-bar__tag {
    margin-top: space(1);
    margin-bottom: space(1);
  }
}
