.vehicle-contact {
  // &__form {

  // }

  &__form-section {
    margin-bottom: space(4);
  }

  &__info {
    .call-action {
      margin-bottom: space(4);
    }

    .map-image-container {
      display: flex;

      .map-image {
        height: auto;
        margin-bottom: 0;
        margin-left: rem(8px);
      }
    }
  }

  &__vehicle {
    margin-bottom: space(5);

    &__row {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
    }

    &__row:first-child {
      align-items: center;
      margin-bottom: space(2);
    }

    &__name {
      font-size: $font-size-lg;
      font-weight: $font-weight-medium;
    }

    &__price {
      flex-shrink: 0;
      margin-left: space(2);
      font-weight: 500;
    }

    &__info {
      margin-top: space(1);
    }
  }

  &__department-name {
    margin-bottom: space(3);
    font-size: $font-size-lg;
    font-weight: $font-weight-medium;
  }
}
