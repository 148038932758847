.vehicle-table {
  @include list-unstyled();

  .table-header {
    padding: 1rem 0;
    font-weight: 700;
    @include bp-down(sm) {
      display: none;
    }
  }

  .table-row {
    padding: 1rem 0;

    &:nth-child(even) {
      background-color: color("white");
    }

    @include bp-down(sm) {
      flex-direction: column;
    }
  }

  .table-column {
    font-weight: 500;
    @include bp-down(sm) {
      max-width: 100%;
    }
    span {
      display: none;
      font-weight: 400;
      @include bp-down(sm) {
        display: inline;
      }
    }
  }

  .btn {
    @include bp-down(sm) {
      display: block;
      width: 100%;
      margin-top: 1rem;
    }
  }
}

.vehile-list-simple-contact-form {
  &__loader {
    font-size: $h2-font-size;
    text-align: center;
  }
}
