.payment-method-info-modal {
  .priceTable {
    @extend .table;
    @extend .table--responsive;
  }

  a {
    color: color("primary");
    text-decoration: underline;
  }

  .headText {
    display: block;
    margin-bottom: space(2);
    margin-left: 10px;
    font-weight: 600;
  }

  table {
    font-size: $font-size-xs;
  }
}
