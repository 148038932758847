@mixin media-item-horizontal() {
  position: relative;
  display: flex;
  flex-direction: column;

  .media-item__image {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    width: rem(87px);
    height: 100%;
    padding-bottom: 0;
  }

  .media-item__content {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 100%;
    padding: space(3);
    padding-left: rem(87px) + space(3);
    border: $border-width solid $border-color;
    border-left: 0;

    .link {
      font-size: $font-size-sm;
    }
  }
}

.media-item {
  @include border-bottom();
  display: block;
  overflow: hidden;
  border-radius: $border-radius;

  @include bp-up(md) {
    margin-bottom: space(4);
  }

  &__image {
    @include bg-image(0);
    width: 100%;
    padding-bottom: aspect-ratio-percent(1.5, 1);
  }

  &__content {
    padding: space(6);
    background: $white;
  }

  @include bp-down(sm) {
    @include media-item-horizontal();
  }
}
