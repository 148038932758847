.choose-spec {
  &__header {
    @include make-h3();
  }

  &__options {
    font-size: $font-size-sm;
  }

  &__options-header {
    color: color("muted");
  }

  .inline-bullets {
    color: color("muted-light");

    .link--primary {
      font-weight: $font-weight-medium;
    }
  }

  .feedback-message {
    margin-bottom: space(3);
  }

  &--promo {
    position: relative;
    padding-top: rem(100px);
    padding-right: space(3);
    padding-bottom: rem(135px);
    padding-left: space(3);

    @include bp-down(sm) {
      padding-top: rem(20px);
      padding-bottom: space(5);
    }

    .choose-spec__content {
      max-width: rem(960px);
      margin: 0 auto;
    }

    .choose-spec__header {
      @include make-h2();
      margin-bottom: space(4);
      color: $white;
    }

    .choose-spec__meta {
      @include make-h3();
      color: color("primary");
    }

    .choose-spec__options {
      margin-top: space(3);
      font-size: $font-size-base;
    }

    .choose-spec__options-header {
      color: $white;
    }

    .inline-bullets {
      color: $white;
    }
  }

  .link-style {
    font-weight: $font-weight-medium;
    color: color("primary");
  }

  &--multi {
    .choose-spec__row {
      @include bp-down(sm) {
        flex-direction: column;

        .regnr-input > .input-group {
          max-width: none;
        }
      }

      label {
        font-size: $font-size-xs;
      }

      select {
        font-size: $font-size-base;
      }
    }
    .choose-spec__action-container {
      display: flex;
      flex: 1;

      .form-stack {
        flex: 1;
        margin-bottom: 0;
        margin-left: space(3);

        @include bp-down(sm) {
          margin-bottom: space(3);
          margin-left: 0;
        }
      }

      .btn {
        margin-left: space(3);

        @include bp-down(sm) {
          margin-left: 0;
        }
      }

      @include bp-down(sm) {
        flex-direction: column;
      }
    }

    .choose-spec__options {
      display: flex;
      padding: space(3);
      font-size: $font-size-sm;
      border-radius: $border-radius;

      &--standard {
        background-color: color("blue-bg");
      }
      //TODO ADD COLORS TO GENERIC POSITION
      &--success {
        background-color: color("green-bg");
      }
      //TODO ADD COLORS TO GENERIC POSITION
      &--error {
        background-color: color("red-bg");
      }

      @include bp-down(sm) {
        flex-direction: column;
        align-items: flex-start;
        margin-bottom: space(3);
      }
    }

    .choose-spec__options-header {
      margin-right: space(3);
      color: $black;
    }

    .inline-bullets {
      font-weight: $font-weight-medium;
      color: $primary;
    }

    .form-step {
      border: rem(1px) solid color("border");
      border-left: rem(4px) solid color("primary");
      border-radius: rem(4px);

      &--danger {
        border-left: rem(4px) solid color("danger");
      }

      &--completed {
        border-left: rem(4px) solid color("success");
      }
    }
  }

  @include bp-down(sm) {
    padding: 0;
  }
}

.modal-body {
  .ttjs-tooltip {

    &--landing-err,
    &--landing-succ {
      // stylelint-disable declaration-no-important
      left: 30% !important;

      .ttjs-tooltip-arrow {
        left: rem(90px) !important;
      }

      .ttjs-tooltip-inner {
        font-size: 10px;
      }
    }
  }
}

.regnr-input--realistic {
  .input-group {
    max-width: rem(400px);
  }

  input {
    height: 100%;
    padding: 0 space(4) !important;
    font-size: 66px !important;
    font-weight: $font-weight-medium !important;
    text-align: center;
    letter-spacing: 2px;

    @include bp-down(sm) {
      font-size: 60px !important;
    }

    @include bp-down(xs) {
      font-size: 54px !important;
    }
  }
}

.ecom-landing-page {
  &__content-container {
    @include bp-down(sm) {
      padding-right: space(3);
      padding-left: space(3);
    }
  }
  .hero-image--transparent-top {
    height: rem(420px);
    padding-top: rem(190px);
    margin-bottom: rem(140px);

    @include bp-down(sm) {
      margin-bottom: rem(280px);
    }
  }

  .page-nav {
    .container {
      padding: 0;
    }
  }
}

.tyreshopregnrblock {
  .choose-spec-container {
    width: 100%;
  }

  .choose-spec--promo {
    padding: rem(84px) 0 1px 0;
  }

  .choose-spec__content {
    max-width: none;

    .form-step {
      margin-top: space(5);
      margin-bottom: 0;
    }

    @include bp-down(sm) {
      flex-direction: column;

      .form-step {
        margin-left: 0;
      }
    }
  }
}
