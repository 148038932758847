.attention-circle {
  position: relative;
  top: -1px;
  margin-right: space(1);
  font-size: rem(8px);
  color: color("attention");

  &--success {
    color: color("success");
  }

  &--secondary {
    color: color("white");
  }

  &--sm {
    top: -2px;
    margin-right: rem(2px);
    font-size: rem(4px);
  }
}
