.eon-product-spot {
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;
  min-height: 200px;
  padding: space(4);
  background: $white;
  border: $border-width solid transparent;
  border-color: $border-color;
  border-radius: $border-radius;
  box-shadow: $box-shadow-border-bottom;

  &__desc {
    display: flex;
  }

  &__info {
    flex-direction: column;
    width: 100%;
    margin-right: space(2);
  }

  &__image {
    width: rem(60px);
    cursor: pointer;
  }

  &__meta {
    font-size: $font-size-xs;
    color: color("muted");
    //text-transform: uppercase;

    .mark {
      position: relative;
      top: -1px;
      margin-right: rem(2px);
    }
  }

  &__buy {
    margin-top: auto;
  }

  .eon-product-title {
    margin-top: space(2);
    cursor: pointer;
  }

  .feedback-message {
    margin-top: rem(12px);
  }

  &__usps {
    display: flex;
    width: 100%;
    padding: rem(12px) 0 rem(8px);
    margin-top: space(3);
    border-top: 1px solid $border-color;
    border-bottom: 1px solid $border-color;

    &--empty {
      padding: 0;
      border-bottom: none;
    }

    &__item {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      margin-right: space(4);
      font-size: $font-size-xs;
      font-weight: $font-weight-medium;
      text-align: center;
      cursor: pointer;

      i,
      svg {
        margin-bottom: space(2);
        font-size: $font-size-lg;
      }
    }
  }

  &__price {
    margin-top: space(3);
    // text-align: right;

    .price-tag {
      margin-right: space(3);
      font-size: $h4-font-size;
      font-weight: $font-weight-medium;

      &.discount {
        color: color("attention");
      }
    }

    &__details {
      display: flex;
      flex-direction: row;
      align-items: flex-start;
    }

    &__meta {
      margin-top: rem(2px);
      margin-bottom: space(3);
      font-size: $font-size-xs;
      color: color("muted");
    }

    &--obsolete {
      font-weight: $font-weight-regular;
      color: color("muted");
      text-decoration: line-through;
    }
  }
}
