.configurator-text-input {
  position: relative;
  display: flex;
  flex: 1;
  align-items: center;
  height: rem(42px);
  padding: space(2) 0;
  font-size: $font-size-sm;
  color: #18191abf;
  background-color: $white;
  border: $border-width solid $border-color;
  border-radius: rem(6px);

  &--textarea {
    height: 120px;
  }

  &:not(:first-child) {
    margin-left: rem(12px);
  }

  &__image-container {
    position: relative;
    height: 100%;
  }

  &__regnr-image {
    position: absolute;
    left: 8px;
    max-width: rem(26px);
    height: 100%;
    object-fit: contain;
    border-radius: rem(4px);
  }
  &__input {
    width: 100%;
    padding: 0 rem(12px);
    border: 0;
    outline: 0;
    &--regnr-input {
      padding-left: rem(34px);
      text-transform: uppercase;

      &::placeholder {
        text-transform: none;
      }
    }

    &:-webkit-autofill,
    &:-webkit-autofill:hover,
    &:-webkit-autofill:focus,
    &:-webkit-autofill:active {
      transition: background-color 5000s ease-in-out 0s;
    }
  }

  textarea {
    height: 100%;
  }
}
