.product-upsell {
  &__container {
    overflow: visible;
  }
  &__header {
    margin-bottom: space(4);
  }

  &__item {
    position: relative;
    width: calc(50% - 10px);
    padding: space(5);
    background: color("blue-bg");
    border: 1px solid $border-color;
    border-radius: $border-radius;

    @include bp-down(sm) {
      min-width: 310px;
      padding: space(4);
    }

    &:not(:last-child) {
      margin-right: space(4);
    }
  }

  &__content-header {
    display: flex;
    justify-content: space-between;
    padding-bottom: space(3);
    border-bottom: 1px solid $border-color;

    .ribbon {
      position: absolute;
      top: -$ribbon-arrow-right-size;
    }

    .price-tag {
      font-size: $h2-font-size;
      font-weight: $font-weight-medium;
    }

    &__text {
      font-size: $font-size-sm;
      color: gray("700");
    }

    &__finance {
      font-size: $font-size-xs;
      color: gray("600");
    }

    &__image {
      min-width: 72px;
      max-height: 72px;
      background: $black;
      border-radius: $border-radius;

      &--interactive {
        cursor: pointer;
        user-select: none;
      }
    }
  }

  &__content {
    padding: space(3) 0 space(3) 0;
    margin-bottom: space(3);
    font-size: $font-size-sm;
    border-bottom: 1px solid $border-color;

    .list-styled {
      padding-top: 1rem;
    }
  }

  &__button {
    display: flex;
    justify-content: space-between;
    padding: space(2) space(3);

    &__text {
      color: $blue;
    }
  }

  &__link-btn {
    margin-top: space(2);
    font-size: $font-size-xs;
    font-weight: $font-weight-medium;
    color: color("primary");

    a {
      padding-left: 5px;
    }
  }
}
