.vehicle-print-selector {
  .site-content {
    height: 100%;
  }
  .section {
    height: 100%;
  }
  &__list {
    display: flex;
    flex-wrap: wrap;
  }

  &__item {
    width: calc(25% - 18px);
    min-width: 300px;
    margin-bottom: space(4);

    &__container {
      background: color("white");
      border-radius: 4px;
      box-shadow: 0px 1px 2px #0000000d;
    }
    &:not(:last-child) {
      margin-right: 24px;
    }
  }

  &__vehicle {
    &__info {
      padding: space(4);
    }
  }
  &__print {
    padding: space(4);
    &__image {
      margin-bottom: 12px;
    }
    &__icon {
      margin-right: space(2);
    }
  }

  .btn {
    width: 100%;
    font-size: 13px;
  }

  .vehicle-list__header {
    margin-top: rem(12px);
  }
}
