.block-header {
  padding-right: 0;
  padding-left: 0;

  a {
    margin-left: space(2);
    font-size: $font-size-base;
    text-decoration: underline;
  }
}


.container > .row {
  > .block {
    &.campaignrolluppage,
    &.campaignstockpage,
    &.campaignconfiguratorpage,
    &.gridblock,
    &.promoblock,
    &.servicebookingblock,
    &.servicebookingoverviewblock {
      padding-top: space(8);

      @include bp-down(sm) {
        padding-top: rem(1px);
        > * {
          @include breakout-fullwidth();
        }
      }
    }
  }
}

.section__content--no-padding {
  .row {
    > .block {
      &.campaignrolluppage,
      &.campaignstockpage,
      &.campaignconfiguratorpage,
      &.gridblock,
      &.promoblock,
      &.servicebookingblock,
      &.servicebookingoverviewblock {
        padding-top: 0;
        @include bp-down(sm) {
          padding-top: 0;
        }
      }
    }
  }
}

.contentblock {
  a:not(.btn) {
    color: color("primary");
    text-decoration: underline;
  }
}

// .standard-page {
//   .container--section-breakout > .row:not(:last-child) > .block,
//   .section__content > .row:not(:last-child) > .block {
//     margin-bottom: space(4);
//   }
// }

