.overlay-footer {
  position: absolute;
  right: 0;
  bottom: 0;
  left: 0;
  padding: space(3);
  background: $white;
  border-top: $border-width solid $border-color;
  box-shadow: $box-shadow-border-top;

  &--yellow-bg {
    background: color("blue-bg");
  }

  &__actions {
    display: flex;

    .btn--primary {
      flex: 1 0 auto;
    }

    .btn--outline {
      position: relative;
      padding-right: rem(12px);
      padding-left: rem(30px);

      svg {
        position: absolute;
        top: rem(14px);
        left: rem(12px);
        font-size: rem(24px);
      }
    }

    .btn + .btn {
      margin-left: rem(12px);
    }

  }

  .properties__label svg {
    cursor: pointer;
  }
}
