.bloglist-page {
  &__header {
    margin-bottom: space(3);
    &__title {
      margin-bottom: rem(12px);
      font-size: $h2-sm-font-size;
      font-weight: $font-weight-medium;

      @include bp-down(sm) {
        font-size: $h3-font-size;
      }
    }
  }
  &__filter {
    display: flex;
    padding-bottom: space(4);
    border-bottom: $border-width solid $border-color;
    .blog-category-item {
      background-color: $white;
    }

    .swiper-container {
      margin-right: 0;
      margin-left: 0;
    }

    .swiper-slide {
      width: auto;
    }
  }
}

.blog-list {
  padding-top: space(4);
  &__header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    &__title {
      font-size: $font-size-sm;
      font-weight: $font-weight-medium;
    }

    &__sorting {
      font-size: $font-size-sm;
      font-weight: $font-weight-medium;
      color: color("primary");
      cursor: pointer;
      user-select: none;
    }
    &__icon {
      font-size: $font-size-xxs;
    }
  }
  .campaign-list__item__content {
    padding-top: space(5);
  }

  &__item-intro {
    margin-bottom: auto;
    font-size: $font-size-sm;
    color: color("muted");
  }

  &__category-listing {
    display: flex;
    flex-wrap: wrap;
    margin-top: space(2);
  }
}
