.compare-vehicles {
  position: fixed;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: $zindex-site-header;
  display: flex;
  justify-content: center;
  padding-top: space(3);
  padding-bottom: space(3);
  color: color("white");
  background: color("blue");

  @include bp-down(sm) {
    padding-bottom: 20px;
  }

  &__toolbar-item {
    display: flex;
    align-items: center;
    font-size: $font-size-sm;
    font-weight: $font-weight-medium;
    &:not(:last-child) {
      margin-right: space(5);
    }

    &__counter {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 18px;
      height: 18px;
      padding-top: 2px;
      margin-left: space(2);
      font-size: $font-size-xxs;
      line-height: 0;
      color: color("white");
      background: color("black");
      border-radius: 18px;

      @include bp-down(sm) {
        padding-right: 0;
      }
    }
  }

  .modal-dialog {
    display: flex;
    max-width: 1272px;
  }

  .modal-content {
    width: auto;
    margin-right: auto;
    margin-left: auto;
  }

  .modal-body {
    padding: 0;
    overflow-x: auto;
    overflow-y: auto;
  }

  .delete-btn {
    position: absolute;
    top: 12px;
    right: 12px;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 30px;
    height: 30px;
    font-size: $font-size-xs;
    color: color("red");
    cursor: pointer;
    user-select: none;
    background-color: color("white");
    border: $border-width solid $border-color;
    border-radius: $border-radius;
    box-shadow: 0px 1px 2px #0000000d;

    @include bp-down(sm) {
      position: initial;
    }
  }

  .comparison-desktop-table {
    width: 100%;

    &__row {
      display: flex;
      width: 100%;
      &:first-child {
        .comparison-desktop-table__cell--label {
          z-index: 1;
          height: 223.25px;
        }
      }
      &:not(:last-child) {
        border-bottom: $border-width solid $border-color;
      }

      &:first-child,
      &:last-child {
        .comparison-desktop-table__cell--label {
          border-bottom-width: 0px;
        }
      }

      &--header {
        height: auto;
      }
    }

    &__cell {
      display: flex;
      align-items: center;
      width: 212px;
      padding: 0 space(4);
      font-size: $font-size-sm;
      font-weight: $font-weight-regular;
      text-align: left;
      &:first-child {
        font-weight: $font-weight-medium;
      }

      &:not(:last-child) {
        border-right: $border-width solid $border-color;
      }

      &--header {
        flex-direction: column;
        padding: space(3) space(4);
        text-align: center;
      }

      &--label {
        position: sticky;
        left: 0;
        min-height: 40px;
        background-color: color("gray-bg");
      }

      &__secondary-value {
        margin-left: space(1);
        font-size: $font-size-xs;
        color: color("muted");
        text-decoration: line-through;
      }
    }

    &__header-image {
      position: relative;
      display: flex;
      width: 100%;
      height: 100%;
      min-height: 107px;

      img {
        width: 100%;
        object-fit: cover;
        border-radius: $border-radius;
      }
    }
    &__header-content {
      min-height: 60px;
      margin-top: space(2);
      &__title {
        font-weight: $font-weight-medium;
        color: color("primary");
      }

      &__sub-title {
        font-size: $font-size-xs;
      }
    }

    .white-bg {
      background-color: color("white");
    }
  }

  .comparison-mobile-table {
    border-collapse: separate;
    &__row {
      background-color: color("white");
      border-width: 0;

      &--header {
        height: auto;
        background-color: color("gray-bg");
      }
    }

    &__cell {
      font-size: $font-size-sm;
      border-bottom: $border-width solid $border-color;
      &:not(:last-child) {
        border-right: $border-width solid $border-color;
      }

      &__content-wrapper {
        height: 200px;
      }

      &__content {
        padding: space(3);
        text-align: center;

        &__single-line {
          display: flex;
          flex-direction: column;
          align-items: center;
          white-space: nowrap;
        }

        &__discount {
          font-weight: $font-weight-medium;
          color: color("danger");
        }
      }

      &--header {
        padding: space(3);
        font-weight: $font-weight-medium;
      }

      &--label {
        position: sticky;
        left: 0;
        background-color: color("white");
        border-right: $border-width solid $border-color;
        .comparison-mobile-table__cell__content {
          display: flex;
          flex-direction: column;
          width: 100%;
          min-width: 80px;
          height: 100%;
          transform: rotate(180deg);
          writing-mode: vertical-rl;

          &__brand {
            font-size: $font-size-xs;
            font-weight: $font-weight-medium;
            color: color("primary");
          }

          &__text {
            font-size: $font-size-xxs;
          }
        }
      }

      &__secondary-value {
        margin-top: space(1);
        font-size: $font-size-xs;
        font-weight: $font-weight-regular;
        color: color("muted");
        text-decoration: line-through;
      }
    }
  }
}
