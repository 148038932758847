.department-select {
  &__header {
    @include make-h2-sm();
    @include bp-down(sm) {
      font-size: $h3-font-size;
    }
  }
  &__info {
    margin-top: space(4);

    .info-block {
      margin-left: rem(22px);
    }
  }
}
