.scrolled {
  .site-header__main,
  .site-header__sub-bar {
    position: fixed;
    top: 0;
    width: 100%;
    background-color: color("white");
  }

  .site-header__sub-bar {
    top: 21px;
    transition: 0.2s all linear;
    @include border-bottom();
  }

  &--up {
    .site-header__sub-bar {
      transform: translateY(100%);
    }
  }
}
