.ttjs-tooltip {
  z-index: $zindex-tooltip;
  width: rem(300px);
  padding: space(3);
  font-size: $font-size-sm;
  color: $body-color;
  text-align: center;
  background: $blue-light;
  border: $border-width solid $border-color;
  border-radius: $border-radius;

  &--landing-err {
    background: color("red-bg");
    border: 1px solid color("red");
  }

  &--landing-succ {
    background: color("green-bg");
    border: 1px solid color("green");
  }

  @include bp-down(sm) {
    padding: rem(12px);
    font-size: $font-size-xs;
  }

  p {
    margin-bottom: 0.5rem;
  }

  a {
    color: $white;
    text-decoration: underline;
  }

  .ttjs-tooltip-arrow {
    position: absolute;
    width: 0;
    height: 0;
    margin: rem(10px);
    border-color: $border-color;
    border-style: solid;
  }
}

.ttjs-tooltip-inner p:last-child {
  margin-bottom: 0;
}

.ttjs-tooltip[x-placement^="bottom"] {
  margin-top: rem(10px);
}

.ttjs-tooltip[x-placement^="bottom"] .ttjs-tooltip-arrow {
  top: - rem(6px);
  left: calc(50% - #{rem(10px)});
  width: 0.625rem;
  height: 0.625rem;
  margin-top: 0;
  margin-bottom: 0;
  background-color: $blue-light;
  border-style: hidden;
  border-top: 1px solid $border-color;
  border-left: 1px solid $border-color;
  transform: rotateZ(45deg);

  &--landing-err {
    background: color("red-bg");
    border-top: 1px solid color("red");
    border-left: 1px solid color("red");
  }

  &--landing-succ {
    background: color("green-bg");
    border-top: 1px solid color("green");
    border-left: 1px solid color("green");
  }
}

.ttjs-tooltip[x-placement^="top"] {
  margin-bottom: rem(10px);
}

.ttjs-tooltip[x-placement^="top"] .ttjs-tooltip-arrow {
  bottom: - rem(6px);
  left: calc(50% - #{rem(10px)});
  width: 0.625rem;
  height: 0.625rem;
  margin-top: 0;
  margin-bottom: 0;
  background-color: $blue-light;
  border-style: hidden;
  border-right: 1px solid $border-color;
  border-bottom: 1px solid $border-color;
  transform: rotateZ(45deg);
}
