.promo-teasers {
  @include bp-down(sm) {
    padding-right: 0;
  }

  .swiper-slide {

    @include bp-down(sm) {
      width: 90%;
    }

    .promo--sm {
      height: rem(280px);
    }
  }
}
