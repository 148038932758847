.spare-parts-modal {

  .form-step__header {
    flex-direction: column;
    align-items: flex-start;
  }

  .reg-nr {
    margin-bottom: space(6);
    background: color("white");
    border: $border-width solid $border-color;
    border-radius: $border-radius;

    &__field {
      position: relative;
      display: flex;
      width: 100%;

      &__symbol {
        border-top-left-radius: $border-radius;

        &--rounded {
          border-bottom-left-radius: $border-radius;
        }
      }

      &__container {
        border-radius: 0;
      }

      input {
        text-transform: uppercase;
        /* stylelint-disable-next-line declaration-no-important */
        background-color: transparent !important;
        border: 0;
      }

      .form-control--invalid {
        border: $border-width solid color("danger");
      }
    }

    &__specs {
      padding: space(3) space(2);
      font-size: $font-size-sm;
      font-weight: $font-weight-medium;
      background: $green-light;
      border-top: $border-width solid $border-color;
      border-bottom-right-radius: $border-radius;
      border-bottom-left-radius: $border-radius;
    }
  }

  .car-info {
    display: flex;
    flex-grow: 1;
    align-items: center;
    padding: 0 space(4);
    margin-bottom: space(6);
    margin-left: space(3);
    background: color("green-bg");
    border: $border-width solid color("green");
    border-radius: 4px;

    &__table {
      width: 80%;
      &__header {
        font-size: 9px;
        font-weight: $font-weight-medium;
        color: color("muted");
      }
      &__text {
        font-size: $font-size-sm;
      }
      td {
        padding: space(1);
      }
    }
  }

  .field-input-images {
    width: 100%;
    margin-top: space(3);
    margin-bottom: space(3);

    label {
      margin-bottom: space(1);
    }

    &__grid {
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
      margin-bottom: space(2);
    }

    &__grid-item {
      .file-placeholder,
      .file-thumbnail {
        margin: rem(12px) space(2);
      }

      @include bp-down(sm) {
        width: 33.333%;

        .file-placeholder,
        .file-thumbnail {
          width: auto;
        }
      }
    }
  }
}
