.tyre-features {
  &__item {
    display: flex;
    padding: space(4) 0;

    @include bp-down(sm) {
      display: block;
    }

    &:not(:last-child) {
      @include border-bottom($shadow: false);
    }
  }

  &__rating {
    position: relative;
    display: flex;
    align-items: center;
    width: 50%;

    @include bp-down(sm) {
      width: 100%;
      margin-top: space(4);
    }
  }

  &__rating-progress {
    flex-grow: 1;
  }

  &__rating-tooltip {
    position: relative;
    width: rem(88px);
    padding: font-padding(rem(9px), rem(4px));
    margin-left: space(3);
    font-size: $font-size-xs;
    font-weight: $font-weight-medium;
    text-align: center;
    background: $white;
    border: $border-width solid $border-color;

    &::after,
    &::before {
      position: absolute;
      top: 50%;
      right: 100%;
      width: 0;
      height: 0;
      pointer-events: none;
      content: " ";
      border: solid transparent;
    }

    &::after {
      margin-top: -10px;
      border-color: rgba(136, 183, 213, 0);
      border-width: 10px;
      border-right-color: $white;
    }

    &::before {
      margin-top: -12px;
      border-color: rgba(194, 225, 245, 0);
      border-width: 12px;
      border-right-color: $border-color;
    }
  }

  &__content {
    position: relative;
    width: 50%;
    padding-left: rem(50px);
    margin-right: rem(80px);

    @include bp-down(sm) {
      width: 100%;
    }
  }

  &__content-icon {
    position: absolute;
    top: 2px;
    left: 0;

    i,
    svg {
      font-size: rem(24px);
    }
  }

  // &__content-header {
  // }

  &__content-meta {
    font-size: $font-size-sm;
    color: color("muted");
  }
}
