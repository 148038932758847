.product-main-action {
  z-index: 500;

  &--modal-open {
    z-index: $zindex-product-main;
  }

  @include bp-down(md) {
    position: relative;
    top: 0;
    right: 0;
    z-index: $zindex-backdrop;
    max-width: none;
    margin-bottom: space(4);
    &--fixed {
      display: none;
    }
  }

  @include bp-down(sm) {
    border-right: 0;
    border-left: 0;
    border-radius: 0;
  }

  &--inline {
    display: none;

    @include bp-down(md) {
      display: block;
    }
  }

  &__container {
    background: color("white");
    border: $border-width solid $border-color;
    border-radius: 4px;
  }

  &__header {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 42px;
    font-size: $font-size-xs;
    font-weight: $font-weight-medium;
    color: color("white");
    background: color("black");

    &__info {
      position: absolute;
      top: 12px;
      right: 24px;
      bottom: 12px;
      display: flex;
      align-items: center;
      justify-content: center;
      width: 18px;
      height: 18px;
      margin-left: space(1);
      font-size: $font-size-xxs;
      color: color("primary");
      cursor: pointer;
      background: color("white");
      border-radius: 9px;
    }
  }

  &__main {
    width: 100%;
    padding-top: space(3);
  }

  &__pricing-meta {
    padding-right: space(4);
    padding-left: space(4);
    font-size: $font-size-xs;
    font-weight: $font-weight-medium;
  }

  &__pricing {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    padding-right: space(4);
    padding-bottom: space(3);
    padding-left: space(4);
    border-bottom: $border-width solid $border-color;
    &__current-price {
      margin-right: space(1);
      font-size: $h2-sm-font-size;
      font-weight: $font-weight-medium;
    }

    &__obsolete-price {
      font-size: $h4-font-size;
      color: color("muted-light");
    }

    &__financing {
      font-size: $font-size-xs;
      &__highlight {
        font-size: $font-size-base;
        font-weight: $font-weight-medium;
      }
    }

    &__link {
      margin-top: space(1);
      font-size: $font-size-xs;
      font-weight: $font-weight-medium;
    }

    & .au-widget-car-rating {
      margin-top: -1em;
    }
  }

  &__configurator {
    padding: 0 space(4);
    border-bottom: $border-width solid $border-color;
  }

  &__section {
    &:not(:last-child) {
      border-bottom: $border-width solid $border-color;
    }
  }

  &__summary {
    padding: space(3) space(4);
    border-bottom: $border-width solid $border-color;

    .vehicle-configurator__summation-list__label,
    .vehicle-configurator__summation-list__value {
      font-size: $font-size-sm;
    }

    .vehicle-configurator__summation-list__row:first-child {
      font-weight: $font-weight-medium;
    }
  }

  &__row {

    &__action-container {
      display: flex;
      justify-content: space-between;
      padding: space(3) 0;
      cursor: pointer;
    }

    &__title {
      font-size: $font-size-sm;
      font-weight: $font-weight-medium;
    }

    &__value-container {
      display: flex;
      align-items: center;
    }

    &__value {
      display: flex;
      align-items: center;
      font-size: $font-size-sm;
      white-space: nowrap;

      .indicator {
        margin-right: space(2);
      }

      &--static {
        color: #18191abf;
        text-decoration: underline;
      }

      .price-tag--obsolete {
        margin-left: space(2);
      }
    }

    &__arrow {
      margin-bottom: 2px;
      margin-left: space(3);
      color: color("primary");
      transition: transform 0.3s ease-in;
      &--open {
        transform: rotate(180deg);
      }
    }

    &__content {
      padding-bottom: space(3);
    }
  }

  &__data {
    &:not(:last-child) {
      margin-bottom: space(1);
    }
    font-size: $font-size-xs;
    font-weight: $font-weight-medium;

    &--gray {
      font-weight: $font-weight-base;
      color: color("muted");
    }

    &--discount {
      color: color("danger");
    }
  }

  &__valuation {
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 40px;
    padding-right: space(4);
    padding-left: space(4);
    &__text {
      font-size: $font-size-xs;
    }
    &__action {
      font-size: $font-size-xs;
      font-weight: $font-weight-medium;
    }
  }

  &__financing {
    padding-top: space(3);
    padding-right: space(4);
    padding-left: space(4);
    border-top: $border-width solid $border-color;

    &__price {
      margin-bottom: space(1);
    }

    .price-tag {
      font-size: $font-size-lg;
      font-weight: $font-weight-medium;

      &--obsolete {
        margin-left: space(1);
        font-size: $font-size-sm;
      }
    }
  }

  &__actions {
    width: 100%;
    padding-right: space(4);
    padding-left: space(4);
    margin-top: space(4);
    margin-bottom: space(3);

    svg {
      margin-right: space(1);
    }
  }

  &__sub-actions-field {
    display: flex;
    flex: 1;
    padding-top: space(3);
    padding-right: space(4);
    padding-bottom: space(3);
    padding-left: space(4);
    border-top: $border-width solid $border-color;

    &--col {
      flex-direction: column;
    }

    &__button {
      display: flex;
      flex: 1;
      align-items: center;
      justify-content: center;
      height: 30px;
      font-size: $font-size-xs;
      font-weight: $font-weight-medium;
      color: color("primary");
      cursor: pointer;
      background-color: color("border-light");
      border-radius: $border-radius;

      &--middle {
        @include bp-down(md) {
          display: none;
        }
      }

      &:not(:last-child) {
        margin-right: 12px;
      }

      .link--icon {
        margin-right: space(1);
      }

      .filter-bar__tag--watch {
        justify-content: center;
        width: 100%;
        margin-right: 0;
        background-color: transparent;
        border: none;
      }
    }

    &__col-item {
      position: relative;
      display: flex;
      align-items: center;
      padding-left: space(4);
      font-size: $font-size-xs;
      a {
        margin-left: space(1);
      }
    }

    &__col-item:not(:first-child) {
      margin-top: space(2);
    }

    &__list-icon {
      position: absolute;
      left: 0;
      font-size: $font-size-sm;
      color: color("white");
    }
  }

  &__footer {
    padding: space(4);
    background: color("blue-bg");
    border-top: $border-width solid $border-color;
    &__main-text {
      font-size: $font-size-sm;
      font-weight: $font-weight-medium;
    }

    &__sub-text {
      margin-bottom: 0;
      font-size: $font-size-sm;
    }

    &__action {
      margin-top: space(2);
      margin-right: space(3);
      margin-left: 0;
      &--solo {
        width: 100%;
        margin-right: 0;
      }
    }
  }

  &__sticky-stopper {
    position: absolute;
    bottom: 0;
  }

  .skeleton-loader {
    min-height: 900px;
    background-image: url("/Static/images/hb_skeleton-loader_desktop.png");
    background-repeat: no-repeat;
    background-size: 100%;
    border-radius: $border-radius;

    @include bp-down(md) {
      background-image: url("/Static/images/hb_skeleton-loader_mobile.png");
    }
  }
}
