.saved-vehicles {
  display: flex;
  flex-direction: column;
  width: 100%;
  margin-bottom: space(3);
  overflow: hidden;
  background: $white;
  border: $border-width solid $border-color;
  border-radius: $border-radius;

  &__item {
    @include bg-cover();
    position: relative;
    width: 100%;
    padding-bottom: aspect-ratio-percent(1.5, 1);

    &--overlay {
      span {
        position: absolute;
        top: calc(50% - #{rem(14px)});
        z-index: 1;
        width: 100%;
        font-size: $font-size-lg;
        color: $white;
        text-align: center;

        @include bp-down(sm) {
          font-size: $font-size-base;
        }
      }

      &::after {
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        content: "";
        background: rgba($black, 0.7);
      }
    }
  }

  .swiper-container {
    display: flex;
    width: 100%;

    .swiper-wrapper {
      height: auto;
    }

    .swiper-slide {
      display: flex;
      height: auto;
      max-height: 200px;
    }
  }


  .swiper-pagination-bullet {
    background: gray("500");
    opacity: 1;
  }

  .swiper-pagination-bullet-active {
    background: color("primary");
  }

  &__nav {
    position: absolute;
    top: calc(50% - 20px);
    z-index: 1;
    font-size: rem(48px);
    color: gray("500");
    cursor: pointer;
    transition: opacity $transition-base;

    @include bp-down(sm) {
      display: none;
    }

    &--prev {
      left: space(3);
    }

    &--next {
      right: space(3);
    }

    &--disabled {
      cursor: default;
      opacity: 0.3;
    }
  }

  .product-info {
    display: flex;
    flex-direction: column;
    padding: space(3);
    cursor: pointer;
    &__title {
      margin-bottom: 0;
      font-size: $font-size-base;
      font-weight: $font-weight-medium;
      user-select: none;
    }
    &__description-container {
      margin-bottom: space(1);
      &__text {
        font-size: $font-size-xs;
        font-weight: $font-weight-regular;
        color: gray("600");
        user-select: none;
      }
    }

    &__price {
      margin-right: space(2);
      user-select: none;
      &--discount {
        font-weight: $font-weight-medium;
        color: color("danger");
      }
      &--obsolete {
        font-size: $font-size-sm;
        font-weight: $font-weight-regular;
        color: gray("600");
        text-decoration: line-through;
      }
    }

    &__card-actions {
      display: flex;
      height: rem(41px);
      cursor: pointer;
      border-top: $border-width solid $border-color;

      &__btn {
        display: flex;
        flex: 1;
        align-items: center;
        justify-content: center;
        .blue {
          color: color("blue");
        }

        &:not(:last-child) {
          border-right: $border-width solid $border-color;
        }
      }
    }
    &--sub-container {
      border-top: $border-width solid $border-color;
    }

    &__indicator {
      &--red {
        background: color("red");
      }
      &--green {
        background: color("green");
      }
      &--yellow {
        background: color("yellow");
      }
    }

    &__tyre-header {
      display: flex;
      align-items: center;
      margin-top: space(2);
      font-size: $font-size-xs;
    }

    &__sub-action {
      margin-top: 12px;
    }

    .tyre-row {
      margin-top: 12px;
      margin-bottom: space(2);
      background-color: color("gray-bg");
      border: $border-width solid $border-color;
      border-radius: $border-radius;
      &--danger {
        background-color: color("red-bg");
        border-color: color("danger");
      }
      &__header {
        display: flex;
        align-items: center;
        justify-content: space-between;
        height: 32px;
        padding: 0 12px;
        font-size: $font-size-xs;
        font-weight: $font-weight-medium;
        cursor: pointer;
        &--open {
          border-bottom: $border-width solid $border-color;
        }
        &--danger {
          border-color: color("danger");
        }
      }

      &__chevron {
        color: color("primary");
      }

      &__icon {
        &--red {
          color: color("red");
        }
        &--green {
          color: color("green");
        }
        &--yellow {
          color: color("yellow");
        }
      }

      &__content {
        padding: 12px;
        border-bottom: $border-width solid $border-color;

        &--danger {
          border-color: color("danger");
        }

        &__title {
          margin-bottom: space(1);
          font-size: $font-size-xs;
          font-weight: $font-weight-medium;
        }
        &__text {
          margin-bottom: space(1);
          font-size: $font-size-xs;
        }

        &__text:last-child {
          margin-bottom: 0;
        }
      }

      &__content:last-child {
        border-bottom: 0;
      }
    }

    .tyre-row:last-child {
      margin-bottom: 0;
    }
  }
}
