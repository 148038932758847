.product-spot {
  position: relative;
  display: flex;
  flex-direction: column;
  cursor: pointer;
  border: $border-width solid transparent;
  border-radius: $border-radius;
  transition: background $transition-base;

  &:hover {
    .product-spot__bookmark {
      display: flex;
    }
  }

  &__images-holder {
    position: relative;
  }

  &__images__actions {
    position: absolute;
    right: rem(8px);
    bottom: rem(8px);
    z-index: 2;
    display: flex;
  }

  &__bookmark {
    display: none;
    align-items: center;
    justify-content: center;
    padding: space(2);
    margin-right: space(2);
    background: color("gray-bg");

    border-radius: 3px;

    &__icon {
      color: color("primary");
    }

    &--marked {
      display: flex;
      background: color("primary");

      .product-spot__bookmark__icon {
        color: color("white");
      }
    }
  }

  .product-images {
    &__item {
      overflow: hidden;
      border-top-left-radius: $border-radius;
      border-top-right-radius: $border-radius;
    }
  }

  &__meta {
    &--discount {
      margin-bottom: rem(2px);
      font-size: rem(10px);
      font-weight: $font-weight-bold;
      color: color("danger");
      text-transform: uppercase;
      letter-spacing: 0.5px;
    }
  }

  &__content {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    padding: space(3);
  }

  &__location {
    margin-bottom: space(1);
    font-size: $font-size-xs;
    font-weight: $font-weight-medium;

    i,
    svg {
      margin-right: rem(2px);
      color: $gray-900;
    }
  }

  &__tags {
    position: absolute;
    top: space(3);
    left: space(3);
    z-index: 100;

    .product-tag {
      float: left;
      margin-right: space(2);
    }
  }

  &__title {
    margin-bottom: space(0);
    overflow: hidden;
    font-size: $h4-font-size;
    font-weight: $font-weight-medium;
    line-height: rem(24px);
    text-overflow: ellipsis;
    white-space: nowrap;
  }

  &__description {
    overflow: hidden;
    font-size: $font-size-sm;
    text-overflow: ellipsis;
    white-space: nowrap;
  }

  &__info {
    display: flex;
    margin-top: space(2);
    margin-bottom: space(3);
    font-size: $font-size-xs;
    color: color("muted");

    &--item {
      margin-left: 3px;
    }

    .meta-tag {
      margin-right: space(1);
      margin-left: 0;
      font-size: $font-size-xxs;
      font-weight: 300;
      text-transform: uppercase;
      border-radius: rem(2px);
    }

    // span {
    //   position: relative;
    //   top: -1px;
    //   margin: 0 1px;
    //   font-size: $font-size-xxs;
    // }
  }

  &__price {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    align-content: flex-start;
    justify-content: space-between;

    .price-tag {
      font-size: $h5-font-size;
      font-weight: $font-weight-medium;

      &--obsolete {
        font-size: $font-size-xxs;
        font-weight: $font-weight-regular;
        color: color("muted");
      }
    }

    &-item {
      flex-basis: 50%;
      margin-bottom: rem(8px);

      &__name {
        font-size: $font-size-xs;
        font-weight: $font-weight-medium;
      }

      &__meta {
        font-size: $font-size-xxs;
        color: color("muted");
        // span {
        //   position: relative;
        //   top: -1px;
        //   margin: 0 1px;
        //   font-size: $font-size-xxs;
        // }

        &--sm {
          font-size: $font-size-xxs;
          color: color("muted-light");
        }
      }
    }
  }

  .vehicle-list__item & {
    width: calc(100% + #{space(2) * 2});
    margin: 0 -#{space(2)};

    @include bp-down(sm) {
      width: calc(100% + #{space(2)});
      margin: 0 -#{space(2) / 2};
    }
  }

  &--interactive {
    background: $white;
    //border: 4px solid transparent;
    box-shadow: $box-shadow-border-bottom;

    &.product-spot--active {
      //border: 4px solid #e0e8f5;
      //border-radius: 4px;
      box-shadow: 0px 0px 0px 4px #e0e8f5;

      &::after,
      &::before {
        opacity: 1;
        //transform: none;
      }
    }

    &::after,
    &::before {
      position: absolute;
      top: 100%;
      left: 50%;
      width: 0;
      height: 0;
      pointer-events: none;
      content: " ";
      border: solid transparent;
      opacity: 0;
      // transition: transform $transition-base;
      transform: translateY(1px);
    }

    &::after {
      margin-left: -10px;
      border-color: rgba(255, 255, 255, 0);
      border-width: 10px;
      border-top-color: $white;
    }

    &::before {
      margin-left: -15px;
      border-color: rgba(194, 225, 245, 0);
      border-width: 16px;
      border-top-color: #e0e8f5;
    }
  }

  &--card {
    background: $white;
    box-shadow: $box-shadow-border-bottom;
  }

  &--loading {
    cursor: wait;
    opacity: 0.5;
  }
}
