// stylelint-disable declaration-no-important
.my-pages {
  padding: space(3);

  &--logged-in {
    padding: 0;
  }

  &__logout {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: space(3);
    margin-bottom: space(3);
    color: color("muted");
    cursor: pointer;
  }

  &__form-section {
    margin-bottom: space(5);
  }

  .feedback-message {
    margin-top: space(3);
  }

  &__restore-link {
    float: right;
    font-size: $font-size-xs;
  }

  &__facets-view {
    border-bottom: $border-width solid $border-color;
  }

  &__facet {
    position: relative;
    background: $white;

    &:not(:last-child) {
      border-bottom: $border-width solid $border-color;
    }

    &--selected {

      .my-pages__facet-name {
        font-weight: $font-weight-medium;
      }

      .my-pages__facet-icon {
        transform: rotate(180deg);
      }
    }
  }

  &__facet-pane {
    display: flex;
    align-items: center;
    cursor: pointer;
  }

  &__facet-name {
    padding: font-padding(space(4), space(3));
    line-height: 1;
  }

  &__facet-counter {
    padding: 0 space(1) 0 space(1);
    background: color("blue-bg");
    .text {
      font-size: $font-size-xs;
    }
  }

  &__facet-icon {
    position: absolute;
    top: rem(17px);
    right: space(3);
    font-size: rem(24px);
    pointer-events: none;
  }

  &__section {
    padding: 0 space(3) space(3) space(3);
    background: gray("100");
    border-top: $border-width solid $border-color;

    &--empty {
      padding: space(3);
      text-align: center;
    }

    &__title-container {
      display: flex;
      justify-content: space-between;
      margin-top: space(4);
      margin-bottom: space(2);

      &--header {

        .section-item__col {
          padding: 0 !important;
        }
      }

      .delete-button {
        font-size: $font-size-xs;
        font-weight: $font-weight-regular;
        color: color("red");
        text-decoration: underline;
        cursor: pointer;
      }
    }

    &__title {
      font-size: $font-size-xs;
      font-weight: $font-weight-medium;
      color: gray("700");
      text-align: center;
    }

    &__text-container {
      margin-bottom: space(4);
    }

    &__text {
      font-size: $font-size-sm;
    }

    &__tyre-empty {
      padding: space(3) 0 space(4) 0;
    }
  }

  &__section.my-tyres {
    padding-top: space(4);

    .product-info {
      cursor: initial;
    }
  }

  .section-item {
    position: relative;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    padding: 0 space(2) 0 space(2);
    margin-bottom: space(2);
    background: $white;
    border: $border-width solid $border-color;
    border-radius: $border-radius;

    &__col {
      display: flex;
      flex: 1;
      align-items: center;
      padding: space(3) 0 space(3) 0;
      &--middle-item {
        display: flex;
        justify-content: center;
      }

      &--last-item {
        display: flex;
        justify-content: flex-end;
      }
    }

    &__tag-container {
      display: flex;
      flex-wrap: wrap;
      align-items: center;
      width: 78%;
      padding-top: space(2);
      cursor: pointer;
    }

    &__subscription-delete {
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      display: flex;
      align-items: center;
      justify-content: center;
      width: 18%;
      cursor: pointer;
      border-left: $border-width solid $border-color;
    }

    &__tag {
      display: flex;
      align-items: center;
      justify-content: center;
      height: rem(30px);
      padding-right: space(2);
      padding-left: space(2);
      margin: 0 space(2) space(2) 0;
      background: color("blue-bg");
      border-radius: $border-radius;
      &__text {
        font-size: $font-size-xs;
        font-weight: $font-weight-medium;
        color: color("blue");
      }
      &--first-item {
        margin-left: 0;
      }
    }

    &__status-icon {
      width: rem(8px);
      height: rem(8px);
      margin-right: rem(8px);
      border-radius: 8px;
      &--red {
        background: color("red");
      }
      &--green {
        background: color("green");
      }
      &--yellow {
        background: color("yellow");
      }
    }
  }
}
