.share-page-filterbar__btn {
  display: flex;
  align-items: center;
  font-size: $font-size-sm;
  font-weight: $font-weight-medium;
  color: color("primary");
  cursor: pointer;
  &__icon {
    margin-right: space(2);
    font-size: 20px;
  }
}
