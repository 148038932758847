.departments-no-results {
  padding: space(4);
  text-align: center;

  @include bp-up(lg) {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    width: rem(500px);
    height: rem(230px);
    margin: auto;
  }

  svg,
  i {
    margin-bottom: space(3);
    font-size: rem(40px);
  }
}

.department-heading {
  padding: space(5) 0 space(6);
  &__title {
    font-size: $h2-font-size;
    font-weight: $font-weight-medium;

    @include bp-down(md) {
      font-size: $font-size-lg;
    }
  }
  &__sub-title {
    margin-top: space(3);
    font-size: $font-size-sm;

    @include bp-down(md) {
      font-size: $font-size-xs;
    }
  }

  @include bp-down(md) {
    padding: space(5);
  }

  .column-layout__column & {
    padding-right: 2.5rem;
    @include bp-down(md) {
      padding-right: 0;
    }
  }
}

.department-link-bar {
  height: rem(30px);
  margin-top: space(4);
  .filter-bar__tag {
    width: auto;
  }

  @include bp-down(md) {
    margin-top: space(3);
  }

  &--no-padding {
    margin-top: 0;

    @include bp-down(md) {
      margin-top: 0;
    }
  }
}

.department-info-row {
  &__section {
    position: relative;
    padding: space(3) space(5);
    border-top: $border-width solid $gray-400;

    svg {
      position: absolute;
      left: space(1);
      margin-top: 1px;
    }

    &--no-bottom-padding {
      padding-bottom: 0;
    }
  }

  &__line {
    font-size: $font-size-sm;

    @include bp-down(md) {
      font-size: $font-size-xs;
    }

    .link {
      text-decoration: underline;
      opacity: 0.75;
    }
  }
  &__opening-hours {
    &__item {
      margin-bottom: space(2);
      font-size: $font-size-sm;
      opacity: 0.75;
      &--open {
        font-weight: $font-weight-medium;
        color: color("success");
        opacity: 1;
      }
      &.link {
        text-decoration: underline;
      }

      &:last-child {
        margin-bottom: 0;
      }

      @include bp-down(md) {
        margin-bottom: space(1);
        font-size: $font-size-xs;
      }
    }
  }

  &__brands {

    &__title {
      margin-bottom: 12px;
      font-size: $font-size-sm;
      font-weight: $font-weight-medium;

      @include bp-down(md) {
        font-size: $font-size-xs;
      }
    }
    &__list {
      display: flex;
      flex-wrap: wrap;
    }
    &__item {
      min-width: 1.25rem;
      max-width: 2.5rem;
      max-height: 40px;
      margin-bottom: space(3);
      img {
        height: 100%;
        object-fit: contain;
      }

      &:not(:last-child) {
        margin-right: space(4);
      }
    }
  }
}
