// stylelint-disable declaration-no-important
.employee-card-print {
  padding-top: 0;

  &__container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    height: 100vh;
    padding: space(4);
  }

  &__content {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  &__image {
    width: 150px;
    height: 150px;
    margin-bottom: space(4);
    overflow: hidden;
    border: 2px solid $border-color;
    border-radius: 999999px;
  }

  &__name {
    margin-bottom: space(3);
    font-size: $h1-font-size;
  }

  &__title {
    font-size: $font-size-lg;
    color: color("muted-light");
  }

  &__footer {
    display: flex;
    flex-direction: column;
    align-items: center;

    img {
      margin-top: 40px;
    }
  }

  &__qr-header {
    font-size: $font-size-sm;
    font-weight: $font-weight-medium;
  }
  ._hj_feedback_container {
    display: none !important;
  }
}
