.opening-hours-print-app {
  display: flex;
  padding: space(6) 0;

  @include bp-down(sm) {
    flex-direction: column;
    padding: 0 0 space(4) 0;

    .row {
      flex-direction: column;
    }
  }

  &__preview {
    &__container {
      display: flex;
      flex-direction: column;
      padding: space(4);
      background: color("white");
      border: $border-width solid $border-color;
      border-radius: $border-radius;
    }

    &__image-container {
      margin-bottom: rem(12px);
      overflow: hidden;
      border: $border-width solid color("border-light");
      border-radius: $border-radius;
    }

    &__btn-icon {
      margin-right: space(1);
    }

    &__header {
      display: flex;
      flex-direction: column;
      align-items: center;
      padding: space(3);
      background-size: cover;

      &--white {
        background-color: color("white");
      }
      &--yellow {
        background-color: color("yellow");
      }
      &--red {
        background-color: color("red");
      }
      &--green {
        background-color: color("green");
      }
      &--blue {
        background-color: color("blue");
      }
      &--white-text {
        color: $white;
      }
    }

    &__icon {
      margin-bottom: space(2);
      font-size: 20px;
      color: color("blue");
    }

    &__title {
      margin-bottom: space(1);
      font-size: 18px;
      font-weight: $font-weight-bold;
    }

    &__sub-title {
      font-size: 14px;
      font-weight: $font-weight-medium;
    }
  }
}
